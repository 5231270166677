import React, { Component } from 'react'
import PureComponent from '../../pure'

import CalDataContainer from './calDataContainer'
import QuickCreateActMenu from './quickCreateActMenu'

import  sAction  from "sAction";

class CalWindowContainer extends PureComponent{
    
    constructor(props){
        super(props);

        let activeTab = props.data.activeTab;
        if(!activeTab){
            activeTab = "detailMeeting";
        }

        this.state = {
            activeTab : activeTab,
        };
    }

    changeActiveTab(newTab){
        if(newTab == this.state.activeTab){
            return;
        }

        this.setState({activeTab : newTab});

    }

    render(){
        let comp = null;
        let menuIcon = null;
        const type = this.props.type;
        let module = this.props.module;
        const data = this.props.data;
        let title = "";
        let titleClass = "calQuickCreateTitle";
        const prefix = this.props.prefix;
        const way = this.props.way;
        
        if(type == "createView"){
            titleClass += " newActTitle";

            menuIcon = <div className="burgerMenu calBurgerMenu" onClick={() => {sAction.quickMenuOpen()}}></div>;
            comp = <QuickCreateActMenu way={way} activeTab={this.state.activeTab} menuOpen={data.menuOpen} clickFnc={(newTab) => {this.changeActiveTab(newTab)}} />;
            
            switch (this.state.activeTab){
                case 'detailMeeting':
                    module = 'Meetings';
                    //title = sAction.translate("LBL_TITLE_CREATE_NEW_MEETING", "Calendar");
                    break;
                case 'detailCall':
                    module = 'Calls';
                    //title = sAction.translate("LBL_TITLE_CREATE_NEW_CALL", "Calendar");
                    break;
                case 'detailTask':
                    module = 'Tasks';
                    //title = sAction.translate("LBL_TITLE_CREATE_NEW_TASK", "Calendar");
                    break;
            }
        }
        else{
            title = <div className="calTitleContainer">
            <div className={"icon-"+module+" calTitleIcon"} />
            {data.calQuickCreate.toJS().fields["name"].value}
            </div>;
        }

        return(
            <div>
            {comp}
            <CalDataContainer 
                data={data} 
                type={this.props.type} 
                activeTab={this.state.activeTab} 
                module={module} 
                prefix={prefix}/>
            </div>
        );

    }

}
export default CalWindowContainer