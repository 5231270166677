import React from "react";

import Enum from "../Enum/Enum";
import Select from "../../formElements/Select";
import  sAction  from "sAction";

export default class Bool extends Enum {

  render() {
    const data = this.props.data;

    var optionsToSelect = [
      {value: '1', label: sAction.translate("LBL_STATUS_ACTIVE_LIST")},
      {value: '0', label: sAction.translate("LBL_STATUS_INACTIVE_LIST")},
    ];

    const forceOpen = data.def.get("forceOpen") || this.props.listField;
    
    return (
      <Select
        options={optionsToSelect}
        defaultValue={data.value}
        onKeyDown={e => this.onKeyDown(e,null,false)}
        open={forceOpen === true ? forceOpen : false}
        autoFocus={forceOpen !== true ? true : forceOpen}
        onChange={e => this.save(e)}
      />
    );
  }
}
