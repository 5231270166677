import React from "react";

import MaterialDefault from "./MaterialDefault";
import MaterialRadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialRadio from "@material-ui/core/Radio";

export default class RadioGroup extends MaterialDefault {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue
    };
    this.lastKeyCode = null;
  }
  onChange(e){
    this.setState({ value: e.target.value });
    if (this.props.onChange != undefined) {
        if(this.lastKeyCode == 38 || this.lastKeyCode == 39 || this.lastKeyCode == 40 || this.lastKeyCode == 37){
        
        this.lastKeyCode = null;

        }else{
        this.props.onChange(e);
        }
      
    }
  };
  onKeyDown(e,index) {
    if(e.keyCode == 13){
        this.props.onChange(e);   
    }else if(e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40 || e.keyCode == 37){
        this.lastKeyCode = e.keyCode;
    }else{
        if(this.props.onKeyDown !== undefined){
            this.props.onKeyDown(e);
        }
    }


        
  }
  render() {
    const attr = this.createAttr("acmRadioGroup");
    var optionsRender = [];
    var cislo = 1;
    this.props.options.forEachObject((label, value) => {

        var focus = false;
        if(this.props.autoFocus == true && value == this.props.value){
            focus = true
        }

      optionsRender.push(
        <FormControlLabel
          tabIndex={cislo}
          key={value}
          value={value}
          control={<MaterialRadio autoFocus={focus} onKeyDown={(e) => this.onKeyDown(e,cislo)} />}
          label={label}
        />
      );
      cislo += 1;
    });

    var value = this.state.value;
    if (this.props.value != undefined) {
      value = this.props.value;
    }
    attr.autoFocus=false
    attr.onKeyDown = undefined
    return (
      <MaterialRadioGroup value={value} onChange={(e) => this.onChange(e)} {...attr}>
        {optionsRender}
      </MaterialRadioGroup>
    );
  }
}
