import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import List from "../../list/list";
import TabContainer from "../../formElements/TabContainer";
import Loader from "../../loader";
import Relate from "../../formElements/Relate";
import Button from "../../formElements/Button";
import AcmDate from "../../formElements/AcmDate";

import {Map} from 'immutable'

export default class AddGiftPopup extends PureComponent {
  constructor(props) {
    super(props);

    const { id, name } = sAction.dataGet("conf/user");

    this.state = {
      id: this.props.data.get("id"),
      note: "",
      count: 1,
      user_id: id,
      user_name: name,

      gifts: [],
      participants: [],

      date: new Date(),

      data: 0,
      activeTab: 0,
    };
  }

  componentDidMount() {
    this.loadListView("acm_GiftsStock");
    this.loadListView("acm_CampaignsPartipants");
  }

  handleRelateUser = (returnData) => {
    this.setState({
      user_id: returnData.id,
      user_name: returnData.name,
    });
  };

  handleChange = ({ target: { name, value } }) => {
    if (name === "count" && value < 1) return;
    this.setState({ [name]: value });
  };

  savePopup = () => {
    sAction.load();

    // filter pro vybrane zaznamy v listview
    let prefix = "conf/popup/data/listViewacm_CampaignsPartipants";
    let records = sAction.dataGet(prefix + "/selected");
    var filterParticipants = null;
    if(records === "all"){
      const list = sAction.dataGet(prefix);
      const savedSearch = list.actSavedSearch;
      filterParticipants = sAction.reloadListParams({
        list,
        prefix,
        savedSearch,
        columns: false,
      });
      filterParticipants["selected"] = list.selected;
      filterParticipants["module"] = list.modul;
      filterParticipants["type"] = list.type;
    }

    prefix = "conf/popup/data/listViewacm_GiftsStock";
    records = sAction.dataGet(prefix + "/selected");
    var filterGifts = null;
    if(records === "all"){
      const list = sAction.dataGet(prefix);
      const savedSearch = list.actSavedSearch;
      filterGifts = sAction.reloadListParams({
        list,
        prefix,
        savedSearch,
        columns: false,
      });
      filterGifts["selected"] = list.selected;
      filterGifts["module"] = list.modul;
      filterGifts["type"] = list.type;
    }

    const sendData = {
      ...this.state,
      filter_participants: filterParticipants,
      filter_gifts: filterGifts,
    }
    sAction.rest.post("addGift", sendData, (data) => {
      sAction.popupHide();

      const prefix = this.props.data.get("prefix");
      const subpanelToReload = sAction.dataGet(prefix+"/subpanels").findIndex(s => s.module === "acm_Gifts");
      if (subpanelToReload !== -1) {
        let text = sAction.translate("LBL_DONE","acm_CampaignsAction") + "<br><br>";
        if (data.added_gifts_count) {
          text += sAction.translate("LBL_GIFTS_ADDED_COUNT","acm_CampaignsAction") + ": " + data.added_gifts_count + "<br>";
        }
        if (data.edited_gifts_count) {
          text += sAction.translate("LBL_GIFTS_EDITED_COUNT","acm_CampaignsAction") + ": " + data.edited_gifts_count;
        }

        sAction.reloadList(0, 1, prefix+"/subpanels/"+subpanelToReload+"/rows", null, () => {
          sAction.alert(text);
        });
      } else {
        sAction.unLoad();
      }
    });
  };

  loadListView = (module) => {
    const restData = {
      limit: 25,
      offset: 0,
      order: [
        {
          module,
          field: "date_entered",
          sort: "asc",
        },
      ],
      filter: {
        operator: "and",
        operands: [
          {
            field: "active",
            type: "eq",
            value: 1,
          },
        ],
      },
    };

    let defaultFilter = Map({
      gifts: {
        field: "active",
        type: "eq",
        value: 1,
      }
    });
    if (module === "acm_CampaignsPartipants") {
      restData.filter = {
        operator: "and",
        operands: [
          {
            module: "acm_CampaignsAction",
            type: "relate",
            relationship: ["acm_campaignsaction_acm_campaignspartipants"],
            name: "acm_campaignsaction_acm_campaignspartipants_name",
            filter: {
              operator: "and",
              operands: [
                {
                  field: "id",
                  type: "eq",
                  value: this.state.id,
                },
              ],
            },
          },
        ],
      };

      defaultFilter = Map({
        participants: {
          operandType: "relate",
          parentId: this.state.id,
          module: "acm_CampaignsAction",
          relationship: ["acm_campaignsaction_acm_campaignspartipants"],
          name: "acm_campaignsaction_acm_campaignspartipants_name",
          type: "eq"
        }
      });
    }

    sAction.rest.post("list/" + module, restData, (data) => {
      console.log(data);
      const popup = sAction.dataGet("conf/popup");
      const id = "";

      sAction.dsClear();

      var content = sAction.listContent(data);
      var akce = {
        type: "PAGELOADLIST",
        page: module,
        content: content,
        defaultFilter,
        listViewPrefix: "conf/popup" + id + "/data/listView" + module,
        function: this,
      };
      sAction.dsAdd("set", "conf/popup" + id + "/data/listView" + module, {}, "ListView");
      sAction.dsAddCustom(akce);
      sAction.dsAdd("set", "conf/popup" + id + "/data/callback" + module, this.callback);
      sAction.dsAdd("set", "conf/popup" + id + "/data/listView" + module + "/type", "popup");
      sAction.dsProcess();

      this.setState({ data: this.state.data + 1 });
    }, false);
  };

  changeActiveTab = (e, newTab) => {
    if (newTab === this.state.activeTab) {
      return;
    } else if (newTab > 0 && this.state.gifts.length == 0) {
      return;
    } else if (newTab > 1 && this.state.participants.length == 0) {
      return;
    }

    this.setState({
      activeTab: newTab,
      activeModules: [],
    });
  };

  callback1 = (data) => {
    if (typeof data.id === "string") {
      data = {
        id: [data.id],
      };
    }
    this.setState({ gifts: data, activeTab: 1 });
  };

  callback2 = (data) => {
    if (typeof data.id === "string") {
      data = {
        id: [data.id],
      };
    }
    this.setState({ participants: data, activeTab: 2 });
  };

  render() {
    const { data, date, activeTab } = this.state;
    if (data < 2) return <Loader />;
    var id = "";

    let render = "";
    switch (activeTab) {
      case 0:
        render = (
          <List
            way={"conf/popup" + id + "/data/listViewacm_GiftsStock"}
            // key="popupList"
            data={this.props.data.get("listViewacm_GiftsStock")}
            categories={null}
            selectCallBack={this.callback1}
            module={"acm_GiftsStock"}
          />
        );
        break;

      case 1:
        render = (
          <List
            way={"conf/popup" + id + "/data/listViewacm_CampaignsPartipants"}
            // key="popupList"
            data={this.props.data.get("listViewacm_CampaignsPartipants")}
            categories={null}
            selectCallBack={this.callback2}
            module={"acm_CampaignsPartipants"}
          />
        );
        break;

      case 2:
        render = (
          <div key={activeTab} className="addGiftPopup" style={{flex: "1 1 auto"}}>
          <div className="pageContainer ListViewContainer">
            <div>
            <table>
              <tbody>
                <tr>
                  <th>{sAction.translate("LBL_ACTION", "acm_CampaignsAction")}</th>
                  <td colSpan={2}>{this.props.data.get("name")}</td>
                </tr>
                <tr>
                  <th>{sAction.translate("LBL_GIFTS_CHOOSED", "acm_CampaignsAction")}</th>
                  <td colSpan={2}>
                    {this.state.gifts.id[0] == "all" ? sAction.translate("LBL_ALL") : this.state.gifts.id.length}
                  </td>
                </tr>
                <tr>
                  <th>{sAction.translate("LBL_PARTICIPANTS_CHOOSED", "acm_CampaignsAction")}</th>
                  <td colSpan={2}>
                    {this.state.participants.id[0] == "all"
                      ? sAction.translate("LBL_ALL")
                      : this.state.participants.id.length}
                  </td>
                </tr>
                <tr>
                  <th>{sAction.translate("LBL_GIVER", "acm_CampaignsAction")}</th>
                  <td>
                    <div className="DetailViewValue" style={{ width: "100%" }}>
                      <Relate
                        key={this.state.user_id}
                        newRecord={false}
                        data={{
                          value: this.state.user_name,
                          //label: "Přidělen"
                          //label: "Dárek",
                        }}
                        //buttons={}
                        module={"Users"}
                        //key={keyPre+"_"+field.name}
                        callback={this.handleRelateUser}
                      />
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <th>{sAction.translate("LBL_HANDOVER_DATE", "acm_CampaignsAction")}</th>
                  <td colSpan={2}>
                    <AcmDate
                      placeholder={sAction.translate("LBL_EMAIL_SEARCH_DATE_FROM", "Emails")}
                      value={date}
                      onChange={(date) => this.setState({ date })}
                    />
                  </td>
                </tr>
                <tr>
                  <th>{sAction.translate("LBL_COUNT", "acm_CampaignsAction")}</th>
                  <td colSpan={2}>
                    <input type="number" value={this.state.count} name="count" onChange={this.handleChange} />
                  </td>
                </tr>
                <tr>
                  <th>{sAction.translate("LBL_NOTE", "acm_CampaignsAction")}</th>
                  <td colSpan={2}>
                    <textarea name="note" onChange={this.handleChange} value={this.state.note} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="acmPopupButtons" style={{ justifyContent: "flex-start" }}>
              <Button className="hoverGreen" onClick={this.savePopup}>
                <div className="actionPanelButtonIcon icon-saveIcon" /> {sAction.translate("LBL_ADD_GIFT")}
              </Button>
            </div>
          </div>
          </div>
          </div>
        );
        break;
    }

    return (
      <React.Fragment>
        <div className="acmPopupHeader">{sAction.translate("LBL_ADD_GIFT")}</div>
        <div className="borderBottom">
          <TabContainer
            value={activeTab}
            onChange={this.changeActiveTab}
            tabs={[
              sAction.translate("LBL_CHOOSE_GIFT", "acm_CampaignsAction"),
              sAction.translate("LBL_CHOOSE_PARTICIPANTS", "acm_CampaignsAction"),
              sAction.translate("LBL_SUMMARY", "acm_CampaignsAction"),
            ]}
          />
        </div>
        {render}
      </React.Fragment>
    );
  }
}
