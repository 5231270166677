export default function quoteActivate(data) {
  const field = { way: data.prefix + "/fields/quote_stage", name: "quote_stage", value: "Active" };
  this.saveField(field, true);
  document.getElementById("save_record").click();

  if (this.dataGet(data.prefix+"/canSave")) {
    const opportunity_id = this.dataGet(data.prefix + "/fields/opportunity_name/def/id_value");
    if (opportunity_id) {
      const data = {
        opportunity_id,
        sales_stage: "Value Proposition",
      };
      this.rest.post("opportunityStatusChange", data, returnData => {

      }, false);
    }
  }
}