import {List, Map} from "immutable";

export default function routeListView(init, recordsPerPage = null) {
  const module = this.getModuleName();
  const prefix = "view";
  let lastOrderData = this.getStorage("lastOrder");
  let asc = null;
  if (lastOrderData && lastOrderData[module]) {
    lastOrderData = lastOrderData[module]
    asc = lastOrderData[0]["sort"] === 'ASC' ? 1 : 0;
  }
  
  let saved_search_id = localStorage.getItem(module+"_saved_search");
  let filter = List();
  let limit = 25;
  if ( ! isNaN(parseInt(recordsPerPage))) {
    limit = recordsPerPage;
  } else {
    recordsPerPage = this.dataGet("conf/user/records_per_page");
    if( !! recordsPerPage && ! isNaN(parseInt(recordsPerPage))){
      limit = recordsPerPage ?? 25
    }
  }

  const searchData = {
    limit: limit == 0 ? 25 : limit,
    offset: 0,
    order: lastOrderData,
    filter,
    saved_search_id
  };

  const listViewSearch = this.getStorage('listViewSearch');
  let savedSearchId = saved_search_id;
  if ( ! listViewSearch || listViewSearch.module !== module) {
    this.setStorage("listViewSearch", { module: module, data: searchData });
  }

  const view = this.getViewName();
  const filterData = this.getStorage('listFilterData');
  // view+module kvuli subpanelum
  const reloadData = filterData !== null ? filterData[view+module] : null;
  let actPagination = 1;
  let actFiltering = null;
  let offset = 0;
  let defaultFilter = null;

  if (reloadData && reloadData.page === module && view !== 'import') {
    // musime z objektu filtru ktery se vratil z localStorage udelat immutable
    filter = this.prepareFilterStructure(reloadData.filter);
    offset = reloadData.offset;
    actPagination = reloadData.actPagination;
    actFiltering = reloadData.actFiltering;
    defaultFilter = Map(reloadData.defaultFilter);
  }
  let action = {
    type: "PREPARELIST",
    page: module,
    orderBy: lastOrderData && lastOrderData[0] ? lastOrderData[0]["field"] : null,
    asc: asc,
    actFiltering: actFiltering,
    filter: filter,
    defaultFilter: defaultFilter,
    actPagination: actPagination,
    savedSearch: savedSearchId,
    limit: limit,
    function: this
  };

  let list;

  if (view === this.dataGet('conf/view')) {
    list = this.dataGet(prefix)
  }
  if ( ! list || list.modul !== module) {
    list = this.prepareListStructure(action);
  }

  this.reloadList(offset, actPagination, prefix, savedSearchId, null, list);
}
