export default function activitiesTimeLineParams(data){

    var params = {
        id:data.id,
        relModule:data.module,
        order:data.order,
        offset:0,
        limit:data.limit,
        filter:[],
    }

    var hiddenModules = [];
    data.activities.entrySeq().forEach(e => {
        if(e[1].get("show") == false){
            hiddenModules.push(e[0]); 
        }    
    });
    if(hiddenModules.length != 0){
        params["filter"].push({field:"hiddenModules",value:hiddenModules});
    }

    if(data.status != null && data.status != ""){
        params["filter"].push({field:"status",value:data.status})
    } 
    
    return params;
}