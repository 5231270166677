import React from "react";

import Enum from "../Enum/Enum";
import RadioGroup from "../../formElements/RadioGroup";

import  sAction  from "sAction";

export default class Radioenum extends Enum {
  constructor() {
    super();
    this.state = {
      state: "fine",
      reg: /^[a-zA-ZÀ-ž\.\-\/\,\:\?\!\+\@ 0-9]+$/
    };
  }
  componentDidMount() {}
  render() {
    const data = this.props.data;
    const optionsName = data.def.get("options");
    const options = sAction.app_strings[optionsName];
    return (
      <div className={"inputDetailRadio"}>
        <RadioGroup
          autoFocus={data.actEdit}
          onKeyDown={e => this.onKeyDown(e)}
          value={data.value}
          options={options}
          onChange={e => this.save(e)}
        />
      </div>
    );
  }
}
