export default function defaultListViewEditSetDefault(data){
    this.confrim(this.translate("LBL_LISTVIEW_DEFAULT_CONFRIM"),() => {
        this.load();

        const params = {
            module: data.get("module")
        }
        if(data.get("subpanel") == true){
            params["subpanel"] = true;
            params["parent"] = data.get("parent")
        }
    
        this.rest.post("defaultListviewReset",params, ret => {
            if(ret.state == true){
                this.popupHide();
                this.route();
            }
           
        }) 
    })
}