export default function clearPreviousOrderBy(){

    this.dsClear();

    this.dsAdd("set", "reportWindow/alterName", {});
    this.dsAdd("set", "reportWindow/orderBy", {});
    this.dsAdd("set", "reportWindow/groupBy", {});
    this.dsAdd("set", "reportWindow/fieldFunction", {});

    this.dsProcess()

}