import React from "react";
import PureComponent from "../../pure";

import  sAction  from "sAction";

import TabContainer from "../../formElements/TabContainer";
import Button from "../../formElements/Button";
import RolesTable from "./RolesTable";

export default class RolesPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: [],
      selectedRoles: [],
      activeTab: 0
    };
  }

  changeActiveTab(newTab) {
    if (newTab == this.state.activeTab) {
      return;
    }

    this.setState({ activeTab: newTab });
  }

  updateData = (name, value) => {
    this.setState({ [name]: value });
  };

  savePopup = () => {
    sAction.popupHide();

    const { selectedUsers, selectedRoles } = this.state;

    if (selectedUsers.length || selectedRoles.length) {
      if (this.props.onChange) {
        this.props.onChange({selectedRoles, selectedUsers});
      } else if (this.props.popupData) {
        this.props.popupData.get("callback")({selectedRoles, selectedUsers});
      }
    }
  };

  render() {
    const { selectedUsers, selectedRoles, activeTab } = this.state;

    const roles = sAction.translate("LBL_ROLES", "Home");
    const users = sAction.translate("LBL_USERS", "Home");

    const tabs = [roles, users]; //"Role", "Uživatelé"

    let renderMenu = (
      <TabContainer
        onChange={(e, value) => this.changeActiveTab(value)}
        value={activeTab}
        tabs={tabs}
      />
    );

    return (
      <div className="homeSetViewContainer">
        <div className="homeSetViewContent">
        <div className="viewActionPanel borderBottom">
          <div className="viewActionPanelButtons">
            <Button className="hoverGreen" onClick={() => this.savePopup()}>
            <div className="actionPanelButtonIcon icon-saveIcon" />
            {sAction.translate("LBL_ADD", "Home")}
            {/* Přidat */}
            {selectedRoles.length > 0 || selectedUsers.length > 0
              ? //Vybráno celkem
              " (" + (selectedRoles.length + selectedUsers.length) + ")"
              : "\u00A0"}
            </Button>
            <div className="viewPanelButtonsDelimiter"/>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
              <div className="actionPanelButtonIcon icon-deleteIcon" />
              {sAction.translate("LBL_CANCEL", "Home")}
              {/* Zrušit */}
            </Button></div>
        </div>
        <div className="detailViewTabContent">
          <div className="acmTabContainer">{renderMenu}</div>

          <div className={activeTab != 0 ? "homeTableHide hidden" : "homeTableHide"}>
            <RolesTable
              module="ACLRoles"
              selectedData={selectedRoles}
              onChange={data => this.updateData("selectedRoles", data)}
            />
          </div>
          <div className={activeTab != 1 ? "homeTableHide hidden" : "homeTableHide"}>
            <RolesTable
              module="Users"
              selectedData={selectedUsers}
              onChange={data => this.updateData("selectedUsers", data)}
            />
          </div>
        </div>
        </div>
      </div>
    );
  }
}
