import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";

import AcmDateTime from "../../formElements/AcmDatetime"

export default class PopupAcmDateTime extends PureComponent {
  constructor() {
    super();

  }
  componentDidMount(){

  }
  change(date){
      const data = this.props.data
      if(data.get("callback") != undefined){
          data.get("callback")(date)
      }
      sAction.popupHide();
  }
  render() {
    const data = this.props.data
    return (
        <div style={{display:"none"}} >
        <AcmDateTime autoOpen={true} 
            onChange={(date) => this.change(date)}
            onClose={() => sAction.popupHide()}
            value={data.get("value") ?? null}
        />
        </div>
    );
  }
}
