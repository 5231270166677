export default function createDiscountRequest(params) {
    const opp = this.dataGet(params.prefix + '/fields');
    const user = this.dataGet('conf/user');
    if(!opp.get('ticker_symbol').get('value')) {
        this.error(this.translate('LBL_MISSING_TICKER_SYMBOL', 'Opportunities'));
        return;
    }

    this.detailPredefinedFields = [];
    //nazev firmy

    // Opp
    this.detailPredefinedFields.push({
        type: "relate_simple",
        fieldName: "opportunities_acm_discount_request_1_name",
        name: "opportunities_acm_discount_request_1opportunities_ida",
        value: { id: params.id, "name": params.name }
    });

    // Assigned user
    this.detailPredefinedFields.push({
        type: "relate_simple",
        fieldName: "acm_discount_request_users_name",
        name: "acm_discount_request_usersusers_ida",
        value: { id: user.get('id'), name: user.get('name') }
    });

    // Assigned Dealer
    this.detailPredefinedFields.push({
        type: "relate_simple",
        fieldName: "acm_discount_request_accounts_name",
        name: "acm_discount_request_accountsaccounts_ida",
        value: { id: user.get('account_id'), name: user.get('account_name') }
    });

    // Stav novy
    this.detailPredefinedFields.push({
        type: "field",
        name: "status",
        value: "New"
    });

    this.detailPredefinedFields.push({
        type: "field",
        name: "gross_price",
        value: opp.get('amount').get('value'),
    });

    this.detailPredefinedFields.push({
        type: "relate_simple",
        fieldName: "currency_id",
        name: "currency_id",
        value: { id: opp.get('currency_id'), name: opp.get('currency_name') }
    });

    this.detailPredefinedFields.push({
        type: "field",
        name: "ticker_symbol",
        value: opp.get('ticker_symbol').get('value'),
    });

    this.detailPredefinedFields.push({
        type: "field",
        name: "final_customer",
        value: opp.get('account_name').get('value'),
    });

    this.detailPredefinedFields.push({
        type: "relate_simple",
        fieldName: "acm_discount_request_accounts_1_name",
        name: "acm_discount_request_accounts_1accounts_ida",
        value: { id: opp.get('account_name').get('def').get('id_value'), name: opp.get('account_name').get('value') }
    });

    this.rest.post('getDRDataFromOpp', {opp: params.id}, (returnData) => {
        if (returnData.status) {
            returnData.message.data.forEachObject((value, field) => {
                this.detailPredefinedFields.push({
                    type: "field",
                    name: field,
                    value: value,
                });

            });
        }

        this.detailNewRecord({
            module: "acm_discount_request",
            way: params.prefix
        });
    });
}
