import React from "react";
import PureComponent from "../../pure";
import Relate from "../../formElements/Relate";
import Button from "../../formElements/Button";
import  sAction  from "sAction";

export default class UserSetReplacementPopup extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            user : {
                value: "",
                id_value: ""
            }
        }

    }

    handleSave(){
        const user = this.state.user;
        if(!user.id_value){
            sAction.error(sAction.translate("LBL_SELECT_VALID_USER", "Users"));
            return;
        }

        const params = {action: "user_set_replacement", user : user.id_value};

        sAction.rest.post("customAction", params, function(resp) {
            if(resp.errCode == "OK"){
                sAction.popupHide();
                sAction.route();
    
            }
            else{
                sAction.error(resp.errMsg);
            }
            
        });

    }

    userSelectorCall(item){
       this.setState({user: {value: item.name, id_value: item.id}});
    }

    render(){
        return <div>
        <div className="viewActionPanelButtons">
            <Button className="hoverGreen" onClick={() => this.handleSave()}>
                <div className={"icon-saveIcon calHeaderIcon"} ></div>
                {sAction.translate("LBL_SAVE", "Home")}
            </Button>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                <div className={"icon-close calHeaderIcon"} ></div>
                {sAction.translate("LBL_CANCEL_ACTION")}
            </Button>
        </div>
        <div className="UserSetReplacementFormContainer">
        <div className="quoteTemplNameLbl">
            {sAction.translate("LBL_REPLACEMENT", "Users")}:
        </div>
        <Relate 
            newRecord={false}
            data={this.state.user}
            module="Users"
            key={"users_select_"+this.state.user.id_value}
            callback={(item) => this.userSelectorCall(item)}
        />

        </div>
    </div>;
    }
}