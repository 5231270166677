import React from "react";
import PureComponent from "../pure";

import OldRecordContainer from "./OldRecordContainer";
import NewRecordContainer from "./NewRecordContainer";

import Button from "../formElements/Button";

import  sAction  from "sAction";

export default class Merge extends PureComponent {
  constructor() {
    super();
  }
  componentDidMount() {}
  componentDidUpdate() {}
  render() {
    const data = this.props.data;
    const way = this.props.way;
    var oldRecordsRender = [];

    if (data != null) {
      data.records.forEach((record, key) => {
        oldRecordsRender.push(
          <OldRecordContainer
            way={way}
            index={key}
            data={record}
            key={key}
            def={data.def}
            module={data.module}
            onScroll={index => console.log(index)}
          />
        );
      });
    }
    return (
      <div className="mergeRecordsContainer">
        <div className="viewActionPanel">
          <div className="viewActionPanelButtons">
            <Button
            onClick={() => sAction.saveMerge(way,data)}
            >
              <div
                
                className="actionPanelButtonIcon icon-saveIcon"
              />
              {sAction.translate("LBL_SAVE_RECORD")}
            </Button>
            <Button
            onClick={() => window.location.hash="list/"+data.module}
            >
              <div className="actionPanelButtonIcon icon-detailCancel" />
              {sAction.translate("LBL_CANCEL_ACTION")}
            </Button>
          </div>
        </div>
        <div className="mergeRecordsContent detailCard">
          <div className="mergeRecordsheader header">
            <div className="title">
              {sAction.translate("LBL_MERGE_RECORDS_HEADER")}
            </div>
          </div>
          <div className="mergeRecordsBody">
            <div className="oldRecordsContainer">{oldRecordsRender}</div>
            <div className="newRecordContainer">
              {data != null && (
                <NewRecordContainer
                  way={way}
                  def={data.def}
                  module={data.module}
                  record={data.newRecord}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
