import React from "react";
import PureComponent from "../pure";

class ImportFileCell extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const data = this.props.data;

        return(<td>{data}</td>)

    }
}

export default ImportFileCell;