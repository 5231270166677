export default function reportWindowSaveColumns(columns){
    console.log(columns);
    var self = this;
    this.dsClear();
 //   this.dsAdd("set","reportWindow/columns",{},"reportWindowColumns");
    columns.forEachObject((children,key) => {
        self.dsAdd("set","reportWindow/columns/"+key,[])
        children.forEachObject(child => {
            child.def["show"] = true
            self.dsAdd("add","reportWindow/columns/"+key,{name:child.name,def:child.def},"reportWindowColumn")
        })
    });
    this.dsProcess();


}