import React, { Component } from 'react'

class Text extends React.Component {
    
    constructor(props){
        super(props)
    }

    render(){
        return (
            <input
              type="text"
              ref="input"
              className="form-control form-control-sm coripoInputText"
              {... this.props.attr}
            />
          );
    }
}
export default Text
