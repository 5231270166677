import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";

export default class ChangePasswordPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            token: false,
            error: false,
            message: '',
            passOld: true,
            passNew: true,
            passAgain: true,
            passOldMessage: '',
            passNewMessage: '',
            passAgainMessage: '',
        };
        this.passOld = React.createRef();
        this.passNew = React.createRef();
        this.passAgain = React.createRef();
    }

    onKeyDown(e) {
        if (e.keyCode == 13 && !this.state.message) {
            this.changePassword();
        }
    }

    changePassword() {
        const passNew = this.passNew.current.value;
        const passAgain = this.passAgain.current.value;
        const passOld = this.passOld.current.value;
        if (!passNew || !passAgain || !passOld) {
            this.setState({ passNew: Boolean(passNew), passAgain: Boolean(passAgain), passOld: Boolean(passOld) });
            return;
        }
        if (passNew != passAgain) {
            this.setState({ passAgain: false, passNew: Boolean(passNew), passOld: Boolean(passOld), passNewMessage: '', passOldMessage: '', passAgainMessage: 'LBL_PASSWORDS_DO_NOT_MATCH' });
            return;
        }
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*-_])(?=.{8,})");
        if (!regex.test(passNew)) {
            this.setState({ passNew: false, passNewMessage: 'LBL_REGEX_CHECK_FAILED', passAgainMessage: '', passAgain: Boolean(passAgain), passOld: Boolean(passOld), passOldMessage: '' });
            return;
        }
        const urlArray = window.location.hash.split("/");
        const self = this;
        sAction.changePasswordRequest(passNew, passOld, urlArray[2], (data) => {
            if (data.status) {
                self.setState({ message: data.message, error: false });
            } else {
                if (data.field) {
                    var returnData = { passNew: Boolean(passNew), passNewMessage: '', passAgain: Boolean(passAgain), passAgainMessage: '', passOld: Boolean(passOld), passOldMessage: '', };
                    returnData[data.field] = false;
                    returnData[data.field + 'Message'] = data.message;
                    self.setState(returnData);
                    // self.setState({ [data.field]: false, [data.field + 'Message']: data.message });
                } else {
                    self.setState({ error: true, message: data.message });
                }
            }
        });
    }

    render() {
        var render = '';
        if (!this.state.message) {
            render = this.getForm();
        } else {
            render = this.getMessage();
        }

        return (
            <React.Fragment>
                <div style={{ overflow: 'hidden', height: '0px' }}>
                    <InputText
                        type='text'
                        name='nickname'
                        style={{ opacity: '0' }}
                    />
                    <InputText
                        type='password'
                        name='password'
                        style={{ opacity: '0' }}
                    />
                </div>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_CHANGE_PASSWORD')}</div>
                </div>
                <div className='exportListPopupContent'>
                    <div className='simpleForm'>
                        {render}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    getMessage() {
        return (
            <React.Fragment>
                <table onKeyDown={e => this.onKeyDown(e)}>
                    <tbody>
                        <tr>
                            <td colSpan="2" className='formlabel'>
                                <div className={this.state.error ? 'flexCenter error' : 'flexCenter'}>
                                    {sAction.translate(this.state.message)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    getForm() {
        return (
            <React.Fragment>
                <table onKeyDown={e => this.onKeyDown(e)}>
                    <tbody>
                        <tr>
                            <td className="formlabel">
                                {sAction.translate("LBL_OLD_PASSWORD")}
                            </td>
                            <td>
                                {this.state.passOldMessage ? this.getErrorMessage(this.state.passOldMessage) : null}
                                <InputText
                                    type="password"
                                    error={!this.state.passOld}
                                    myRef={this.passOld}
                                    className="withBorder"
                                    autoFocus={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="formlabel">
                                {sAction.translate("LBL_NEW_PASSWORD")}
                            </td>
                            <td>
                                {this.state.passNewMessage ? this.getErrorMessage(this.state.passNewMessage) : null}
                                <InputText
                                    type="password"
                                    error={!this.state.passNew}
                                    myRef={this.passNew}
                                    className="withBorder"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="formlabel">
                                {sAction.translate("LBL_NEW_PASSWORD_AGAIN")}
                            </td>
                            <td>
                                {this.state.passAgainMessage ? this.getErrorMessage(this.state.passAgainMessage) : null}
                                <InputText
                                    type="password"
                                    error={!this.state.passAgain}
                                    myRef={this.passAgain}
                                    className="withBorder"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <div className="viewActionPanelButtons flexRight">
                                    <Button onClick={() => this.props.close()} className='popupCloseButton'>
                                        <div className={"actionPanelButtonIcon icon-closeIconLight"} />
                                        {sAction.translate("LBL_EXPORT_CLOSE")}
                                    </Button>
                                    <Button onClick={() => this.changePassword()}>
                                        <div className={"actionPanelButtonIcon icon-editDesc"} />
                                        {sAction.translate("LBL_SET_NEW_PASSWORD")}
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    getErrorMessage(message) {
        return (
            <div className='error'>
                {sAction.translate(message)}
            </div>
        );
    }
}