import React from "react";
import FormField from "./../formField.js";

import sAction from 'sAction'

export default class Image extends FormField {

    handleClick(e) {
        const target = e.target
        if(target.classList.contains("icon-upload")){
            this.uploadImage()
        } else if (target.classList.contains("icon-deleteIcon")) {
            this.deleteImage();
        }
        else{
            sAction.popup("image",{url:this.imgUrl()})
        }
    }
    imgUrl() {
        const data = this.props.data;
        var value = "img/no_img.png"

        if (data.value){
            var value = sAction.param.files+"image/"+ data.value
        }

        return value;
    }

    uploadImage() {
        sAction.processFileInput({ multiple: false, way: this.props.way, accept: "image/png,image/jpeg,image/bmp,image/gif" }, file => {
            sAction.uploadImageFile(file, files => {
                const file = files.ids[0];
                sAction.dsClear();
                // deleting new image on leave without saving
                sAction.dsAdd('add', "view/imageActions/deleteOnLeave", file["name"]);
                // deleting of overwritten image on save
                if (this.props.data.value) {
                    sAction.dsAdd('add', "view/imageActions/deleteOnSave", this.props.data.value);
                }
                sAction.dsProcess();
                sAction.saveField({
                    way: this.props.way,
                    name: this.props.data.name,
                    type: this.props.data.def.get("type"),
                    value: file["name"]
                });
            });
        });
    }

    deleteImage = () => {
        sAction.dsClear();
        sAction.dsAdd('add', "view/imageActions/deleteOnSave", this.props.data.value);
        sAction.dsAdd('set', this.props.way + "/value", '');
        sAction.dsAdd("set", "view/changes/fields/" + this.props.data.name, '');
        sAction.dsProcess();
    }

    render() {
        const data = this.props.data
        const def = data.def
        const imgUrl = this.imgUrl()
        let deleteIcon
        if (imgUrl !== "img/no_img.png") {
           deleteIcon =  <div className="imageFieldIcon icon-deleteIcon"/>
        }
        var overrideStyle = {};
        const imgHeight = def.get("imgHeight");
        if(imgHeight != undefined){
            overrideStyle["height"] = imgHeight
        }

        return (
            <div className="imageFieldContainer" style={overrideStyle}>
                <img src={imgUrl} />
                <div className="imageFieldHover" onClick={(e) => this.handleClick(e)}>
                    <div className="imageFieldIcon icon-upload"></div>
                    {deleteIcon}
                </div>
            </div>
        )
    }
}