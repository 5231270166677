import React from "react"
import ReactDOM from 'react-dom';
import PureComponent from "../pure";
import sAction from "sAction";
import Loader from "../loader";

export default class RecentRecordsManager extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showComponent: false,
            dataReturned: false,
            rows: []
        };
        this.restDataEmptyMessage = null
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    changeState() {
        if (this.state.showComponent) {
            this.setState({
                showComponent: false,
                dataReturned: false,
                rows: []
            })

            this.restDataEmptyMessage = null
        } else {
            this.setState({showComponent: true,})
            this.getDataFromRest()
        }

    }

    handleClickOutside = event => {
        if (this.state.showComponent) {
            const domNode = ReactDOM.findDOMNode(this);

            if (!domNode || !domNode.contains(event.target)) {
                this.setState({
                    showComponent: false,
                    dataReturned: false,
                    rows: []
                })
            }
        }
    }


    getDataFromRest() {
        sAction.rest.post("getRecentRecords", null, returnData => {
            console.log(returnData)
            if (returnData.message.text === "success") {
                returnData.message.data.forEach((data) => {
                    let url = `#detail/${data.moduleName}/${data.itemId}/`
                    this.state.rows.push(<tr className="recordLine" key={data.itemId}
                                             onClick={() => window.location.replace(url)}>
                        <td className="dateTimeItemRecords">{data.dateTime}</td>
                        <td className="itemName">{data.item_summary}
                            <span
                                className="moduleName">{" (" + sAction.translate("LBL_MODULE_NAME", data.moduleName) + ")"}
                            </span>
                        </td>
                    </tr>)
                })
            } else {
                this.restDataEmptyMessage = sAction.translate("LBL_NO_RECENT_RECORDS", returnData.message.text)
            }
            this.setState({dataReturned: true})

        })
    }


    renderTable() {
        return (
            <>
                {
                    !this.restDataEmptyMessage ?
                        <table className="recordsTable">
                            <thead className="recordsHeader">
                            <tr className="tableHead">
                                <th>{sAction.translate("LBL_RECENT_RECORDS_HEADER", "Poslední záznamy")}</th>
                            </tr>
                            </thead>
                            <tbody className="recentRecordsLineContainer">{this.state.rows}</tbody>
                        </table>
                        : <div className="messageContainer">
                            <div className="messageContent">{this.restDataEmptyMessage}</div>
                        </div>
                }
            </>
        )


    }

    getClass() {
        if (!this.state.showComponent) {
            return ""
        }
        return "open";
    }

    render() {
        return (
            <div className={`icon-recentRecords recordsButtonContainer ${this.getClass()}`}
                 onClick={() => this.changeState()}>
                {this.state.showComponent ?
                    <div className="recentRecordsContainer" onBlur={() => this.changeState()}>
                        <div className="recentRecordsContent">
                            {this.state.dataReturned ? this.renderTable() : <Loader key={"zero"}/>}
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}




