export default function detailEditDefaultLayout(way) {
  const hiddenFields = [];
  const fields = this.dataGet(way + "/fields");

  fields.entrySeq().forEach((e) => {
    if (e[0] != "empty_row") {
      const def = e[1].get("def");
      if (def.get("customField") == true) {
        hiddenFields.push(e[1].get("name"));
      }
    }
  });
  const params = {
    hiddenFields,
    module: this.dataGet(way + "/module"),
  };
  this.load();
  this.rest.post("setDefaultLayout", params, (ret) => {
    if (ret["state"] == true) {
      this.routeDetailView();
    } else {
      this.unLoad();
      this.alert(ret.message);
    }
  });
}
