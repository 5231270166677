import React from "react";
import ListItem from '@material-ui/core/ListItem';
export default class WaveEffect extends React.PureComponent{
    render(){

    
    
    return(
        <ListItem button className={this.props.className != undefined ? "waveEffect "+this.props.className : "waveEffect"}>
            {this.props.children}
        </ListItem>
    )
    }
}