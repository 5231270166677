import React, { Component } from "react";
import PureComponent from "../../pure";

import OppProduct from "./OppProduct";
import  sAction  from "sAction";

class OppProductComponent extends PureComponent {

  render() {
    const data = this.props.data;
    const way = this.props.way;
    const readonly = this.props.readonly;
    var lines = [];
    var total = 0;

    if (data) {
      data.get("lines").forEach((line, k) => {
        total += line.discount_price;
        lines.push(<OppProduct data={line} key={k} ind={k} oppWay={way} way={way + "/lines/" + k}
                               readonly={readonly}/>);
      });
    }

    return (
      <div className="detailCard OppRowsContainer">
        <div className="header">
          <div className="title"><span className="detailViewHeaderIcon icon-Products"></span><span
            className="detailViewName">{sAction.translate("LBL_PRODUCTS_SUBPANEL_TITLE", "Products")}</span></div>
        </div>
        {lines.length > 0 && (
          <table style={{ width: "calc(100% - 50px)" }}>
            <thead>
            <tr className="oppProductsHeader">
              <th style={{ width: 250 }}>{sAction.translate("LBL_PRODUCT_NAME", "Opportunities")}</th>
              <th style={{ maxWidth: 150 }}>{sAction.translate("LBL_PRODUCT_QUANTITY", "Opportunities")}</th>
              <th style={{ maxWidth: 150 }}>{sAction.translate("LBL_CELKEM", "acm_OpportunityProducts")}</th>
              <th>{sAction.translate("LBL_PRODUCT_DESCRIPTION", "Opportunities")}</th>
            </tr>
            </thead>
            <tbody>
            {lines}
            <tr className="oppTotalLineAmount">
              <td></td>
              <td></td>
              <td className="oppTotalAmount">{sAction.translate("LBL_PRODUCT_TOTAL_PRICE", "Opportunities")}
                <br/><b>{sAction.formatNumber(total)}</b></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default OppProductComponent;