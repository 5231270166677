import { Map, List, fromJS } from "immutable";
import { DataConstructors } from "../../../DataConstructors";
import processSubpanel from "./processSubpanel";
import processCustomData from "./processCustomData";

export default function detailCustom(state, action) {
  var allState = state;

  var prefix = ["detailView"];
  if (action.prefix != undefined) {
    prefix = action.prefix.split("/");
  }
  state = state.getIn(prefix);
  //
  var type = action.type;
  if (type == "PAGELOADDETAIL") {
    const data = action.content;
    const rowCount = data.rowCount;
    const module = action.content.module;

    var tabs = []; // přijde do DetailView.tabs jako List(tabs)

    data.tabs.forEach(tab => {
      var tabRows = []; // přijde do Tab.rows jako List(tabRows)
      tab.rows.forEach(row => {
        
        if (row.type == "headerPanel") {
          tabRows.push(new Map(row));
        } else {
          var listRow = [];
          row.forEach(field => {
           
            if (field.name != "") {
              listRow.push(field.name);
            }
           
            // __________________________________ ze sugaruz chodí špatný currency id type
            if (field.name == "currency_id") {
              field.def.type = "currency_id";
            }
            var def = fromJS(field.def);
            if (def == null) {
              def = new Map();
            }
            var newField = new DataConstructors["Field"]({
              name: field.name,
              value: field.value,
              tab: tabs.length,
              row: tabRows.length,
              def: def
            });
          //  console.log(field);
            //  console.log(action);
            //________________________________EVENT NA EDITACI
            //   if(field.edit == true){

            const event = new DataConstructors["Event"]({
              key: "onClick",
              functionName: "fieldToEdit"
            });
          });
          
          if(listRow.length < 2){
            listRow.push("empty_row");
          }
       //   console.log(listRow);
          tabRows.push(List(listRow));
        }
      });
      var active = false;
      if (tabs.length == 0) {
        active = true;
      }
      const newTab = new DataConstructors["Tab"]({
        id: tabs.length + 1,
        name: tab.name,
        type: "standard",
        active: active,
        rows: List(tabRows)
      });
      tabs.push(newTab);
    });

    // _______________________________________________ naplnění fields
    var fields = {};
    data.fields.forEachObject((field, name) => {
    //  console.log(data.fields);
      var def = fromJS(field.def);
      if (def == null) {
        def = new Map();
      }

      if (field.def){
        if (field.def.name == "currency_id") {
          field.def.type = "currency_id";
          if (!field.value){
            // todo: zde by se mela nacist mena, co ma uzivatel na profilu - $current_user->getPreference('currency');
            field.value = "-99";
            if (!action.content.changes){
              action.content.changes = {};
            }
            // action.content.changes['currency_id'] = "-99"; // resi se az na backendu, pokud si user tu menu nezmeni
          }
        }
      }

      const newField = new DataConstructors["Field"]({
        name: name,
        value: field.value,
        def: def
      });
      fields[name] = newField;
    });
    // _______________________________________________ naplnění fields END

    //________________________________________________________ACTION BUTTONS
    const buttons = action.content.actionButtons;
    var dButtons = [];
    buttons.forEach(button => {
      if (button.params != undefined) {
        var paramsList = [];
        button.params.forEach(param => {
          paramsList.push(new Map(param));
        });
        button.params = List(paramsList);
      }

      const b = {
        id: button.id,
        def: new Map(button)
      };
      dButtons.push(new DataConstructors["DetailButton"](b));
    });
    //________________________________________________________ACTION BUTTONS

    //_________________________________________ SUBPANELS
    const subpanels = action.content.subpanels;
    var subpanelsPole = [];
    subpanels.forEachObject(subpanel => {
      const subData = {
        module: module,
        subpanel: subpanel
      };
      subData.parentId = data.id;

      const subpanelData = processSubpanel(subData);

      subpanelsPole.push(subpanelData);
    });
    // _________________________________________ SUBPANELS END

    // _________________________________________ TIMELINE
    const timeline = action.content.timeline;
    var timeLineData = null;
    if (timeline != undefined) {
      var listData = null;
      try{
        timeline.list = timeline.list.toJS();
      }catch(e){

      }
      if (timeline.list.type == "list") {
        listData = new Map(timeline.list);
      } else if (timeline.list.type == "custom") {
        var value = {};
        timeline.list.value.forEachObject((data, key) => {
          value[key] = {
            label: data.label,
            values: List(data.values)
          };
        });
        listData = new Map({
          type: timeline.list.type,
          value: new Map(value),
          name: timeline.list.name
        });
      }

      timeLineData = DataConstructors.timeline({
        field: timeline.field,
        list: listData,
        clickable: timeline.clickable,
        onClick: new Map(timeline.onClick),
        colors: new Map(timeline.colors),
        titles: new Map(timeline.titles)
      });
    }
    // _________________________________________ TIMELINE END

    // _________________________________________ DASHBOARD
    var dashboard = null;
    if (action.content.dashboard != null) {
      dashboard = fromJS(action.content.dashboard);
    }
    // _________________________________________ DASHBOARD END

    // _________________________________________ ACTIVITIES
    var activities = null;
    if (action.content.activities != null) {
      activities = fromJS(action.content.activities);
    }
    // _________________________________________ ACTIVITIES END

    //_________________________________________ CUSTOM DATA

    if (data.type == "popup" && (module == "Meetings" || module == "Calls")) {
      //pridani tabu
      const invTab = new DataConstructors["Tab"]({
        id: tabs.length + 1,
        name: "LBL_PARTICIPANTS_TAB",
        active: false,
        type: "invitees"
      });
      tabs.push(invTab);
    } else if (module === "Users" && data.id && allState.conf.user.is_admin == 1) {
      //pridani tabu
      const aclTab = new DataConstructors["Tab"]({
        id: tabs.length + 1,
        name: "LBL_USER_ACCESS",
        active: false,
        type: "acl_access"
      });
      tabs.push(aclTab);
    }

    var customData = processCustomData(data);
    
    var type = action.content.type
    if(state != undefined){
      type = state.type
    }
    var changes = null;

    let custDataChanges = new Map();

    if (customData && customData.customLines) {
      custDataChanges = {customLines: new List()};
    }

    if (action.content.changes === undefined) {
      changes = new Map({
        fields: new Map(),
        files: List(),
        customData: custDataChanges,
        forceChange: false
      });
    } else {
      changes = new Map({
        fields: new Map(action.content.changes),
        files: List(),
        customData: custDataChanges,
        forceChange: false
      });
    }
    const newDetailView = new DataConstructors["DetailView"]({
      id: data.id,
      name: data.recordName,
      module: module,
      rowCount: rowCount,
      fields: new Map(fields),
      tabs: List(tabs),
      buttons: List(dButtons),
      subpanels: List(subpanelsPole),
      customJS: List(action.content.customJS),
      customData: customData,
      timeline: timeLineData,
      dashboard: dashboard,
      activities: activities,
      readonly: data.readonly,
      changes: changes,
      type,
      acl: new Map(data.acl),
    });

    return allState.setIn(prefix, newDetailView);
  }

  if (type == "LOADSUBPANEL") {
    const subpanel = action.subpanel;
    const data = action.content
    const module = action.parent;
    const subData = {
      module: module,
      subpanel
    };
    subData.parentId = action.parentId;
    const subpanelData = processSubpanel(subData);

    var way = action.way.replace(prefix.join("/") + "/", "").split("/");
    state = state.setIn(way, subpanelData);
    return allState.setIn(prefix, state);
  } else if (type == "QUOTERPDATAINIT") {
    const data = action.content;

    var favList = [];
    data.records.favorites.forEach(function(val, ind) {
      //data.favorites[ind] .def = {show :true};
      //val.def = {show:true};
      favList.push(new Map(val));
    });

    var topList = [];
    data.records.top.forEach(function(val, ind) {
      topList.push(new Map(val));
    });

    var recentList = [];
    data.records.recent.forEach(function(val, ind) {
      recentList.push(new Map(val));
    });

    var rpData = {
      favorites: List(favList),
      recent: List(recentList),
      top: List(topList)
    };

    return allState.setIn(
      prefix,
      new DataConstructors["quoteProductSelector"](rpData)
    );
  } else if (type == "LEADCONVERTALLOCATION") {
    const prefix = action.prefix.split("/");
    var lists = [];
    for (let i = 0; i < action.content.length; i++) {
      lists.push(new DataConstructors["DetailView"]());
    }
    return allState.setIn(
      prefix,
      new DataConstructors["leadConvert"]({
        detailViews: List(lists),
        action: "convertion",
        record: action.content.record,
        name: action.content.name
      })
    );
  } else if (type == "LEADCONVERTHANDLERESULTS") {
    const prefix = action.prefix.split("/");
    return allState.setIn(
      prefix,
      new DataConstructors["leadConvert"]({
        newBeans: List(action.content.newBeans),
        action: "displayResults",
        name: action.content.name
      })
    );
  }else if(type == "DETAILLOCALSTORAGERESTORE"){
    var customData = processCustomData(action.content);
    return allState.setIn(['view', 'customData'], customData);
  } else if (type == "PRODUCTCATEGORIESTREE") {
    //var tree = createListViewTree(action.content, true)
    //console.log("RP tre", tree);
    /*
      var treeData = {
        module: action.content.listViewTree.module,
        name: action.content.listViewTree.name,
        field: action.content.listViewTree.field,
        relationship: action.content.listViewTree.relationship,
        tree: List([tree]),
      }
      if(state !== null){
        treeData["activeCategory"] = state.getIn(["listViewTree","activeCategory"]);
      }
    }
    */
    /*
    const data = action.content;
    
    var tree = [];
    data.forEachObject((elem) => {
        elem.type = "group";
        if(elem.parent_id.length > 0){
          data[elem.parent_id].children.push(elem);
        }
        else{
          tree.push(elem);
        }
    });
    console.log("Parser", prefix, tree)
    return allState.setIn(prefix, List(tree));
    */
  }
  return allState;
}

export function addEvent(field, event) {
  var list = field.get("events").push(event);
  return field.set("events", list);
}

function handleCustomData() {}
