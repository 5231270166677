export default function askDeleteRecord(params){
  this.confrim(this.translate("LBL_BUTTON_CONFIRM_DELETE_RECORD"),() => {
    this.deleteRecord(params);

    if (params.type === 'rightPanel') {
      this.dsClear();
      this.dsAdd('set', params.type + '/show', false);
      this.dsProcess();
    }
  });
}
