export default function calActionContent(data){
    let retVal = {};
    const panels = data.view.panels;
    var tabs = new Array();
    var recordName = null;
    for (var key in panels) {
      if (panels.hasOwnProperty(key)) {
        var value = panels[key];
        var tab = {};
        tab.name = key;
        tab.rows = new Array();
      
        value.forEach(function(el,rowIndex){
          if(Array.isArray(el)){
          el.forEach(function(field,index){

            var name = null;
            if(typeof field == "object"){
              name = field.name
            }else{
              name = field;
            }
           

            if(tab.rows[rowIndex] == undefined){
              
              tab.rows[rowIndex] = new Array();
            }
            
            var def = data.def[name];
            if(def == undefined){
              
              if(name.endsWith("_name")){
               
                const editName = name.substr(0,name.lastIndexOf("_name"));
                def = data.def[editName];
              }
            }

            if(name == "duration_hours" && def.type == "int"){
              def.type = "hoursMinutes";
            }
            
            if(name == "name"){
              recordName = data.record[name]
            }else if(name == "last_name"){
              recordName = data.record["last_name"]+" "+data.record["first_name"]
            }
            if (def != undefined) {
              if (def.type == "relate") {
                def["id_value"] = data.record[def.id_name]

              }
            }

            const filedToStore = {
              name: name,
              value: data.record[name],
              def:  def,
            }


            tab.rows[rowIndex].push(filedToStore);


          });
        }else{
          for (var index in el) {
            if (el.hasOwnProperty(index)) {
              const field = el[index];

                 
            if(tab.rows[rowIndex] == undefined){
              
              tab.rows[rowIndex] = new Array();
            }

            const filedToStore = {
              name: field.name,
              value: data.record[field.name],
              def:  data.def[field.name],
            }


            tab.rows[rowIndex].push(filedToStore);

        }
      }
    }
        });
        tabs.push(tab);
        
      }
    }
    retVal.recordName = recordName;
    retVal.tabs = tabs;
    retVal.customJS = data.js;
    //_________ SUBPANELS
    
    retVal.subpanels = data.subpanels;

    retVal.subpanels = [];
    retVal.actionButtons = [];

    return retVal;
}