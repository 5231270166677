export default function convertLead(data){
    const state = this.dataGet(data.prefix+"/fields/status/value");

    if(state === "Converted" || state === "Dead" || state === 'Canceled'){
        this.error(this.translate('LBL_CANNOT_TRANSFER_LEAD', 'Leads'));
    }
    else{
        this.href("#leadConvert/"+data.id);
    }
}
