export default function createDiscountRequest(params) {
    const vehicle = this.dataGet(params.prefix + '/fields');
    const user = this.dataGet('conf/user');

    this.detailPredefinedFields = [];
    //nazev firmy
    this.load();
    this.rest.post('getDRInfoFromCommPolicy', {vehicleID: params.id}, (returnData) => {
        if (returnData.status === true) {
            if (returnData.message && returnData.message.data && returnData.message.data.found === true) {
                this.detailPredefinedFields.push({
                    type: "field",
                    name: "extra_discount",
                    value: returnData.message.data.extraDiscount,
                });

                this.detailPredefinedFields.push({
                    type: "field",
                    name: "base_discount",
                    value: returnData.message.data.baseDiscount,
                });
            } else {
                this.detailPredefinedFields.push({
                    type: "field",
                    name: "extra_discount",
                    value: 0,
                });

                this.detailPredefinedFields.push({
                    type: "field",
                    name: "base_discount",
                    value: 0,
                });
            }

            // Vehicle
            this.detailPredefinedFields.push({
                type: "relate_simple",
                fieldName: "acm_discount_request_acm_stock_vehicles_name",
                name: "acm_discount_request_acm_stock_vehiclesacm_stock_vehicles_ida",
                value: { id: params.id, "name": params.name }
            });

            // Assigned user
            this.detailPredefinedFields.push({
                type: "relate_simple",
                fieldName: "acm_discount_request_users_name",
                name: "acm_discount_request_usersusers_ida",
                value: { id: user.get('id'), name: user.get('name') }
            });

            // Assigned Dealer
            this.detailPredefinedFields.push({
                type: "relate_simple",
                fieldName: "acm_discount_request_accounts_name",
                name: "acm_discount_request_accountsaccounts_ida",
                value: { id: user.get('account_id'), name: user.get('account_name') }
            });

            // Stav novy
            this.detailPredefinedFields.push({
                type: "field",
                name: "status",
                value: "New"
            });

            // Vehicle Type - ???
            // this.detailPredefinedFields.push({
            //     type: "field",
            //     name: "type_vehicle",
            //     value: "New"
            // });


            this.detailPredefinedFields.push({
                type: "field",
                name: "vehicle_model",
                value: vehicle.get('model').get('value'),
            });


            this.detailPredefinedFields.push({
                type: "field",
                name: "vehicle_range",
                value: vehicle.get('range_f').get('value'),
            });


            this.detailPredefinedFields.push({
                type: "field",
                name: "gross_price",
                value: vehicle.get('gross_price').get('value'),
            });


            // this.detailPredefinedFields.push({
            //     type: "field",
            //     name: "base_discount",
            //     value: vehicle.get('base_discount').get('value'),
            // });

            this.detailPredefinedFields.push({
                type: "field",
                name: "net_price",
                value: vehicle.get('net_price').get('value'),
            });

            this.detailPredefinedFields.push({
                type: "field",
                name: "transport_cost",
                value: vehicle.get('transport_cost').get('value'),
            });

            this.detailPredefinedFields.push({
                type: "field",
                name: "already_discount",
                value: vehicle.get('extra_discount').get('value'),
            });

            this.detailPredefinedFields.push({
                type: "field",
                name: "currency_id",
                value: vehicle.get('currency_id').get('value'),
            });

            // this.detailPredefinedFields.push({
            //     type: "field",
            //     name: "extra_discount",
            //     value: vehicle.get('extra_discount').get('value'),
            // });
            //
            // this.detailPredefinedFields.push({
            //     type: "field",
            //     name: "base_discount",
            //     value: vehicle.get('base_discount').get('value'),
            // });

            this.detailPredefinedFields.push({
                type: "field",
                name: 'gross_price',
                value: vehicle.get('sales_order_value').get('value'),
            });

            // this.detailPredefinedFields.push({
            //     type: "field",
            //     name: "",
            //     value: vehicle.get('stock_type').get('value'),
            // });

            this.unLoad();
            this.detailNewRecord({
                module: "acm_discount_request",
                way: params.prefix
            });
        }
    });
}
