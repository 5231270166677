import React from "react"
import InfoTable from "./views/InfoTable"
export default function CustomDataTop(props){
    const data = props.data;
    const module = props.module;
    var component = null;
    if(data.get("infoTable") != undefined){
        component = <InfoTable data={data.get("infoTable")} module={module} />
    }

    return(
        component
    )
}