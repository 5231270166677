export default function sendMassEmail(params) {
  const list = this.dataGet(params.prefix);
  var records = list.selected;

  var filterData = null;
  if(records === "all"){
    const savedSearch = list.actSavedSearch;
    filterData = this.reloadListParams({
      list,
      prefix: params.prefix,
      savedSearch,
      columns: false,
    });
    filterData["selected"] = list.selected;
    filterData["module"] = list.modul;
    filterData["type"] = list.type;
  }


  this.load();
  this.rest.post("email/getEmailByBeanId", { bean_id: records, module: list.modul, filter: filterData }, returnData => {
    this.unLoad();
    if (!returnData) {
      // Nepodařilo se spojit se serverem nebo získat data, opakujte prosím akci.
      this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_DATA", "Emails"));
    } else if (typeof returnData !== "object" || !("emails" in returnData)) {
      // Data nebyla vrácena ve správném formátu. Výstup:
      this.error(this.translate("ERR_SEND_MASS_EMAIL_FORMAT", "Emails") + returnData);
    } else if (!returnData.emails.length) {
      // U vybraných firem nebyly nalezeny žádné e-maily.
      this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_EMAILS", "Emails"));
    } else {
      if (returnData.not_found.length) {
        var links = "";

        returnData.not_found.forEach(f => {
          let name = list.rows.find(r => r.get("key") === "NAME");
          name = name.records.find(r => r.get("id") === f);
          name = (name) ? name.get("value") : "___";

          links += '<a target="_blank" href="#detail/'+list.modul+'/' + f + '">' + name + "</a><br>";
        });

        this.confrim(
          // Následující firmy nemají vyplněn žádný e-mail, chcete pokračovat?
          this.translate("ERR_SEND_MASS_EMAIL_MISSING_EMAILS", "Emails") + "<br><br>" + links,
          () => {
            this.popupHide();
            this.load();
            // zeptame se znovu restu na ty chybejici emaily... je mozne, ze uz byly dovyplneny
            this.rest.post("email/getEmailByBeanId", { bean_id: returnData.not_found, module: list.modul, filter: filterData }, returnData2 => {
              this.unLoad();
              var data = {
                to_addrs: [...returnData.emails, ...returnData2.emails].join(", "),
                //send_individually: true,
              };

              this.openEmailPopup(data);
            });
          }
        );
      } else {
        var data = {
          to_addrs: returnData.emails.join(", "),
          //send_individually: true,
        };
        this.openEmailPopup(data);
      }
    }
  });
}
