export default function openRolesPopup(title, callback) {
  if (!title) title = this.translate("LNK_NEW_ROLE", "Roles");

  const popup = this.dataGet("conf/popup");
  const id = popup.show && popup.content != "load" ? "2" : "";

  this.dsClear();
  this.dsAdd("set", "conf/popup" + id + "/show", true);
  this.dsAdd("set", "conf/popup" + id + "/data", {});
  this.dsAdd("set", "conf/popup" + id + "/data/header", title);
  this.dsAdd("set", "conf/popup" + id + "/data/callback", callback);
  this.dsAdd("set", "conf/popup" + id + "/content", "roles");
  this.dsProcess();
}
