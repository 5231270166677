export default function detailEditAddNewHeader(way, key, module) {
  const newLabelKey = "LBL_DETAILVIEW_HEADER_" + Date.now();
  const newTabTranslate = this.translate("LBL_EDIT_NEW_HEADER");

  const lineData = { type: "headerPanel", name: newLabelKey };

  const params = {
    module: module,
    translate: newTabTranslate,
    vname: newLabelKey,
  };
  this.load();
  this.rest.post("saveCustomTranslate", params, (ret) => {
    this.unLoad();
    if (ret.state == true) {
      this.moduleTranslate[module][newLabelKey] = newTabTranslate;
      if (key == null) {
        this.dataAdd(way + "/rows", lineData);
      } else {
        const rows = this.dataGet(way + "/rows").toJS();
        rows.splice(key, 0, lineData);
        this.dsClear();
        this.dsAdd("set", way + "/rows", []);
        rows.forEach((row) => {
          this.dsAdd("add", way + "/rows", row);
        });
        this.dsProcess();
      }
    }
  });
}
