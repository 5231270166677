import React from "react"
import PureComponent from "../../pure"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import  sAction  from "sAction";
import EmailAssignField from "../../email/EmailAssignField"
import { EmailPopupActionPanel } from "./shared/EmailPopupActionPanel"

export default class EmailAssignPopup extends PureComponent {
  constructor(props) {
    super(props);

    const { uids, ieId, folder } = this.props;

    this.state = {
      uids,
      ieId,
      folder,
      distribute_method: "direct",
      users: "",
    };
  }

  handleChange = returnData => {
    this.setState({
      users: returnData.id,
    });
  };

  savePopup = () => {
    sAction.load();
    sAction.rest.post("email/doAssignmentAssign", this.state, data => {
      sAction.popupHide();
      sAction.unLoad();
    });
  }

  render() {
    return (
      <div className="email__popup">
        {/*Přiřadit*/}
        <div className="acmPopupHeader">{sAction.translate("LBL_EMAIL_ASSIGN_TO")}</div>
        <EmailPopupActionPanel onSave={this.savePopup} />
        <div className="email__popup__items">
          <DialogContent>
            <DialogContentText />

            <EmailAssignField onChange={this.handleChange} />

          </DialogContent>
        </div>
      </div>
    );
  }
}
