export default function subpanelMaxSize(data,prefix){
    /*
    const parentData = this.dataGet(prefix);
    console.log(data.toJS());
    var columns = null;
    columns = [];
    data.rows.rows.forEach(row => {
      columns.push({
        field: row.key.toLowerCase(),
        function: null,
        module: data.modul,
        width:row.width,
      })
    })
    
    */

}