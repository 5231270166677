import React, { PureComponent } from "react";
import  sAction  from "sAction";
import composeEmail from './composeEmail'

export default class EmailFieldView extends PureComponent {

  estopPropagation = e => {
    e.stopPropagation();
  };

  render() {
    // console.log("render EmailFieldView()");
    const value = [];

    this.props.data.forEach((email, i) => {
      const primary = email.get("primary_address") === "1" ? "("+sAction.translate("LBL_EMAIL_PRIMARY")+")" : "";
      const opt_out = email.get("opt_out") === "1" ? "("+sAction.translate("LBL_EMAIL_OPT_OUT", "Emails")+")" : "";
      const invalid = email.get("invalid_email") === "1" ? "("+sAction.translate("LBL_EMAIL_INVALID")+")" : "";

      let key = email.get("email_address_id");
      if (!key) key = email.get("email_address");
      if (!key) return;

      let text;
      let email_link = (
        <a onClick={composeEmail(this.props.prefix)} data-email={email.get("email_address")}>
          {email.get("email_address")}
        </a>
      );
      if (opt_out && invalid) {
        email_link = <del>{email_link}</del>;
        text = <span style={{ color: "red" }}>({sAction.translate("LBL_EMAIL_OPT_OUT_AND_INVALID")})</span>;
      } else if (opt_out) {
        email_link = <del>{email_link}</del>;
        text = <span style={{ color: "red" }}>{opt_out}</span>;
      } else if (invalid) {
        email_link = <del>{email_link}</del>;
        text = invalid;
      } else if (primary) {
        text = primary;
      } else {
        text = "";
      }

      let key2 = `${key}_${i}`;

      value.push(
        <div key={key2} data-email={key2}>
          {email_link}&nbsp;{text && <em>{text}</em>}
        </div>
      );
    });

    return <div id="email1">{value}</div>;
  }
}
