import rest from "./rest";

export default function init(paramData, callback) {

  if(window.location.search != ""){
    var urlParams = new URLSearchParams(window.location.search);
    var urlData = {};
    urlParams.forEach((val,key) => {
      urlData[key] = val;
    });
    urlData = this.toBase64(urlData);
    var r = urlParams.get("r");
    if(r != null){
      
      window.location.href = window.location.origin+"/#"+r+"/"+urlData;
    }
  }


  this.dataReducer = "app";
  this.param = paramData;

  this.notificationInterval = null;
  this.currencies = null;
  this.customClass = null;
  this.colorTemplate = new Array(
    "#898CFF",
    "#71E096",
    "#5AD0E5",
    "#BB96FF",
    "#FF89B5",
    "#F5A26F",
    "#DA97E0",
    "#EFCE7E",
    "#668DE5",
    "#90D4F7",
    "#ED6D79",
    "#FF96E3",
    "#93BFDC",
    "#E09399",
    "#B6D96E",
    "#FF9393",
    "#79CB9F",
    "#BDCCAA",
    "#5AD0E5",
    "#65D8CC"
  );
  this.detailTempData = {};
  this.rest = new rest(this);
  this.objectLoopInit();
  this.overwriteStyle();

  if (window.location.hash.startsWith("#custom")) {
    this.doRoute = false;
    this.routeCustom();
  }
  
  this.hashChangeEvent();
    this.rest.get("checksid", data => {
      this.initData(data);
      var view = this.getViewName();
      this.route(true,data,view == "login" ? "home" : null);
      this.renderReact();
      this.checkNewNotifications(); 
      this.doRoute = true;
    });
}
