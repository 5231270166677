import React from "react";
import PureComponent from "../../pure";
import sAction from 'sAction';
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import Button from "../../formElements/Button";
import AcmDate from "../../formElements/AcmDate";
import Tag from '../../Tags/Tag';

export default class AddRelate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      type: "",
      validUntil: "",
      open: false
    };
  }

  setFilter(way, filter, record, module) {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
    var self = this;
    this.interval = setInterval(() => {
      sAction.getTags(way, filter, record, module);
      clearInterval(self.interval);
    }, 300);
  }

  addTag(id, way, prefix) {
    const data = this.props.data;
    const record = data.get("record");
    const module = data.get("module");
    sAction.addTagRelate(id, way, prefix, record, module);
  }

  createNewTag(way, prefix) {
    sAction.createTag(
      {
        name: this.state.name,
        type: this.state.type,
        validUntil: this.state.validUntil
      },
      way,
      prefix
    );
  }

  showCreateNew() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const data = this.props.data;
    const way = this.props.way;
    const tags = data.get("tags");
    const message = data.get("message");
    const prefix = data.get("prefix");
    const record = data.get("record");
    const module = data.get("module");

    var renderData = [];
    if (tags) {
      tags.forEach((tag, key) => {
        renderData.push(
          <Tag 
            keyId={key}
            tag={tag}
            way={way}
            key={key}
            colored={true}
            onClick={e => this.addTag(tag.get("id"), way, prefix)}
            removable={false}
          />
        );
      });
    } else {
      renderData = sAction.translate("LBL_NO_TAG_FOUND");
    }
    const tagTypes = sAction.getEnumForSelect("type_2");

    const createNew = (
      <div className="popupTagCreate">
        <InputText
          type="text"
          label={sAction.translate("LBL_TAG_NAME")}
          onChange={e => this.setState({ name: e.target.value })}
        />
        <Select
          options={tagTypes}
          onChange={e => this.setState({ type: e.target.value })}
          label={sAction.translate("LBL_TAG_TYPE")}
        />
        <AcmDate
          label={sAction.translate("LBL_TAG_DATE")}
          onChange={e => this.setState({ validUntil: e })}
        />
      </div>
    );

    return (
      <React.Fragment>
        <div className="reportWindowHeader">
          <div className="reportWindowHeaderText">{sAction.translate('LBL_TAG_ADD_NEW')}</div>
        </div>
        <div className="popupTagAdd">
          {message != undefined && (
            <div className="tagMessage">{sAction.translate(message)}</div>
          )}
          <div className="popupTagFilter">
            <InputText
              autoFocus={true}
              type="text"
              label={sAction.translate("LBL_TAG_FILTER")}
              onChange={e =>
                this.setFilter(way, e.target.value, record, module)
              }
            />
          </div>
          <div className="popupTagContent">{renderData}</div>

          {this.state.open == true && createNew}
          <div className="popupTagButtons">
            {this.state.open == false ? (
              <Button onClick={e => this.showCreateNew()}>
                <div className="actionPanelButtonIcon icon-addIcon" />
                {sAction.translate("LBL_TAG_CREATE_NEW")}
              </Button>
            ) : (
              <Button onClick={e => this.createNewTag(way, prefix)}>
                <div className="actionPanelButtonIcon icon-saveIcon" />
                {sAction.translate("LBL_TAG_SAVE_NEW")}
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
