import React, { PureComponent } from "react";

export default class SSFieldView extends PureComponent {
  render() {
    const { data } = this.props;

    const options = {};
    data.get("list").forEach((option, i) => {
      options[option.get("id")] = option.get("name");
    });

    const value = [];
    data.get("selected").forEach((ss, i) => {
      value.push(<li key={i}>{options[ss]}</li>);
    });

    return <div id="ss"><ul>{value}</ul></div>;
  }
}
