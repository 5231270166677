import React from "react";
import PureComponent from "../pure";

import NotificationItem from "./NotificationItem";

import  sAction  from "sAction";
import Loader from "../loader";

export default class NotificationCenter extends PureComponent {
  constructor() {
    super();
    this.list = React.createRef();
    this.doUpdate = true;
  }

  scroll = (e) => {
    const data = this.props.data;

    if (data.list.size >= 10) {
      const target = e.target;
      const scroll = target.scrollTop;
      var scrollHeight = target.scrollHeight;
      const height = target.clientHeight;
      if (scrollHeight > height) {
        scrollHeight -= height;
      }
      if (
        scroll >= scrollHeight - 100 &&
        this.doUpdate == true &&
        data.all == false
      ) {
        this.doUpdate = false;

        const paramData = {
          limit: data.limit,
          offset: data.offset
        };

        sAction.notificationMore(paramData);
      }
    }
  }

  returnEmpty = () => {
    const label = sAction.translate("LBL_NOTIFICATIONS_EMPTY");

    return (
        <div className="notificationCenterEmpty" key={"zero"}>
          {label}
        </div>
    );
  }

  render() {
    this.doUpdate = true;
    const data = this.props.data;
    var notListOpen = false;
    if (data != null) {
      notListOpen = data.open;
    }

    var renderData = null;
    var iconCustomClass = "";
    if (data !== null && data.open == true) {
      iconCustomClass = "open";
      var listData = [];
      if (data.load == true) {
        listData.push(
          <Loader key={"zero"} />
        );
      } else if (data.list.size !== 0) {
        data.list.forEach((record, key) => {
          listData.push(
            <NotificationItem key={key} index={key} data={record} />
          );
        });
        if (data.loadMore == true) {
          listData.push(
             <Loader key={"zero"} />
             );
        }
      } else if (data.list.size === 0) {
        listData.push(this.returnEmpty())
      }

      renderData = (
        <div className="notificationCenterContainer">
        {/*  <div className="notTriangle" /> */}
          <div className="notificationCenterContent" onScroll={this.scroll}>
          {/*  <div className="notificationCenterHeader">
              <div>
                {sAction.translate(
                  "LBL_NOTIFICATION_CENTER",
                  "acm_coripoNotify"
                )}
              </div>
            </div> */}
              {listData}
            </div>
          </div>
      );
    }

    return (
      <div
        className="notificationButtonContainer" 
      >
      <div className={"icon-notify notificationButton userHeaderButton "+iconCustomClass}
      onClick={e => sAction.openNotCenter(e, data)}
      >
      </div>
        {data.notice != null && data.notice != 0 && (
          <div className="notificationAlertContainer">{data.notice}</div>
        )}
        {renderData}
      </div>
    );
  }
}