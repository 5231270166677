import React from "react";
import PureComponent from "../pure";

import  sAction  from "sAction";
import MergeEditField from "./MergeEditField";

export default class NewRecordLine extends PureComponent {
  constructor() {
    super();
    this.state = {
      edit: false
    };
  }
  componentDidMount() {}
  componentDidUpdate() {}

  removeFromNewRecord(field) {
    const def = this.props.def;
    const way = this.props.way;
    sAction.mergeRemoveField(way, field);
    if(def.get("type") == "relate" && def.get("source") == "non-db" ){
        sAction.mergeRemoveField(way, def.get("id_name")); 
    }
  }
  getValue(){
    var value = this.props.value;
    const def = this.props.def;
    const type = def.get("type");

    if(type == "multienum"){
      const options = sAction.app_strings[def.get("options")];
      value = value.replace(/\^/g, '');
      const valuePole = value.split(",");

      value = [];
      valuePole.forEach(element => {

        const vname = options[element];
        console.log(vname);
        if(vname != ""){
          value.push(<div title={vname} className="mergeRecordMultiEnumLine" key={element}>{vname}</div>);
        }
      });
      
    }
    return value
  }
  render() {
    const value = this.props.value;
    const field = this.props.field;
    const def = this.props.def;
    const module = this.props.module;

    return (
      <div
        className={
          value == undefined || value == ""
            ? "mergeRecordFieldLine"
            : "mergeRecordFieldLine withValue"
        }
      >
        <div className="mergeRecordText">
          <div className="mergeRecordFieldHeader">
            {sAction.translate(def.get("vname"), module)}
          </div>
          <div className="mergeRecordFieldValue">
            {this.state.edit == true ? (
              <MergeEditField onBlur={() => this.setState({edit:false})} def={def} field={field} way={this.props.way} value={value} />
            ) : (
              <div onClick={() => this.setState({ edit: true })}>
                {value == undefined || value == "" ? "..." : this.getValue()}
              </div>
            )}
          </div>
        </div>
        <div className="mergeRecordButtons">
          {value != undefined && value != "" && (
            <div
              className="icon-deleteIcon mergeAddFieldButton"
              onClick={() => this.removeFromNewRecord(field)}
            />
          )}
        </div>
      </div>
    );
  }
}
