export default function getDateFormat(type) {
  let ret = "";

  if (Array.isArray(this.userPreferences.date_format)) {
    if (type === "moment") { //moment.js
      ret = this.userPreferences.date_format.join("").toUpperCase();
    } else if (type === "datepicker") {
      ret = this.userPreferences.date_format.join("").replace(/[dmY]/g, function (m) {
        return {
          'd': 'dd',
          'm': 'MM',
          'Y': 'yyyy'
        }[m];
      });
    }
  } else {
    if (type === "moment") {
      ret = "D.M.Y";
    } else if (type === "datepicker") {
      ret = "dd.MM.yyyy";
    }
  }

  return ret;
}