export default function editDetailEditTab(e,vname,module,way){
    e.stopPropagation()
    this.popup("customModuleTranslate",{
        vname:vname,
        module:module,
        header: this.translate("LBL_EDIT_TAB_NAME"),
        way:way,
        callback: (params) => {
            this.setCustomTranslate(params,() => {
                this.dsClear();
                this.dsAdd("set",way+"/name","");
                this.dsAdd("set",way+"/name",vname)
                this.dsProcess();
            })
        }
    })
}