export default function logOut() {
    // ulozeni dashboardu - predevsim kvuli razeni sloupcu
    if (this.dataGet("conf/view") === "home") {
        this.homeLayoutSave();
    }

    this.rest.clearCookie('sID');
    this.rest.clearCookie('mirrorSID');

    this.routeLogin()
}