import React from 'react';
import ListviewAddNewProspectList from "../../components/popup/listview/ListviewAddNewProspectList";

export default function addToProspectLists(params) {
  const selected = params.selected || this.dataGet(params.prefix + "/selected");
  const filterData_lv = selected !== "all" ? null : this.getListviewFilter(params.prefix);

  this.popupHide();

  const sendRequest = (sendRestData, action) => {
    this.load();

    this.rest.post(action, sendRestData, (returnRestData) => {
      this.unLoad();
      if (returnRestData) {
        this.alert("OK. "+this.translate("LBL_ADDED_RECORDS")+": "+returnRestData.added_prospects);
        this.dataSet(params.prefix + "/selected", []);
        this.dataSet(params.prefix + "/selectPage", false);
      } else {
        // nastala chyba
      }
    });
  }

  // vytvoreni noveho marketingoveho seznamu
  const saveCallback = (name, description) => {

    const sendRestData = {
      uids: selected,
      uids_filter: filterData_lv,
      module: params.module,
      name,
      description,
    };

    sendRequest(sendRestData, "addNewProspectList");
  };

  const data = {
    header: this.translate("LBL_ADD_TO_PROSPECT_LIST_BUTTON_LABEL"),
    //  color:"red",
    buttons: [
      {
        // Vytvořit
        label: this.translate("LBL_CREATE","ProspectLists"),
        callback: () => {
          this.popupHide();
          this.popup(<ListviewAddNewProspectList saveCallback={saveCallback} />);
          //window.open("#detail/ProspectLists", "_blank");
        },
      },
      {
        // Vybrat existující
        label: this.translate("LBL_CHOOSE_EXISTING","ProspectLists"),
        callback: () => {
          this.popupHide();
          const relateData = {
            module: "ProspectLists",
          };
          this.openRelatePopup(relateData, (returnData) => {
            // filter pro vybrane marketingove seznamy v popupu
            const records = returnData.id;
            const filterData_p = records !== "all" ? null : this.getListviewFilter("conf/popup/data/listView");

            const sendRestData = {
              uids: selected,
              uids_filter: filterData_lv,
              module: params.module,
              prospect_list_id: records,
              prospect_list_filter: filterData_p,
            };

            this.popupHide();

            sendRequest(sendRestData, "addToProspectLists");
          });
        },
      },
    ],
  };

  // "Vybranou položku nebo položky můžete přiřadit k existujícímu seznamu nebo vytvořit nový."
  this.popup(this.translate("LBL_CREATE_OR_CHOOSE_EXISTING","ProspectLists"), data);
}


