export default function editFieldCreateField(way,params){
    this.load()
    this.rest.post("createNewField", params, ret => {
        if(!ret.state){
            this.unLoad()
            console.log(ret)
            this.alert(this.translate(ret.message))
        }else{
            const vardefs = ret.vardefs
            if(params["new"] !== false){
            this.moduleTranslate[params["module"]][vardefs["vname"]] = params["name"]
            }
            this.dsClear();
            if(params["new"] !== false){
                this.dsAdd("add",way+"/hiddenFields",vardefs["name"]);
                this.dsAdd("set",way+"/fields/"+vardefs["name"],{
                    value:null,
                    name:vardefs["name"]
                    
                },"Field");
            }
            this.dsAdd("set",way+"/fields/"+vardefs["name"]+"/def",vardefs);
            this.dsProcess();
            this.popupHide();
            this.saveEditedDetail(way,false)
        }
    })
}