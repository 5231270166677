import React, { PureComponent } from "react";
import  sAction  from "sAction";
import Button from "../formElements/Button";

export class EmailComposeHeaderPopup extends PureComponent {
  render() {
    return (
      <React.Fragment>
        {/*Napsat e-mail*/}

        <div className="viewActionPanel borderBottom" style={{paddingLeft: 30}}>
          <div className="viewActionPanelButtons">
            <Button
              className="hoverGreen"
              onClick={() => this.props.onSubmit("sent")}
            >
              {/*Odeslat*/}
              <div className="actionPanelButtonIcon icon-emails" />{" "}
              {sAction.translate("LBL_SEND_BUTTON_LABEL", "Emails")}
            </Button>
            <div className="viewPanelButtonsDelimiter" />
            <Button
              className="hoverBlue"
              onClick={() => this.props.onSubmit("draft")}
            >
              {/*Uložit návrh*/}
              <div className="actionPanelButtonIcon icon-saveIcon" />{" "}
              {sAction.translate("LBL_SAVE_AS_DRAFT_BUTTON_LABEL", "Emails")}
            </Button>
            <div className="viewPanelButtonsDelimiter" />
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
              <div className="actionPanelButtonIcon icon-deleteIcon" />{" "}
              {sAction.translate("LBL_CANCEL", "Home")} {/* Zrušit */}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
