export default function hashChangeEvent(){
    window.addEventListener("hashchange", (e) => {
      // ulozeni dashboardu - predevsim razeni sloupcu
      if (this.dataGet("conf/view") === "home") {
        this.homeLayoutSave();
      }

      if (this.doRoute == true) {
          if (this.needSave(e.oldURL) == false) {
            var view = this.getViewName();
            this.route(false,null,view == "login" ? "home" : null);
          } else {
            this.doRoute = false;
            window.location.hash = "#" + e.oldURL.split("#")[1];
            this.confrim(this.translate("LBL_RECORD_IS_NOT_SAVED"), () => {
              //tento callback se vola pri kliknuti na pokracovat kdyz neni zaznam ulozen
              if (localStorage.getItem('changes-'+this.dataGet("view/module")+'-'+this.dataGet('conf/user/id')))
              {
                this.removeChangesFromLS(this.dataGet("view/module"), this.dataGet('conf/user/id'))
              }
              //je potreba procistit store od dat z popupu
              this.dsAdd("set","conf/popup/show",false);
              this.dsAdd("set","conf/popup/content",null);
              this.dsAdd("set","conf/popup/data",{});
              this.dsProcess();
              this.dataSet("view/changes", { fields: [], files: [] });
              this.setStorage('stopChanges',1)
              const imagesToDelete = this.dataGet("view/imageActions/deleteOnLeave");
              if (imagesToDelete) {
                this.rest.delete("deleteImages/", imagesToDelete.toJS(), () => {})
              }
              window.location.href = e.newURL;
            });
            setTimeout(() => {
              this.doRoute = true;
            }, 200);
          }
        }
      });
}
