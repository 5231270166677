import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";

import  sAction  from "sAction";

export default class ReportWindowColumn extends PureComponent {

    setWidth(value){
      const prefix = this.props.prefix
      const index = this.props.index
      sAction.dataSet(prefix+ "/columns/" + index + "/def/width", value+"%");
    }

    render() {
    const data = this.props.data
    const index = this.props.index
    const module = this.props.module
    const vname = this.props.vname
    const setWidth = this.props.setWidth;
    let show = false
    if(data.def.get("show") === true || data.def.get("show") === undefined){
        show = true
    }
    return (
        <div key={index+data.name}
         className={show  ? "reportWindowColumn" : "reportWindowColumn hidden"}
         data-name={data.name}
         data-way={index}>
        <span className="reportWindowColumnSpan">{vname}</span>
        {setWidth && (
          <InputText type={"number"} value={parseInt(data.def.get("width").replace("%",""))} onChange={(e) => this.setWidth(e.target.value.replace(/[^0-9]*/g,''))} />
        )}
      </div>
    );
  }
}
