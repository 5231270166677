export default function detailWorkflow(params){
    this.load();
    this.rest.post("workflowDetail",params,ret => {
        if(ret.state == true){
            if(ret.after != undefined){
                ret.after.forEach(a => {
                    const action = a.action;
                    if(this[action] != undefined){
                        this[action](ret.returnData);
                    }else{
                        console.log("Akce \""+action+"\" není definována");
                    }
                })
            }else{
                this.route();
            }
        }else{
            this.unLoad();
            this.alert(ret.message);
        }
    })
}