
export default function dsCheckBlock(block){
    var state = true;
    if(typeof block.type != "string"){
        console.error("Block type is must be String")
        state = false
    }
    var data = block.data
    data.forEach(element => {
        if(typeof element.way != "string"){
            console.error("Block type must be String")
            console.error(element.way);
            state = false
        }
        if((element.value === undefined) && block.type != "DATADELETE" ){
            console.error("Value is not defined");
            console.error(element.value);
            state = false
        }
        if((typeof element.index != "number" && typeof element.index != "string") && block.type == "DATADELETE" ){
            console.error("Index is not defined");
            console.error(element.index);
            state = false
        }
        if(element.like != null && typeof element.like != "string"){
            console.error("Like is must be String")
            console.error(element.like);
            state = false
        }
        if(element.inList != null && typeof element.inList != "string"){
            console.error("inList is must be String")
            console.error(element.inList);
            state = false
        }
    });
    return state;
}