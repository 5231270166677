import React from "react";

import InputField from "../inputFieldClass";
import ReactQuill from "react-quill";

import SelectParams from "./SelectParams";

export default class RichText extends InputField {
  constructor() {
    super();
    this.quillRef = null;
    this.reactQuillRef = null;
  }

  // vlozi promenne do quill editoru pres 2 selecty a 1 button (<SelectParams />)
  // (Firmy - Ulice -> $account_billing_address_street)
  addParam = (param) => {
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    this.quillRef.insertText(position, param + " ");

    return true;
  };

    //quill editor focus
  componentDidMount() {
    this.getQuillEditorRefs();

    const newRecord = this.props.newRecord;
    if (!newRecord) {
      this.reactQuillRef.focus();
    }
  }

  componentDidUpdate() {
    this.getQuillEditorRefs();
  }

  //ziska refy na quill editor
  getQuillEditorRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    if (this.quillRef != null) return;

    const input = this.reactQuillRef.getEditor();
    if (input != null) this.quillRef = input;
  };

  //ziska html kod z quill editoru
  getValue = () => {
    if (!this.reactQuillRef) {
      return;
    }
    return this.reactQuillRef.state.value;
  }

  render() {
    const { data, newRecord, readonly } = this.props;

    var value = data.value;
    if (newRecord && (value == "" || value == undefined)) {
      value = "";
    }

    // console.log("RichTextRender()");

    return (
      <div className="RichText">
          {/* vlozi 2 selecty a 1 button
            (Firmy - Ulice -> $account_billing_address_street) */}
          {!readonly ? <SelectParams onAdd={this.addParam} /> : <br />}

          <ReactQuill
            theme="snow"
            ref={i => {
              this.reactQuillRef = i;
            }}
            // onBlur={() => this.save()}
            // onBlur nefunguje, kdyz uzivatel rovnou klikne na Ulozit... proto onKeyUp:
            // onKeyUp={() => this.save()} // taky nefunguje, kdyz se napr. vlozi obrazek
            onChange={() => {if (this.reactQuillRef) this.save()}} // bez podminky if (this.reactQuillRef) se zacykli, je to pomale
            onKeyDown={e => this.onKeyDown(e, true)}
            //className={inputClass}
            modules={RichText.modules}
            formats={RichText.formats}
            bounds={".app"}
            readOnly={readonly || false}
            value={value}
            //placeholder="..."
          />
      </div>
    );
  }
}

/*
 * https://quilljs.com/docs/modules/
 */
RichText.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"],

    ["clean"]
  ],
  clipboard: {
    // toggle to addParam extra line breaks when pasting HTML:
    matchVisual: false
  }
};
/*
 * https://quilljs.com/docs/formats/
 */
RichText.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "background",
  "align"
];
