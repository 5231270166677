export default function addToCampaignsAction(params) {
  const relateData = {
    module: "acm_CampaignsAction",
  };
  this.openRelatePopup(relateData, (returnData) => {

    // filter pro vybrane zaznamy v listview
    const selected = this.dataGet(params.prefix + "/selected");
    const filterData_lv = selected !== "all" ? null : this.getListviewFilter(params.prefix);

    // filter pro vybrane kampane/akce v popupu
    const records = returnData.id;
    const filterData_p = records !== "all" ? null : this.getListviewFilter("conf/popup/data/listView");
    
    var sendRestData = {
      contacts_uids: selected,
      contacts_filter: filterData_lv,
      campaigns_actions_uids: records,
      campaigns_actions_filter: filterData_p,
    };
    
    this.popupHide();
    this.load();
    this.rest.post("addToCampaignsAction", sendRestData, (returnRestData) => {
      this.unLoad();
      if (returnRestData) {
        this.alert("OK. Přidáno nových kontaktů: "+returnRestData.new_contacts_count);
      } else {
        // nastala chyba
      }
    });
  });
}


