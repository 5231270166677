import React from "react";
import moment from "moment";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import Select from "../../formElements/Select";

export default class ActivitiesTimeline extends PureComponent {
  constructor() {
    super();
    this.timeline = React.createRef();
  }
  toogleActivities(module) {
    const prefix = this.props.prefix;
    const data = this.props.data;
    const moduleData = data.activities.get(module);

    if (moduleData == undefined) {
      sAction.dataSet(prefix + "/activities/" + module, { show: false });
    } else {
      sAction.dataSet(
        prefix + "/activities/" + module + "/show",
        !moduleData.get("show")
      );
    }
    sAction.reloadActivitiesPanel(prefix);
  }
  statusChange(value) {
    const prefix = this.props.prefix;
    sAction.dataSet(prefix + "/status", value);
    sAction.reloadActivitiesPanel(prefix);
  }

  componentDidMount() {
    const popupContainer = this.timeline.current.closest(".popupContent");
    if (popupContainer) {
      popupContainer.style.width = "90%";
      popupContainer.style.height = "80%";
    }
    this.timeline.current.addEventListener("scroll", e =>
      this.handleScroll(e)
    );
  }

  handleScroll(e) {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      const prefix = this.props.prefix;
      const data = this.props.data;
      sAction.activitiesTimelineExtend(prefix,data);
    }
  }

  handleSwitchOrder() {
    const prefix = this.props.prefix;
    const order = this.props.data.get("order");
    if (order == "desc") {
      sAction.dataSet(prefix + "/order", "asc");
    } else {
      sAction.dataSet(prefix + "/order", "desc");
    }
    sAction.reloadActivitiesPanel(prefix);
  }

  showMore(e, index, isShow) {
    e.preventDefault();
    sAction.activitiesTimelineShowMore(index, isShow);
  }

  render() {
    const prefix = this.props.prefix;
    const data = this.props.data;

    const records = data.get("records");
    const activities = data.get("activities");

    const activityModule = ["Calls", "Meetings", "Tasks", "Emails"];
    const activityButtons = [];
    activityModule.forEach(module => {
      activityButtons.push(
        <button
          key={module}
          onClick={() => this.toogleActivities(module)}
          className={
            "activity" +
            module +
            " " +
            (activities.getIn([module, "show"]) === false ? " filter" : "")
          }
        >
          <span className={"icon-" + module} />
        </button>
      );
    });

    const filterButtons = (
      <div className="filterButtons">
        {activityButtons}
        <button 
          className="activityPrint"
          onClick={() => sAction.printTimelineConfirm(prefix)}
        >
          <span className={"icon-Print"} />
        </button>
        <button
          onClick={() => sAction.showActivitiesTimelinePopup(prefix)}
          className="activityResize"
        >
          <span className={"icon-Resize"} />
        </button>
      </div>
    );

    const statusItems = Object.entries(
      sAction.app_strings["meeting_status_dom"]
    ).map(([key, value]) => ({ label: value, value: key }));
    const options = [
      { label: sAction.translate("LBL_ALL"), value: "" },
      ...statusItems
    ];
    const statusFilter = (
      <div className="filterStatusEnum">
        <Select
          options={options}
          onChange={e => this.statusChange(e.target.value)}
        />
      </div>
    );

    const activitiesRecords = records
      ? records.map((record, index) => {
          const date = moment(record.get("date_start")).format(
            "DD. MM. YYYY HH:mm"
          );
          const status =
            sAction.app_strings["meeting_status_dom"][record.get("status")];
          const module = record.get("module");
          const modulesDiffs = data.get("format");
          const diffs = modulesDiffs.get(module);

          let description = record.get("description");
          if (description) {
            if (description.length > 400) {
              const shortDescription = description.substring(0, 400) + "...";
              if (record.get("showAll")) {
                description = (
                  <div className="content">
                    {description}
                    <a href="#" onClick={e => this.showMore(e, index, false)}>
                      {sAction.translate("LBL_SHOW_LESS")}
                    </a>
                  </div>
                );
              } else {
                description = (
                  <div className="content">
                    {shortDescription}
                    <a href="#" onClick={e => this.showMore(e, index, true)}>
                      {sAction.translate("LBL_SHOW_MORE")}
                    </a>
                  </div>
                );
              }
            } else {
              description = <div className="content">{description}</div>;
            }
          }
          return (
            <div
              className={"activityItem activity" + diffs.get("activityClass")}
              key={index}
            >
              <span className="icon">
                <span className={"icon-" + diffs.get("iconClass")} />
              </span>
              <span className="activityContent">
                <span className="arrow" />
                <a
                  className="header"
                  href={"#detail/" + diffs.get("link") + "/" + record.get("id")}
                  target="_blank"
                >
                  {record.get("name")}
                </a>
                <div className="subHeader" />
                <div className="dateAndStatus">
                  <div className="date">{date}</div>
                  <div className="status">{status}</div>
                </div>
                {description}
              </span>
              <span className="icon right">
                <span className={"icon-" + diffs.get("iconClass")} />
              </span>
            </div>
          );
        })
      : [];

    const timeline = (
      <div className={"activitiesListContainer"}>
        <div className="activitiesList">{activitiesRecords}</div>
      </div>
    );

    const lineEdge = (
      <div className="switchOrder">
        <span className="icon" onClick={() => this.handleSwitchOrder()}>
          <span className="icon-SwitchOrder" />
        </span>
      </div>
    );

    return (
      <div className="activitiesTimeline" ref={this.timeline}>
        {filterButtons}
        {statusFilter}
        {lineEdge}
        {timeline}
      </div>
    );
  }
}
