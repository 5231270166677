

export default function loadTranslate(complete){


  var module = this.getModuleName();
  var self = this;
  this.rest.get("trns/"+module,function(data){

    self.moduleTranslate = data;
    self.rest.get("trns",function(data){

      self.translate = data;
      complete();
    });
  });


}
