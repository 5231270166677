export default function fillDetailPredefinedFields(prefix,id, set_assigned_user = true) {
    this.dsClear();

  if (
    this.detailPredefinedFields != undefined &&
    this.detailPredefinedFields != null
  ) {
    const detail = this.dataGet(prefix);
    var fields = {};
    var relationShips = null;
    
    this.detailPredefinedFields.forEach(field => {
      if (field.type == "field") {
        this.dsAdd("set",prefix + "/fields/" + field.name + "/value",field.value);
        fields[field.name] = field.value;
      } else if (field.type == "parent") {

        this.dsAdd("set", prefix + "/fields/" + field.name + "/value", field.parentName);
        this.dsAdd("set",prefix + "/fields/" + field.name + "/def/id_value",field.parentId);
        this.dsAdd("set",prefix + "/fields/" + field.name + "/def/parent_type_value",field.parentModule);
        fields["parent_id"] = field.parentId;
        fields["parent_type"] = field.parentModule;

      } else if (field.type == "relate") {
        var relPom = field.rel.toJS();
        detail.fields.entrySeq().forEach(e => {
            const key = e[0];
            const value = e[1]; 
            console.log(relPom,value.def.toJS());
            if(value.def.get("type") == "relate" && value.def.get("id_name") == relPom.id_name){
              this.dsAdd("set", prefix + "/fields/" + value.name + "/value", field.value);
              this.dsAdd("set",prefix + "/fields/" + value.name + "/def/id_value",field.id);
              fields[value.def.get("id_name")] = field.id;
            }else if(value.def.get("id_name") == "billing_account_id" && relPom.module == "Accounts"){
              this.dsAdd("set", prefix + "/fields/" + value.name + "/value", field.value);
              this.dsAdd("set",prefix + "/fields/" + value.name + "/def/id_value",field.id);
              fields[value.def.get("id_name")] = field.id;
            }
        })
        relPom.relname = relPom.relName;
        relPom.id = field.id;
        this.dsAdd("set", prefix+"/changes/relationships", relPom);

      } else if (field.type === "relate_simple") {
        this.dsAdd("set", prefix + "/fields/" + field.fieldName + "/value", field.value.name);
        this.dsAdd("set", prefix + "/fields/" + field.fieldName + "/def/id_value", field.value.id);
        fields[field.name] = field.value.id;
      } else if (field.type === "customData") {
        let custom = {};
        field.value.forEachObject((value, key) => {
          if (key === 'invitees') {
            //deleting search invitees as only related invitees as predefined make sense
            value.searchInv = {Users: [], Contacts: [], Leads: []};
          }
          custom[key] = value;
        })
        this.dsAdd('set', `${prefix}/customData`, custom);
      }
    });
    
    this.dsAdd("set", prefix + "/changes/fields", fields);
    this.detailPredefinedFields = null;
  }

  // _____ set assgined_user
  if (!id && set_assigned_user) {
    const user = this.dataGet("conf/user");
    this.dsAdd("set", prefix + "/fields/assigned_user_name/value", user.name);
    this.dsAdd("set", prefix + "/fields/assigned_user_name/def/id_value", user.id);
    this.dsAdd("set", prefix + "/changes/fields/assigned_user_id", user.id);
  }
  // ______set assigend_user END

  this.dsProcess();
}
