import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import RolesTable from "../../popup/roles/RolesTable";
import TabContainer from "../../formElements/TabContainer";
import CheckBox from "../../formElements/CheckBox";

export default class PermissionAccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
        };
    }

    updateData = (name, value) => {
        sAction.dsClear();
        sAction.dsAdd('set', this.props.way + '/customData/' + name, value);
        sAction.dsAdd('set', this.props.way + '/changes/customData/' + name, value);
        sAction.dsAdd('set', this.props.way + '/changes/forceChange', true);
        sAction.dsProcess();
    };

    changeActiveTab(newTab) {
        if (newTab === this.state.activeTab) {
            return;
        }
        this.setState({activeTab: newTab});
    }

    render() {
        const data = this.props.data;
        const allowAll = data.get('allowAll') == 1 ? true : false;

        if (sAction.userPreferences.is_admin !== '1') {
            return null;
        }
        const roles = sAction.translate("LBL_ROLES", "Home");
        const users = sAction.translate("LBL_USERS", "Home");
        const tabs = [roles, users]; //"Role", "Uživatelé"
        let activeTab = this.state.activeTab;
        let selectedUsers = this.props.data.get('users');
        let selectedRoles = this.props.data.get('roles');

        var permissionTable = (
            <div className='permissionAccess' style={{maxWidth: '500px'}}>
                <div className="acmTabContainer">
                    <TabContainer
                        onChange={(e, value) => this.changeActiveTab(value)}
                        value={activeTab}
                        tabs={tabs}
                    />
                </div>
                <div className={activeTab !== 0 ? "homeTableHide hidden" : "homeTableHide"}>
                    <RolesTable
                        module="ACLRoles"
                        selectedData={selectedRoles}
                        onChange={data => this.updateData("roles", data)}
                    />
                </div>
                <div className={activeTab !== 1 ? "homeTableHide hidden" : "homeTableHide"}>
                    <RolesTable
                        keepSelf={true}
                        module="Users"
                        selectedData={selectedUsers}
                        onChange={data => this.updateData("users", data)}
                    />
                </div>
            </div>
        );

        return (
            <div className='detailCard detailViewTabContent detailQuoteProdComponent'>
                <div className='subpanelsContainer'
                     data-way={this.props.way + '/customData/customLines'}>
                    <div className="qGroupHeaderContainer header">
                        <div className="title">
                            <span className={"detailViewHeaderIcon icon-"}/>
                            <span className="detailViewName">
                                {sAction.translate('LBL_ACCESS_SETTINGS', data.module)}
                            </span>
                        </div>
                    </div>
                    <div className="detailView">
                        <div className='permissionAccess checkbox'>
                            <span>
                                {sAction.translate('LBL_ALLOW_ALL', data.module)}
                            </span>
                            <span>
                                <CheckBox
                                    defaultValue={allowAll ? 1 : 0}
                                    onChange={e => this.updateData('allowAll', !!e)}
                                />
                            </span>
                        </div>
                        {!allowAll && permissionTable}
                    </div>
                </div>
            </div>
        );
    }
}
