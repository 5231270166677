import React from "react";

export default class MaterialDefault extends React.PureComponent {
    constructor(props){
        super(props)

    }
    onChange(e,value){
        if(this.props.onChange != undefined){
            this.props.onChange(e,value);
        }
    }
    onBlur(e){
        if(this.props.onBlur != undefined){
            this.props.onBlur(e);
        }
    }
    onKeyUp(e){
        if(this.props.onKeyUp != undefined){
            this.props.onKeyUp(e);
        }
    }
    onKeyDown(e){
        if(this.props.onKeyDown != undefined){
            this.props.onKeyDown(e);
        }
    }
    onClick(e){
        if(this.props.onClick != undefined){
            this.props.onClick(e);
        }
    }
    createAttr(className){
        if(this.state != null && this.state.focus == true){
            className = "acmInput focus";
        }
        if(this.props.error == true){
            className += " error";
        }
        var attr = {className: className}

        this.props.forEachObject((value,key) => {
            if(key.startsWith("on") && typeof value == "function"){
                if(typeof this[key] == "function"){
                attr[key] = (e,value) => this[key](e,value)
                }
            }else if(key == "myRef"){
                attr["inputRef"] = value
            }
            else if(key != "className" && key != "containerClassName"){
                attr[key] = value
            }else if(key == "className"){
                attr["className"] += " "+value
            }
        })
        return attr
    }
}