import detailDefault from "../../detailDefault";

export default class detailacm_Gifts extends detailDefault {
  load(sAction, data) {
  }

  update(sAction, data) {
    switch (data.field) {
      case "acm_gifts_contactscontacts_ida":
        const params = {
          module : "Contacts",
          id : data.value.id,
          action: "getAccountInfo"
        }
        sAction.rest.post("customAction", params, function(resp) {
          if (resp.errCode == "OK") {
            const fieldData = {
              "way": data.prefix+"/fields/accounts_acm_gifts_1_name",
              "name": "accounts_acm_gifts_1accounts_ida",
              "fieldName": "accounts_acm_gifts_1_name",
              "type": "relate",
              "value": {
                "id": resp.id,
                "name": resp.name
              }
            }
            sAction.saveField(fieldData, true);
          }
        });
    }
  }
}