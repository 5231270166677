import React from "react";
import PureComponent from "../pure";
import InputText from "../formElements/InputText";
import RecentRecordsManager from "../records/recentRecords";

import Breadcrumb from "./breadcrumb";
import NotificationCenter from "../notification/NotificationCenter";
import sAction from "sAction";

class Header extends PureComponent {
  constructor() {
    super();
    this.searchInput = React.createRef();
  }
  handleSearch() {
    const val = this.searchInput.current.value;
    sAction.href("#globalSearch/" + encodeURI(val));
    //sAction.route();
  }

  searchKeyUp(ev) {
    if (ev.keyCode == 13) {
      this.handleSearch();
    }
  }
  alertMessageClick() {
    const alertMessage = this.props.alertMessage;
    if (sAction[alertMessage.get("buttonAction")] != undefined) {
      sAction[alertMessage.get("buttonAction")]();
    } else {
      console.log(
        "Akce " + alertMessage.get("buttonAction") + " není definována"
      );
    }
  }

  render() {
    const user = this.props.user;
    const recordName = this.props.recordName;
    const breadcrumbData = sAction.breadcrumb(recordName);
    const alertMessage = this.props.alertMessage;
    var menuButton = (
      <div className="burgerMenu" onClick={() => sAction.menuOpen()} />
    );
    menuButton = null;
    const isSuperUser = user.is_superUser !== null && !!user.is_superUser;

    const notificationData = this.props.not;

    let headerContentClass = 'headerContent';
    if (sAction.develMode === true) {
      headerContentClass += window.location.hostname !== 'localhost' ? ' testEnviroment' : ' testEnviromentLocal';
      var develModeString = window.location.hostname !== 'localhost' ? 'Testovací prostředí' : 'Localhost';
    }

    let mirrorURL = null;
    if (sAction.rest.getCookie('mirrorSID')) {
      /**
       * @param {string} value
       * @return {string}
      */
      const e41 = (value) => value
        .split('-')
        .map((item) => parseInt(item, 16))
        .map((item, index) => {
          const result = [];
          while (item > 0) {
            result.push('kQAdzJZqUVcnoLSrHBvNsCtDemKTfRiabuXxhpMFG'[item % 41]);
            item = Math.floor(item / 41);
          }
          return result.reverse().join('').padStart([6, 3, 3, 3, 9][index], 'k');
        }).join('');
      const session = e41(sAction.rest.getCookie('mirrorSID'));
      const mirrorSession = e41(sAction.rest.getCookie('sID'));

      mirrorURL = `${sAction.param.mirror.url}/#custom?action=mirrorLogin&sid=${session}&mirrorSID=${mirrorSession}`;
    }

    return (
      <header>
        {alertMessage.get("show") == true && (
          <div className="headerAlertPanel">
            {alertMessage.get("message")}
            {alertMessage.get("buttonAction") != undefined && (
              <a onClick={() => this.alertMessageClick()}>
                {alertMessage.get("buttonLabel")}
              </a>
            )}
          </div>
        )}

        <div className={headerContentClass}>
          <div className="headerLeftContainer">
            <div className="headerLogoContainer">
              <a href="#home" className="headerLogoContent">
                <img className="headerLogo" src="img/coripo-logo.png" />
              </a>

              <a className="logo" href="#home">
                {sAction.param.companyName}
              </a>
            </div>
            <Breadcrumb data={breadcrumbData} />
          </div>
          {sAction.develMode === true &&
            <div className="develModePanel">
              {develModeString}
            </div>
          }
          {menuButton}
          <div className="userMenu">
            {mirrorURL && (
              <div className='userHeaderButton mirror' onClick={() => location.href = mirrorURL}>
                <span className='icon-newTab'></span>
                <div>{sAction.param.mirror.name}</div>
              </div>
            )}
            <div className="headerGlobarSearch">
              <InputText
                containerClassName="globalSearchInput"
                type="text"
                placeholder={sAction.translate("LBL_GLOBAL_SEARCH_PLACEHOLDER")}
                myRef={this.searchInput}
                onKeyUp={(ev) => this.searchKeyUp(ev)}
              />
              <div
                className="icon-search userHeaderButton"
                onClick={() => this.handleSearch()}
              />
            </div>

            <NotificationCenter data={notificationData} />
            <RecentRecordsManager />
            <div
              className="icon-filter headerFiltering userHeaderButton"
              onClick={() => sAction.openReportWindow()}
            />

            <div className="userMenuContainer">
              <a
                href={"#detail/Users/" + user.id}
                className="userMenuContainerHeader"
              >
                <div className="icon-avatar profileIcon" />
                <div className="userName">{user.name}</div>
              </a>
              <div className="userMenuContent">
                {(user.is_admin === "1" || isSuperUser) && (
                  <a href={"#admin"}>
                    <div className="userMenuContentLine">
                      <div className="icon-admin profileIcon" /> Admin
                    </div>
                  </a>
                )}
                {sAction.getStorage("debug") && (
                  <React.Fragment>
                    <a href={"#admin/custom/repair_quick"}>
                      <div className="userMenuContentLine">
                        <div className="icon-admin profileIcon" />
                        {sAction.translate(
                          "LBL_QUICK_REPAIR_AND_REBUILD",
                          "Administration"
                        )}
                      </div>
                    </a>
                    <a href={"#admin/custom/studio"}>
                      <div className="userMenuContentLine">
                        <div className="icon-admin profileIcon" />
                        Studio
                      </div>
                    </a>
                  </React.Fragment>
                )}
                <a href={"#list/acm_wiki"}>
                  <div className="userMenuContentLine">
                    <div className="icon-support profileIcon" />
                    {sAction.translate("LBL_PODPORA")}
                  </div>
                </a>
                <div
                  className="userMenuContentLine"
                  onClick={() => sAction.logOut()}
                >
                  <div className="icon-logout profileIcon" />
                  {sAction.translate('LBL_LOGOUT')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
