import Datetime from './../Datetime/Datetime.js'
export default class Datetimecombo extends Datetime {


  constructor(){
    super();

  }
  

}
