export default function confCustom(state, action) {
  var allState = state
  state = state.get("conf");
  switch (action.type) {
    case "EDIT":
      var editState = state.set(action.key, action.value);

      return allState.set("conf",editState);

    case "RELOADLIST":
      var newState = state.set("load", false);

      return allState.set("conf",newState);
    case "POPUP":
      if (action.show != undefined) {
        state = state.setIn(["popup", "show"], action.show);
      }
      if (action.content != undefined) {
        state = state.setIn(["popup", "content"], action.content);
      }

      return allState.set("conf",state);
    default:
    return allState;
  }
}
