import React from "react";
export default function Enum(props) {
  var lineRender = null;
  const bcColor = props.bcColor;

  try {
    var vname = props.option[props.value];
    if (vname === "" || vname === undefined) {
      vname = props.value;
    }
    lineRender = vname;
  } catch (e) {
    lineRender = props.value;
  }

  var extraStyle = {};
  var extraStyleDiv = {};
  var spanClass = "";
  if (bcColor) {
    extraStyle = { backgroundColor: bcColor, color: "#FFF" };
    extraStyleDiv = { color: "#FFF" };
    spanClass = "listBcColor";
  }

  lineRender = (
      <span className={spanClass} style={extraStyle} title={vname}>
          {lineRender}
        </span>
  );
  if (props.colors && props.colors[props.value]) {
    lineRender = (
        <span className='colorList' style={{backgroundColor: props.colors[props.value]}}> </span>
    );
  }

  return (
    <div
      className={"newListViewLine " + props.extraClass}
      style={extraStyleDiv}
      onClick={props.onClick}
    >
      {lineRender}
    </div>
  );
}
