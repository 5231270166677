
import detailDefault from "../../detailDefault";
export default class detailacm_CampaignsAction extends detailDefault{
    load(sAction,data){
        const val = sAction.dataGet(data.prefix+"fields/type/value");
        this.updateFieldsVisibility(sAction, data,val === 'Training');
    }
    update(sAction,data){
        //pokud je vybran v ciselniku Typ "Skoleni"
        if(data.field === "type"){
            this.updateFieldsVisibility(sAction, data,data.value === 'Training');
        }
    }

    //fce skryva/odkryva fieldy podle stavu checkboxu
    updateFieldsVisibility(sAction, data, visible) {
        sAction.dataSet(data.prefix+"/fields/date_training/def/visible", visible);
    }
}
