export default function sweetSpotAction(params){
    var item = null;
    
    if(params.item != null){
        item = params.item;
    }
    else if(params.index != null){
        item = this.dataGet("conf/popup/data/data/"+params.index);
        //item = this.dataGet("conf/sweetSpot/data/"+params.index);
    }

    switch(item.type){
        case "detail":
            window.location.hash = "#detail/"+item.module+"/"+item.record;
            break;
        case "list":
            window.location.hash = "#list/"+item.module;
            break;
        case "link":
            window.location.hash = item.link;
            break;
        case "repair":
                var self = this;        
                self.load();
                
                self.rest.get("repair", data => {
                    //TODO TTT kontrola odpovedi (ted tam chodi nejaky mess, ale oprava se provede...)
                    self.load();
                    location.reload();
                });
              
            break;
    }

}