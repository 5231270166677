export default function reportWindowCreate() {
  var self = this;
  this.dataSet("reportWindow/results",{});
  const module = this.dataGet("reportWindow/module");
  var listViewData = this.reportWindowCreateBasic(module);
  if (listViewData != false) {


    this.rest.post("list/" + module, listViewData,data => {
      var content = self.listContent(data);
      var akce = {
        type: "PAGELOADLIST",
        page: module,
        content: content,
        listViewPrefix: "reportWindow/results",
        function: self,
      };
      self.dsClear();
      self.dsAdd("set", "reportWindow/filterAlert/alert",false);
      self.dsAddCustom(akce);
      self.dsAdd("set", "reportWindow/actContent", "result");
      self.dsAdd("set", "reportWindow/results/type", "reportWindow");
      self.dsProcess();
    });
  } else {
    this.dataSet("reportWindow/filterAlert",{alert:true,message:"Špatně nastavený filtr - nelze pokračovat"})
  }
}
