import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";

import sAction from "sAction";

export default class EditFieldsEnum extends PureComponent {
  constructor(props) {
    super(props);

    this.enumKeyInput = React.createRef();
    this.keyInput = React.createRef();
    this.valueInput = React.createRef();
    this.addButton = React.createRef();

    this.state = {
      reg: /^[a-zA-Z_0-9]+$/,
      enumKeyError: false,
      keyError: false,
    };
  }
  valueKeyUp(e) {
    const keyCode = e.keyCode;
    if (e.keyCode == 13) {
      this.addEnumLine();
    } else {
      const value = e.target.value;
      const key = sAction
        .normalizeString(value)
        .replace(/ /g, "_")
        .toLowerCase();
      this.keyInput.current.value = key;
    }
  }

  addEnumLine() {
    var add = true;
    const data = this.props.data;
    const key = this.keyInput.current.value;
    const value = this.valueInput.current.value;

    const newState = { keyError: false };
    if (key != "" && !this.state.reg.test(key)) {
      add = false;
      newState.keyError = true;
    }

    data.get("optionList").forEach((line, index) => {
      if (line.get("value") == key) {
        add = false;
        newState.keyError = true;
      }
    });

    this.setState(newState);
    if (add) {
      console.log(value);
      const line = {
        label: value,
        value: key,
      };
      sAction.dataAdd("conf/popup/data/optionList", line);
      this.keyInput.current.value = "";
      this.valueInput.current.value = "";
      this.keyInput.current.focus();
    }
  }
  save() {
    const data = this.props.data;
    var add = true;
    const key = this.enumKeyInput.current.value;
    const newState = { enumKeyError: false };
    if (key == "") {
      add = false;
      newState.enumKeyError = true;
    }
    if (!this.state.reg.test(key)) {
      add = false;
      newState.enumKeyError = true;
    }
    this.setState(newState);
    if (add) {
      const data = this.props.data;

      const enumName = data.get("selectOption");

      var options = {};
      data.get("optionList").forEach((opt) => {
        options[opt.get("value")] = opt.get("label");
      });

      const params = {
        enum: enumName,
        options: options,
        forceClose: data.get("forceClose"),
      };
      sAction.editFieldSaveEnum(params);
    }
  }

  render() {
    const data = this.props.data;

    var optionsRender = [];
    const optionList = data.get("optionList");
    if (optionList) {
      data.get("optionList").forEach((line, index) => {
        optionsRender.push(
          <div key={index} className="detailViewEnumEditListLine">
            <div className="detailViewEnumEditListLineMove">
              <div
                className="detailViewEnumEditListLineMoveUp icon-arrowUp"
                onClick={() =>
                  sAction.editDetailChangeOptionIndex(
                    index,
                    "up",
                    data.get("optionList")
                  )
                }
              />
              <div
                className="detailViewEnumEditListLineMoveDown icon-arrowDown"
                onClick={() =>
                  sAction.editDetailChangeOptionIndex(
                    index,
                    "down",
                    data.get("optionList")
                  )
                }
              />
            </div>
            <div className="detailViewEnumEditListLineKey">
              {line.get("value")}
            </div>
            <InputText
              value={line.get("label")}
              onChange={(e) =>
                sAction.dataSet(
                  "conf/popup/data/optionList/" + index + "/label",
                  e.target.value
                )
              }
            />
            <div className="detailViewEnumEditListLineDelete">
              <div
                onClick={() =>
                  sAction.dataDelete("conf/popup/data/optionList", index)
                }
                className="icon-closeIconLight"
              />
            </div>
          </div>
        );
      });
    }

    return (
      <div className="simpleForm">
        <table>
          <tbody>
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_ENUM_KEY")}
              </td>
              <td>
                {data.get("lockOptionKey") === true ? (
                  <InputText
                    disabled
                    myRef={this.enumKeyInput}
                    value={data.get("selectOption")}
                    className="withBorder"
                  />
                ) : (
                  <InputText
                    myRef={this.enumKeyInput}
                    error={this.state.enumKeyError}
                    value={data.get("selectOption")}
                    className="withBorder"
                    onChange={(e) =>
                      sAction.dataSet(
                        "conf/popup/data/selectOption",
                        e.target.value
                      )
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <div className="detailViewEnumEditContainer">
                  <div className="detailViewEnumEditListContainer">
                    {optionsRender}
                  </div>
                  <div className="detailViewEnumEditAddLineContainer">
                    <InputText
                      label={sAction.translate("LBL_NEW_FIELD_KEY")}
                      myRef={this.keyInput}
                      error={this.state.keyError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <InputText
                      label={sAction.translate("LBL_NEW_FIELD_VALUE")}
                      myRef={this.valueInput}
                      onKeyUp={(e) => this.valueKeyUp(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Button onClick={() => this.addEnumLine()}>
                      {sAction.translate("LBL_DETAILEDIT_ADD")}
                    </Button>
                  </div>
                  <div className="flexInLine flexRight detailViewEnumEditSaveButton">
                    <Button
                      onClick={() =>
                        sAction.dataSet("conf/popup/data/view", "newField")
                      }
                    >
                    <div className="icon-undo buttonIcon"></div>
                      {sAction.translate("LBL_RETURN_BACK")}
                    </Button>
                    <Button onClick={() => this.save()}>
                    <div className="icon-saveIcon buttonIcon"></div>
                      {sAction.translate("LBL_SAVE_RECORD")}
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
