import React from "react";
import PureComponent from "../pure";

import  sAction  from "sAction";

export default class NotificationItem extends PureComponent {
  delete(){
    const index = this.props.index
    const data = this.props.data;
    sAction.setNotification(data.id,{deleted:1},index);
  }
  setSeen(seen){
    const index = this.props.index
    const data = this.props.data;
    
    if(seen == "1"){
      sAction.setNotification(data.id,{seen:0},index);
    }else if(seen == "0"){
      sAction.setNotification(data.id,{seen:1},index);
    }
  }
  read(){
    const index = this.props.index
    const data = this.props.data;
    if(data.seen != "1"){
      sAction.setNotification(data.id,{seen:1},index);
    }
  }

  render() {
    const data = this.props.data;
    var name = data.name.replace("{RECORD_NAME}", data.record_name);
    name = name.replace(
      "{MODULE_NAME}",
      sAction.app_strings["moduleList"][data.parent_module]
    );
    var description = data.description.replace(
      "{RECORD_NAME}",
      data.record_name
    );
    description = description.replace(
      "{MODULE_NAME}",
      sAction.app_strings["moduleList"][data.parent_module]
    );
    const src = data.url

    var containerClass = "notificationItem";
    if(data.seen == "0"){
      containerClass += " notSeen";
    }
    containerClass += " "+data.type
    var date = sAction.dateFormat(data.notify_date);
    return (
      <div className={containerClass}>
        <a href={src} className="notificationItemText" onClick={() => this.read()}>
          <div className="notificationItemHeader">{name}</div>
          <div className="notificationItemDesc">{description}</div>
          <div className="notificationItemDate">{date}</div>
        </a>
        <div className="notificationItemButtons">
          <div className="iconCancel notificationRemoveButton" onClick={() => this.delete()} />
          <div className="seenIcon notificationRemoveButton" onClick={() => this.setSeen(data.seen)} />
        </div>
      </div>
    );
  }
}
