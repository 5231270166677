import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import TabSelect from "../formElements/TabSelect";

class ImportFileHeader extends PureComponent {
  constructor(props) {
    super(props);
  }

  addFieldSelects = (headerArray, options, count) => {
    for (let i = 0; i < count; i++) {
      let clearLabel = null;
      let labelStyle = null;
      if (!sAction.dataGet(this.props.way + "/mapping/" + i)) {
        clearLabel = true;
      }
      if (sAction.dataGet(this.props.way + "/mapping/duplicates/" + i)) {
        labelStyle = { color: "red" };
      }
      let defaultValue = null;
      const mappedValue = sAction.dataGet(this.props.way + "/mapping/" + i);
      if (mappedValue !== undefined) {
        defaultValue = this.getVNameOfField(mappedValue);
      }
      headerArray.push(
        <th key={"field" + i}>
          <TabSelect
            containerClassName="withBorder"
            search={true}
            numOfTabSelect={i}
            options={options}
            onChange={this.onChange}
            container={"importView"}
            label={defaultValue ?? sAction.translate("LBL_CHOOSE_MAPPING")}
            clearLabel={clearLabel}
            labelStyle={labelStyle}
          />
        </th>
      );
    }

    return headerArray;
  };

  getVNameOfField = (name) => {
    const fields = this.props.data.get('fields').get('fields');
    const obj = fields.filter(field => {
      return field.get('name') === name;
    });
    if (obj.get(0) && obj.get(0).get('vname')) {
      return sAction.translate((obj.get(0).get('vname')));
    }

    return name;
  }

  addAdditionalData = (headerArray, additionalData) => {
    additionalData.forEach((data, key) => {
      if (!data || data.get("visible") === false) {
        return;
      }
      let labelStyle = null;
      if (data.get('duplicate') === true) {
        labelStyle = {color: "red"};
      }
      const name = data.get("label");
      headerArray.push(
        <th className={"relateHeader"} key={key}>
          <div className={"relateHeaderCell"}>
            <span style={labelStyle}>{name}</span>
            <span
              className={"icon icon-detailCancel"}
              onClick={() =>
                sAction.deleteColumn({
                  way: data.get("way"),
                  index: key,
                })
              }
            />
          </div>
        </th>
      );
    });

    return headerArray;
  };

  constructHeader = (data, additionalData) => {
    let header = [];
    const columnCount = data.get("columnCount");
    const selectContents = data.get("fields").get("fields").toJS();

    selectContents.sort((a, b) => {
      const aName = sAction.translate(a["vname"]);
      const bName = sAction.translate(b["vname"]);

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });

    const isFields = true;
    const options = this.props.getOptions(selectContents, isFields);
    header = this.addFieldSelects(header, options, columnCount);
    header = this.addAdditionalData(header, additionalData);
    return header;
  };

  onChange = (e) => {
    sAction.dsClear();
    sAction.dsAdd(
      "set",
      this.props.way + "/mapping/" + e.numOfTabSelect,
      e.value
    );
    sAction.dsProcess();
  };

  render() {
    const data = this.props.data;
    const additionalData = this.props.additionalData;
    let header = [];
    if (data !== undefined) {
      header = this.constructHeader(data, additionalData);
    }

    return <tr>{header}</tr>;
  }
}

export default ImportFileHeader;
