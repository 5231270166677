export default function importFileMapping(params) {
  const module = this.getModuleName();
  const prefix = params.prefix;
  const data = this.dataGet(prefix).toJS();
  let mapping = data.table.header.mapping;
  delete data.table.header.mapping.duplicates;
  const delimiters = data.delimiters;
  const fileEncoding = data.fileEncoding;
  const fileId = params.fileId;
  const ignoreFirst = data.ignoreFirstRow === 1;
  const mapFromFirst = data.useFirstAsMapping === 1;

  let additionalMapping;
  if (data.additionalData) {
    additionalMapping = this.formatRelatesMapping(data.additionalData);
  }

  const mappingData = {
    mapping: mapping,
    ignoreFirst: ignoreFirst,
    mapFromFirst: mapFromFirst,
    additionalData: additionalMapping,
    delimiters: delimiters,
    fileEncoding: fileEncoding,
    file: fileId,
  };

  this.dsClear();
  this.dsAdd("set", "conf/load", true);
  this.dsProcess();
  this.rest.post("import/" + module, mappingData, (data) => {
    this.loadImportedList(data, module, prefix);
  });
}
