import React from "react";
import PureComponent from "../../pure";
import sAction from "../../../action"

// import OrganizationChart from "@dabeng/react-orgchart";
// import 'regenerator-runtime/runtime'
// import MyNode from "../../orgChart/nodeClickable"
// import {fromJSON} from "../../../action/exportConfig"

export default class AccHierarchyPopup extends PureComponent {

    constructor(){
        super();
        this.state = {
            loaded : false,
            data : {},
        }

    }

    componentDidMount() {

        var record = this.props.data.get('params').get('id')

        const params = record;

        this.setState({
            loaded: false,
        })

        var component = this;
        // sAction.load()

        sAction.rest.post("getAccountHierarchy", params, function(resp) {
            if(resp.status === true){
                sAction.unLoad();

                component.setState({loaded:true});
                // var d = fromJSON(resp.message.data)

                component.setState({data:d[0]});
                // this.state.data = resp.message.data;
            }
        });
    }

    render(){

        if (this.state.loaded){
            var ds = this.state.data;

            // return <OrganizationChart datasource={ds} NodeTemplate={MyNode} chartClass="clickable" direction={"l2r"} collapsible={false}/>;

        }
        return <div> &nbsp;&nbsp;&nbsp;loading... </div>;

    }
}

