import React from "react";

import InputField from "../inputFieldClass";
import SelectParams from "ROOT/src/components/detailViewFields/RichText/SelectParams";

function htmlUnescape(str) {
  return str == null
    ? ""
    : str
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&");
}

export default class HTMLText extends InputField {
  constructor(props) {
    super(props);
  }

  getValue() {
    return tinyMCE.activeEditor.getContent();
  }

  handleEditorChange = (e) => {
    this.save()
  }

  componentDidMount() {
    var tinymce_min = document.querySelector('script[src*="tinymce.min.js"]');

    if (tinymce_min) {
      this.init_tinymce();
    } else {
      const script = document.createElement("script");
      script.src = "build/tinymce/tinymce.min.js";
      document.body.appendChild(script);

      script.onload = () => {
        this.init_tinymce();
      }
    }
  }
  init_tinymce = () => {

    var readonly = false;
    if (this.props.readonly || this.props.data.def.get("readonly")){
      readonly = true;
    }
    tinymce.init({
      selector: '#htmlEditor',
      height: '500px',
      language: 'cs',
      relative_urls : false,
      remove_script_host : false,
      document_base_url : document.location.origin,
      setup: (ed) => {
        ed.on('change', (e) => {
          this.handleEditorChange(e);
        });

      },
      menubar: readonly ? '' :'file edit view insert format tools table help',
      plugins: readonly ? [] :[
        'print preview fullpage paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
      ],
      toolbar: readonly ? [] :
        'undo redo | bold | forecolor fontsizeselect | fullscreen preview print',
      readonly: readonly ? 1 : 0
    });
  }

  componentWillUnmount() {
    document.getElementById("htmlEditorWrapper").innerHTML = "";
    //smazani tinymce tooltipu z <body>
    var elements = document.getElementsByClassName("tox-silver-sink");
    while(elements.length > 0){
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  // vlozi promenne do HTML editoru pres 2 selecty a 1 button (<SelectParams />)
  // (Firmy - Ulice -> $account_billing_address_street)
  addParam = (param) => {
    tinyMCE.activeEditor.selection.setContent(" " + param + " ");

    this.save();
  };

  render() {
    const {data, newRecord, readonly} = this.props;

    var value = data.value;
    if (newRecord && (value == "" || value == undefined)) {
      value = "";
    }
    if (value) {
      value = htmlUnescape(value);
    }

    if (data.def.get("readonly")){
      return (
        <div id="htmlEditorWrappper" className="w100">
          <textarea id="htmlEditor" className="readonly" defaultValue={value}></textarea>

        </div>
      );
    }
    return (
      <div id="htmlEditorWrapper" className="w100">
        {/* vlozi 2 selecty a 1 button
            (Firmy - Ulice -> $account_billing_address_street) */}
        {!readonly ? <SelectParams onAdd={this.addParam} /> : <br />}
        <textarea id="htmlEditor" defaultValue={value}></textarea>
      </div>
    );
  }
}
