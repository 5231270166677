import React from 'react';
// import TextField from "@material-ui/core/TextField"

import  sAction  from "sAction";
import moment from "moment";
import Immutable from "immutable";
import EmailDetailHeader from "./EmailDetailHeader";


export default class EmailDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      iframe: null
    };
  }

  componentDidMount() {
    // aktualizace upozorneni poctu neprectenych emailu v levem menu
    this.subtractUnreadEmails();
    this.setIframe();
  }

  componentDidUpdate() {
    this.subtractUnreadEmails();
    this.setIframe();
  }

  componentWillUnmount() {
    if (!this.iframe) return;
    this.iframe.removeEventListener("load", this.setIframeHeight);
  }

  setIframe = () => {
    this.iframe = document.getElementById("email_content");
    if (!this.iframe) return;

    let sID = sAction.rest.getCookie("sID");
    let description = this.props.data.description;

    if (!description) {
      //(Zpráva neobsahuje žádný text)
      description = sAction.translate("LBL_EMAIL_NO_TEXT", "Emails");
    } else {
      //uprava inline obrazku
      description = description.replace(
        /['"]cid:([^"'@]+)(@[^"']+)?["']/g,
        '"'+sAction.param.files + "emailImg/$1/" + sID +'"'
      );

      //uprava nevalidnich sugar odkazu
      description = description.replace(
        /<(http[^ >]+)>/g,
        '&lt;<a href="$1">$1</a>&gt;'
      );
    }

    this.iframe.contentWindow.document.open();
    this.iframe.contentWindow.document.write(description);
    var anchors = this.iframe.contentWindow.document.getElementsByTagName("a");
    for (var i = 0; i < anchors.length; i++) {
      anchors[i].target = "_blank";
    }
    this.iframe.contentWindow.document.close();

    this.iframe.addEventListener("load", this.setIframeHeight);
    // iframe.contentWindow.addEventListener("load", this.setIframeHeight);
    setTimeout(this.setIframeHeight, 1500);
  };

  setIframeHeight = () => {
    if (!this.iframe) return;

    if (this.iframe.contentWindow) {
      //this.iframe.style.height = 'auto';
      this.iframe.style.height = this.iframe.contentWindow.document.body.scrollHeight + 100 + 'px';
    }
  }

  subtractUnreadEmails() {
    // aktualizace upozorneni poctu neprectenych emailu v levem menu,
    // oznaceni emailu za precteny si sugar udela sam

    //pokud byl precteny - exit
    const seen = this.props.data.seen;
    if (seen !== 0 && seen !== "0") return;

    // ziskej celkovy pocet neprectenych emailu
    const way = this.props.folderWay + "/unseen";
    let unseen = sAction.dataGet(way);

    sAction.dsClear();
    if (unseen) sAction.dsAdd("set", way, unseen - 1); //odecteni
    sAction.dsAdd("set", this.props.data.way + "/seen", 1); //oznaceni emailu za precteny (lokalne)
    sAction.dsProcess();
  }

  downloadAttachment = (e) => {
    const { data } = this.props;

    const attachment = data.attachments_array.get(e.target.dataset.i);

    const id = attachment.get("id");
    const name = attachment.get("filename");

    sAction.downloadFile({
      id,
      name,
      module: 'Emails',
      partNum: attachment.get("partnum"),
      ieId: data.ieId,
      uid: data.uid,
      isImap: !data.is_sugarEmail
    });
  };

  render() {
    // console.log("render EmailDetail()", this.props);
    const { data, hideHeader } = this.props; // viz DataConstructors.EmailDetail

    // try {
    if (!Immutable.isRecord(data) && data.isEmpty()) {
      return (
        <div className="email__detail__empty">
          <div className="emailIcon" />
          {/*Není vybrán žádný e-mail*/}
          <div>{sAction.translate("ERR_NO_EMAIL", "Emails")}</div>
        </div>
      );
    }
    // } catch (e) {}
    let attachmentsNew = [];
    if (data.attachments_array) {
      data.attachments_array.valueSeq().forEach((a, i) => {
        let attachId = a.get("id") || "imap--" + data.ieId + "--" + data.uid + "--" + a.get("partnum");
        let attachName = a.get("filename");
        let eyeComp = null;
        if (/\.(bmp|jpg|jpeg|png|gif|tiff)$/i.test(attachName)) {
          eyeComp = <div className="icon-eye listViewEyeIcon" onClick={() => sAction.popup("image", {url: sAction.param.files+"image/"+attachId})}>&nbsp;</div>;
        }
        attachmentsNew.push(
          <div key={i + "_" + attachId}>
            {eyeComp}
            <a
              data-i={i}
              onClick={this.downloadAttachment}
            >
              {attachName}
            </a>
            <br />
          </div>
        );
      });
    }

    let date = "";
    if (data.is_sugarEmail) {
      if (data.time_start) {
        date += moment(new Date(data.date_start + " " + data.time_start)).format(sAction.getDateFormat("moment") + " " + sAction.getTimeFormat("moment"));
      } else if (data.date_start) {
        date = moment(new Date(data.date_start)).format(sAction.getDateFormat("moment"));
      }
    } else {
      date = data.date_start;
    }

    return (
      <React.Fragment>
        {!hideHeader && (<div className="email__detail__header">
          <EmailDetailHeader data={data} />
        </div>)
        }

        <div className="email__detail__body">
          <h1>{data.name}</h1>

          <table className="email__detail-table">
            <tbody>
              <tr>
                {/*Odesílatel*/}
                <th>{sAction.translate("LBL_FROM", "Emails")}</th>
                <td dangerouslySetInnerHTML={{ __html: data.from_addr }} />
              </tr>
              <tr>
                {/*Komu*/}
                <th>{sAction.translate("LBL_EMAIL_ADDRESS_BOOK_ADD_TO", "Emails")}</th>
                <td dangerouslySetInnerHTML={{ __html: data.toaddrs }} />
              </tr>
              {!!data.cc_addrs && (
                <tr>
                  {/*Kopie (cc):*/}
                  <th>{sAction.translate("LBL_CC", "Emails")}</th>
                  <td dangerouslySetInnerHTML={{ __html: data.cc_addrs }} />
                </tr>
              )}
              <tr>
                <th>{sAction.translate("LBL_DATE", "Project")}</th>
                <td>
                  {date}
                </td>
              </tr>
              {/*Přílohy*/}
              {attachmentsNew.length>0 && (
                <tr>
                  <th>{sAction.translate("LBL_ATTACHMENTS", "Emails")}</th>
                  {/*<td dangerouslySetInnerHTML={{ __html: data.attachments }} />*/}
                  <td>{attachmentsNew}</td>
                </tr>
              )}
            </tbody>
          </table>

          <hr />
          <iframe
            id="email_content"
            key={data.uid}
          />
          <hr />
        </div>
      </React.Fragment>
    );
  }
}
