import $ from 'jquery';

export default function calToggleSharedView(){

    var state = !this.calEvents.sharedView;

    this.calEvents.sharedView = state;

    this.dataSet("calendar/sharedView", state);
    this.displayCalContainer(this, true);

    //this.dataSet("calendar/sharedView", state);
}