export default function test_invoicetemplate(params){

  this.popup("test_invoicetemplate",{
      header:this.translate("LBL_TEST_TEMPLATE"),
      callback: (data) => {
          const d = {
              ...params,
              ...data,
          }
          this.dsClear();
          this.dsAdd("set","conf/load",true);
          this.dsAdd("set","conf/popup/data/message","");
          this.dsProcess();
          this.rest.post("test_invoicetemplate",d,ret => {
              this.dsClear();
              this.dsAdd("set","conf/load",false);
              this.dsAdd("set","conf/popup/data/message",ret.message);
              this.dsProcess();
          })
      }
  });
}