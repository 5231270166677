import React from "react";

import DropZone from "../../DropZone/DropZone";
import Button from "../../formElements/Button";
import  sAction  from "sAction";

export default class FileUpload extends React.PureComponent {
  state = {
    hidden: true
  };

  addAttachment = () => {
    if (!this.state.hidden) {
      this.setState({ hidden: false });
      //return;
    }

    document.querySelector("input[type=file]").click();
  };

  addSugarDocument = () => {
    if (!this.state.hidden) {
      this.setState({ hidden: false });
      //return;
    }

    document.querySelector("input[class=addSugarDocument]").click();
  };

  downloadFile = e => {
    e.stopPropagation();
    e.preventDefault();
    const attachment = this.props.data.def.getIn(["files", e.target.dataset.i])
    const id = attachment.get("id");
    const name = attachment.get("name");
    sAction.downloadFile({
      id,
      name,
      cache: true,
      preventDelete: true,
    });
  };

  render() {
    const { hidden } = this.state;

    return (
      <React.Fragment>
        <DropZone
          prefix={this.props.prefix}
          way={this.props.way + "/def/files"}
          attachments={this.props.data.def.get("files")}
          hidden={hidden}
          handleDownload={this.downloadFile}
        />

        <div className="viewActionPanelButtons">
          <Button onClick={this.addAttachment} className="hoverBlue">
            <div className="actionPanelButtonIcon icon-attachment" />{" "}
            {/*Přidat přílohu z PC*/}
            {/*Přidat soubor*/}
            {sAction.translate("LBL_ADD_FILE", "Emails")}
          </Button>

          <div className="viewPanelButtonsDelimiter" />

          <Button onClick={this.addSugarDocument} className="hoverBlue">
            <div className="actionPanelButtonIcon icon-attachment2" />{" "}
            {/*Přidat Sugar Dokument*/}
            {sAction.translate("LBL_ADD_DOCUMENT", "Emails")}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
