
export default function printFile(data) {
  data["action"] = "printPdf"

  this.dsClear();
  this.dsAdd("set","conf/popup/show",true);
  this.dsAdd("set","conf/popup/content","fileDownload");
  this.dsAdd("set","conf/popup/data/state","load");
  this.dsAdd("set","conf/popup/data/buttons",[]);
  this.dsProcess();

  this.filesXhr({
    action: "generateFileRequest",
    data: data,
    success: ret => {
      if(ret && ret.status == true){
        this.dsClear();
        this.dsAdd("set","conf/popup/data/state","init")
        this.dsAdd("set","conf/popup/data/token",ret.token)
        this.dsProcess();
      } else {
        this.popupHide();
        this.error("Omlouváme se, nastala neočekávaná chyba");
      }
    },
    error: () => {
      this.popupHide();
      this.error("Omlouváme se, nastala neočekávaná chyba");
    }
  });
}