import React, { Component } from "react";

class StandartMultiSelect extends React.Component {
    
    handleOptionClick(val){
        let selected = this.props.selected;
        const ind = selected.indexOf(val);

        if(ind >= 0){
            selected.splice(ind, 1);
        }
        else{
            selected.push(val);
        }

        if(this.props.onChange != undefined) {
            this.props.onChange(selected);
        }
    }

    render(){
        let optionsRender = [];
        let selected = [];
        if(this.props.selected != null){
            selected = this.props.selected;
        }

        this.props.options.forEach(opt => {
            let classIt = "";
            if(selected.indexOf(opt.value) >= 0){
                classIt = "selected";
            }

            let row = <li key={opt.value} value={opt.value} className={classIt} onClick={() => this.handleOptionClick(opt.value)}>{opt.label}</li>;
            optionsRender.push(row);
        });

        let className = "standartMultiSelect";

        if(this.props.className != undefined){
            className += " "+ this.props.className;
        }

        return(
            <div className={className}>
                <ul>
                    {optionsRender}
                </ul>
            </div>
        );
    }
}
export default StandartMultiSelect;