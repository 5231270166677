export default function importFile(paramData) {
    const prefix = paramData.prefix;
    const data = this.dataGet(prefix).toJS();
    if ( ! data.import) {
        this.error('Nahrajte, prosím, csv soubor', 'Import');

        return false;
    }

    if ( ! data.table.header) {
        this.error('Namapujte alespoň jedno pole', 'Import');

        return false;
    }

    const mapping = data.table.header.mapping;
    const additionalMapping = data.additionalData;
    const validMapping = this.validateImportMapping(prefix, mapping, additionalMapping);
    if ( ! validMapping) {
        this.error('Duplicity v mapování. Každé pole může být namapováno maximálně na jeden sloupec.');

        return false;
    }

    /*
    Pokud jsme dosli az sem, nejsou namapovane zadnu duplicitni sloupce
     */
    delete data.table.header.mapping.duplicates;
    let mappingValues = Object.values(data.table.header.mapping);
    /*
    null slouzi jako zastupna hodnota kdyz neni mapovani,
    proto je potreba ho pro kontrolu zda je neco namapovane odstranit
    */
    mappingValues = mappingValues.filter(val => val != null);
    if ( ! mappingValues.length) {
        this.error('Namapujte alespoň jedno pole', 'Import');

        return false;
    }

    const fields =  data.fields.fields;
    const required = this.checkRequiredAreMapped(mappingValues, additionalMapping, fields);
    let requiredNames = '';
    for (let req of required) {
        req = this.translate(req).replace(':', '');
        requiredNames += req + ", ";
    }
    requiredNames = requiredNames.slice(0, -2) + '. ';

    if (required.length) {
        this.confrim("Následující povinná pole nejsou namapovaná: " + requiredNames + "Přejete si přesto pokračovat?", () => {
            this.popupHide();
            this.uploadCacheFile(this.temporaryData.file, (data) => {
                const fileId = data['ids'][0]['file']['id'];
                this.importFileMapping({prefix: prefix, fileId: fileId});
                this.popupHide();
            });
        });
    } else {
        this.uploadCacheFile(this.temporaryData.file, (data) => {
            const fileId = data['ids'][0]['file']['id'];
            this.importFileMapping({prefix: prefix, fileId: fileId});
            this.popupHide();
        });
    }
}