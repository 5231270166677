

export default function  toggleLine(id,state,prefix){ 

    const action = {
        type: "TOGGLELINE",
        listViewPrefix: prefix,
        id: id,
        state: state,
    }
    this.dataSetCustom(action);

}