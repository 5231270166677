import React, { PureComponent } from "react";
import RichText from "../formElements/HTMLText";

import  sAction  from "sAction";

import { EmailComposeHeaderPopup } from "./EmailComposeHeaderPopup";
import { EmailComposeForm } from "./EmailComposeForm";
import { EmailComposeFormBottom } from "./EmailComposeFormBottom";
import Loader from "../loader";
import ErrorMessage from "../default/ErrorMessage";

const RichEditor = React.forwardRef((props, ref) => <RichText ref={ref} {...props} />);

export default class EmailCompose extends PureComponent {
  constructor(props) {
    super(props);

    sAction.emailPrepareReplyData(props);

    this.textRef = React.createRef();

    this.state = {
      error_msg: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const props = this.props.data;

    if (props.filledComposeCache === 0) sAction.emailFillComposeCache(this.props);

    if (!props.updateEmailText) return;

    if (props.updateEmailText === "relate") {
      this.refreshVariables();
    } else {

      let description = props.description_html
        ? props.description_html.replace(
          /['"]cid:([^"'@]+)(@[^"']+)?["']/g,
          '"' + sAction.param.files + "emailImg/$1/" + sAction.rest.getCookie("sID") + '"'
        )
        : "";

      this.setEditorContent(description);
    }

    sAction.dataSet(this.props.way + "/updateEmailText", "");
  }

  componentDidMount() {
    // console.log("render componentDidMount ()");
    const props = this.props.data;

    if (props.filledComposeCache) {
      if (!props.uid) this.changeSignature(props.signature);
    }
  }

  getEmailTemplateConfirm = (e) => {
    const id = e.target.value;
    if (!id) {
      sAction.confrim("Chcete smazat veškerý text?", () => {
        sAction.popupHide();

        sAction.dsClear();
        sAction.dsAdd("set",this.props.way + "/emailTemplate", "");
        sAction.dsAdd("set",this.props.way + "/signatureId", "");
        sAction.dsAdd("set",this.props.way + "/description_html", "");
        sAction.dsProcess();

        this.setEditorContent("");
        this.changeSignature("");

      });
      return;
    }

    let contents = this.getEditorContent();

    //upozorneni se nezobrazi, kdyz text emailu obsahuje pouze default podpis
    contents = /<body.*?>([\s\S]*)<\/body>/.exec(contents)[1].trim();
    if (contents.match(/<div id=["']signature["']>/)) {
      contents = contents.replace(/<div id=["']msgBody["']>([\s]*)<p>&nbsp;<\/p>([\s]*)<div id=["']signature["']>([\s\S]*)<\/div>([\s]*)<\/div>/g, "");
    }

    if (contents) {
      // Výběrem této šablony přepíšete text v těle e-mailu. Přejete si pokračovat?
      sAction.confrim(sAction.translate("LBL_EMAILTEMPLATE_MESSAGE_SHOW_MSG", "Emails"), () => {
        sAction.popupHide();
        sAction.emailChangeTemplate(id, this.props);
      });
    } else {
      sAction.emailChangeTemplate(id, this.props);
    }
  };

  refreshVariables = () => {
    sAction.emailRefreshVariables(this.props);
  };

  changeSignature = (signature) => {
    sAction.emailChangeSignature(signature, this.props);
  };

  getSignature = (e) => {
    const id = e.target.value;

    if (id === this.props.data.signatureId) return;

    sAction.dataSet(this.props.way + "/signatureId", id);

    if (!id) {
      this.changeSignature("");
      return;
    }
    sAction.rest.post("email/getSignature", { id }, (data) => {
      this.changeSignature(data.signature_html);
    });
  };

  isFormValid = (status) => {
    let contents = this.getEditorContent();
    /*if (contents === "" || contents === "<p><br></p>")
      // Není vyplněn text zprávy
      return sAction.translate("ERR_NO_TEXT", "Emails");*/

    let { account, cc_addrs, bcc_addrs, to_addrs } = this.props.data;

    const error_msg = [];

    if (status !== "draft") {
      if (!account) {
        // Není vybrán žádný odesílatel
        error_msg.push(sAction.translate("ERR_NO_SENDER", "Emails"));
      }

      if (to_addrs === "" && cc_addrs === "" && bcc_addrs === "") {
        // Není vyplněn žádný adresát
        error_msg.push(sAction.translate("ERR_NO_RECEPIENT", "Emails"));
      }
    }

    let to_addrs_errors = sAction.emailsCheckFormat(to_addrs);
    let cc_addrs_errors = sAction.emailsCheckFormat(cc_addrs);
    let bcc_addrs_errors = sAction.emailsCheckFormat(bcc_addrs);

    if (to_addrs_errors || cc_addrs_errors || bcc_addrs_errors) {
      error_msg.push(
        sAction.translate("ERR_EMAIL_FORMAT_INVALID", "Emails") +
          ":" +
          (to_addrs_errors ? "<br><br><b>" + sAction.translate("LBL_EMAIL_TO") + "</b>: " + to_addrs_errors : "") +
          (cc_addrs_errors ? "<br><br><b>" + sAction.translate("LBL_CC", "Emails") + "</b>: " + cc_addrs_errors : "") +
          (bcc_addrs_errors ? "<br><br><b>" + sAction.translate("LBL_BCC", "Emails") + "</b>: " + bcc_addrs_errors : "")
      );
    }

    this.setState({ error_msg });

    if (error_msg.length) return false;

    if (contents.match(/(\$[A-Za-z][A-Za-z0-9_])/)) {
      return window.confirm(
        // "Text e-mailu obsahuje nevyplněné $proměnné, chcete jej přesto odeslat?"
        sAction.translate("ERR_UNFILLED_VARIABLES", "Emails")
      );
    }

    return true;
  };

  submitCheck = (status) => {
    // formular neni zvalidovan
    if (!this.isFormValid(status)) return false;

    if (status !== "draft" && !this.props.data.name) {
      sAction.confrim(sAction.translate("LBL_SEND_ANYWAYS", "Emails"), () => {
        sAction.popupHide();
        this.submit(status);
      });
    } else {
      this.submit(status);
    }
  };

  submit = (status) => {
    let {
      uid,
      name: subject,
      cc_addrs,
      bcc_addrs,
      to_addrs,
      parent_type,
      // parent_name,
      parent_id,
      account,
      attachments: files,
      send_individually,
      customData,
      recordModule,
      recordId
    } = this.props.data;

    let attachments = [];
    let documents = [];
    let templateAttachments = [];
    let imapAttachments = [];
    let generateAttachments = {};

    if (files) {
      generateAttachments = files.get("generateAttachments") || {};

      files.valueSeq().forEach((a) => {
        switch (a.get("source")) {
          case "sugar":
            documents.push(a.get("id"));
            break;

          case "sugartemplate":
            templateAttachments.push(a.get("id"));
            break;

          case "generated":
            break;

          case "imap":
            imapAttachments.push(a);
            break;

          default:
            attachments.push(a.get("id") + a.get("name"));
            break;
        }
      });
    }

    attachments = attachments.join("::");
    documents = documents.join("::");
    templateAttachments = templateAttachments.join("::");

    let ieId = "";
    let mbox = "";
    let email_id = "";
    // rozepsany email
    if (uid) {
      email_id = uid;
      ieId = sAction.dataGet("view/selectedFolder/ieId");
      mbox = "sugar::Emails";
    }

    const custom = (customData) ? "Custom" : "";

    let sendDescription = sAction.emailRemoveVariableHighlights(this.getEditorContent());
    sendDescription = sendDescription.replace(/<p><br><\/p>/g,"<br>").replace(/<\/p>/g,"<br>", "").replace(/<p>/g,"");

    sAction.load();
    sAction.rest.post(
      "email/sendEmail"+custom,
      {
        email_id,
        uid,
        ieId,
        mbox,
        type: "",
        composeLayoutId: "composeLayout1",
        composeType: "",
        fromAccount: account,
        sendSubject: subject,
        sendDescription,
        sendTo: to_addrs,
        sendBcc: bcc_addrs,
        sendCc: cc_addrs,
        setEditor: 1,
        saveToSugar: 1,
        parent_id,
        parent_type,
        attachments,
        documents,
        outbound_email: "",
        templateAttachments,
        templateAttachmentsRemove: "",
        generateAttachments,
        imapAttachments,

        // data_parent_type1: parent_type,
        // data_parent_id1: parent_id,
        // data_parent_name1: parent_name,
        // addressFrom1: account,
        // addressTO1: to_addrs,
        // addressCC1:cc_addrs,
        // addressBCC1: bcc_addrs,
        // subject1: subject,
        // htmleditor1: "",

        sugar_body_only: "true",
        to_pdf: "true",
        module: "Emails",
        action: "EmailUIAjax",
        emailUIAction: "sendEmail",
        saveDraft: status === "draft" ? "true" : null,
        send_individually,
        customData,
        recordModule,
        recordId,

        // /* link attributes */
        // parent_name: ,
      },
      (returnData) => {
        sAction.unLoad();

        if (!returnData) {
          sAction.error(
            sAction.translate(
              "ERR_EMAIL_SEND_ERROR",
              "Emails",
              sAction.translate("LBL_SEND_EMAIL_FAIL_TITLE", "Emails")
            )
          );
        } else if (typeof returnData !== "object") {
          sAction.error(returnData);
        } else if (returnData.status !== "error") {
          sAction.popupHide();
          sAction.alert(
            status === "draft"
              ? sAction.translate("LBL_EMAIL_DRAFT_SAVED", "Emails")
              : sAction.translate("LBL_EMAIL_SENT", "Emails")
          );
        }
      }
    );

    return true;
  };

  getEditorContent = () => {
    return tinyMCE.activeEditor.getContent();
  };

  setEditorContent = (content) => {
    const current = this.textRef.current;
    
    if (!current) return;

    current.setValue(content);
  };

  saveEmailText = () => {
    sAction.dataSet(this.props.way + "/description_html", this.getEditorContent());
  };

  render() {
    // console.log("render emailCompose()");

    const { way, data } = this.props;
    const { error_msg } = this.state;

    if (!data.loaded) return <Loader />;

    return (
      <React.Fragment>
        <EmailComposeHeaderPopup way={way} onSubmit={this.submitCheck} />

        <div className="email__detail__body">
          {!!error_msg.length && (
            <div style={{ marginTop: 15 }}>
              <ErrorMessage>{error_msg}</ErrorMessage>
            </div>
          )}

          <div className="email__compose__flex">
            <EmailComposeForm
              data={data}
              openPopupAddContact={this.openPopupAddContact}
              handleRelate={this.handleRelate}
              way={way}
            />

            <RichEditor
                ref={this.textRef}
                defaultValue={data.description_html}
                onChange={this.saveEmailText}
                refreshVariables={this.refreshVariables}
            />

            <EmailComposeFormBottom
              data={data}
              getEmailTemplateConfirm={this.getEmailTemplateConfirm}
              getSignature={this.getSignature}
              way={way}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
