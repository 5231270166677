import React from "react";
import PureComponent from "../../pure";

// import InputText from "../formElements/InputText";

import  sAction  from "sAction";

import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../formElements/Button";

export default class ChangesDetectedPopup extends PureComponent {
  constructor(props) {
    super(props);
  }

  //ulozeni zmen v popupu
  usePopup = (e) => {
    sAction.popupHide();

    var uId = sAction.dataGet('conf/user/id');
    var listModule = sAction.dataGet('view/modul');

    var changes = sAction.getStorage('changes-'+listModule+'-'+uId);

    // sAction.redirect("http://localhost:8080/#detail/"+changes.module+"/"+changes.id+"")
    if (changes.id == null){
      changes.id = "";
    }
    sAction.href("#detail/"+changes.module+"/"+changes.id,'_blank');

  };
  deletePopup = (e) => {
    sAction.popupHide();
    sAction.removeStorage('changes-'+this.props.data.get("module")+'-'+sAction.dataGet('conf/user/id'))
  };

  render() {
    const name = this.props.data.get("name");
    const id = this.props.data.get("id");

    return (
      <div>
        <div className="acmPopupHeader">
          {sAction.translate("LBL_POPUP_CHANGES_HEADER")}
        </div>
        <div className="acmPopupContent">
          <div className="w100">
            <p> {sAction.translate("LBL_POPUP_TEXT")}</p>
          </div>
        </div>
        <div className="acmPopupButtons">
          <button className="acmButton hoverGreen" data-value={id} onClick={this.usePopup}>{sAction.translate("LBL_POPUP_LOAD")}</button>
          <button className="acmButton hoverRed" data-value={id} onClick={this.deletePopup}>{sAction.translate("LBL_POPUP_DISCARD")}</button>
        </div>
      </div>
    );
  }
}
