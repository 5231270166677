
export default function isir(sendData, prefix = "view") {
  if (!sendData.ic) {
    this.error(this.translate("LBL_CHECK_ICO_MSGERR_NOICO", "Accounts"), this.translate("LBL_CHECK_ICO_MSGERR_ENTERICO", "Accounts"));
    return;
  }

  this.load();
  this.rest.post("detailapi/checkisir", sendData, data => {
    let html = "";

    if (data.isir_stat == 1) {
      html = `
                    <b>${this.translate(
                      "LBL_CHECK_ISIR_MSG_FOUND_REC",
                      "Accounts"
                    )}</b><br /><br />
                    ${this.translate("LBL_STAV", "Accounts")}: ${
        data.isir_stav_text
      }<br /><br />
                    ${this.translate("LBL_GO_TO_RECORD_CONFIRM", "Accounts")}`;

      this.confrim(html, () => {
        this.popupHide();
        window.open(
          "https://isir.justice.cz/isir/ueu/vysledek_lustrace.do?ic=" +
            sendData.ic +
            "&aktualnost=AKTUALNI_I_UKONCENA&rowsAtOnce=50&spis_znacky_obdobi=14DNI"
        );
      });
    } else {
      html = this.translate("LBL_CHECK_ISIR_MSG_NOTFOUND_REC", "Accounts");

      this.alert(html, this.translate("LBL_CHECK_ISIR_MSG_TITLE", "Accounts"));
    }

    delete data.stat;

    this.updateFields(data, prefix);
  });
}
