import { DataConstructors } from "../../../DataConstructors";
import { List } from "immutable";

export default function createListViewTree(cat,main = false){
    if(cat == null){
      return null;
    }
   var listChildren = [];
   var children = cat.children
   children.forEach(child => {
     listChildren.push(createListViewTree(child));
   })

   var data = {
     id: cat.id,
     name: cat.name,
     type: cat.type,
     children: List(listChildren)
   }
     return new DataConstructors.listViewTreeCat(data);
   
   
}