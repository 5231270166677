
import detailDefault from "../../detailDefault";
export default class detailacm_orders_lines extends detailDefault{
    load(sAction,data){

    }
    update(sAction,data){

            const fields = sAction.dataGet(data.prefix+"/fields");
            const cenaBezDphKus = fields.getIn(["cena_bez_dph_kus","value"]);
            const mnozstvi = fields.getIn(["mnozstvi","value"]);
            const slevaAbs = fields.getIn(["sleva_hodnota_c","value"]);
            const sazbaDph = fields.getIn(["procento_dph","value"]);
            var slevaProcento = 0;

            var slevaHodnota = "";

            if(cenaBezDphKus){
                var cenabezdph = sAction.normalizePrice(cenaBezDphKus) * sAction.normalizePrice(mnozstvi);
                if (slevaProcento != '' && slevaProcento) {
                    var cenabezdphorig = cenabezdph;
                    cenabezdph -=  (sAction.normalizePrice(slevaProcento) / 100) * cenabezdph;
                    var cenasleva = cenabezdphorig - cenabezdph;
                    slevaHodnota = cenasleva;
                }
            }

            if (slevaAbs != '' && slevaAbs) {
                cenabezdph -= slevaAbs;
                slevaProcento = "";
            }

            var dphkoef = sAction.normalizePrice(sazbaDph / 100) + 1;
            var cenasdph = cenabezdph * dphkoef;
            var dph = cenasdph - cenabezdph;

            sAction.dsClear();
            sAction.dsAdd("set",data.prefix+"/fields/cena_bez_dph_celkem/value",cenabezdph)
            sAction.dsAdd("set",data.prefix+"/changes/fields/cena_bez_dph_celkem",cenabezdph)
            sAction.dsAdd("set",data.prefix+"/fields/cena_s_dph_celkem/value",cenasdph)
            sAction.dsAdd("set",data.prefix+"/changes/fields/cena_s_dph_celkem",cenasdph)
            sAction.dsAdd("set",data.prefix+"/fields/cena_dph_celkem/value",dph)
            sAction.dsAdd("set",data.prefix+"/changes/fields/cena_dph_celkem",dph)
            sAction.dsAdd("set",data.prefix+"/fields/sleva_hodnota_c/value",slevaHodnota)
            sAction.dsAdd("set",data.prefix+"/changes/fields/sleva_hodnota_c",slevaHodnota)
          //  sAction.dsAdd("set",data.prefix+"/fields/sleva_procento/value",slevaProcento)
          //  sAction.dsAdd("set",data.prefix+"/changes/fields/sleva_procento",slevaProcento)

            var cenasdphkus = cenaBezDphKus * dphkoef;
            var dphkus = cenasdphkus - cenaBezDphKus;
            sAction.dsAdd("set",data.prefix+"/fields/cena_dph_kus/value",dphkus)
            sAction.dsAdd("set",data.prefix+"/changes/fields/cena_dph_kus",dphkus)
            sAction.dsAdd("set",data.prefix+"/fields/cena_s_dph_kus/value",cenasdphkus)
            sAction.dsAdd("set",data.prefix+"/changes/fields/cena_s_dph_kus",cenasdphkus)
            sAction.dsProcess();
        }
    }
