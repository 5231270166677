import React from "react";
import PureComponent from "../pure";
import ImportFileTable from "./ImportFileTable";
import ImportFileSelect from "./ImportFileSelect";
import ViewPanelMenu from "../viewPanelMenu/viewPanelMenu";
import ListViewTable2 from "../list/listViewTable2";
import sAction from "sAction";
import Button from "../formElements/Button";
import CheckBox from '../formElements/CheckBox';

class Import extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (sessionStorage.getItem("listImported") === "true") {
      return;
    }

    let delimiterOptions = [];
    delimiterOptions.push(
      {
        value: "\n",
        label: <span className="importViewSelectorOptions">\n</span>,
      },
      {
        value: "\r\n",
        label: <span className="importViewSelectorOptions">\r\n</span>,
      },
      {
        value: "\r",
        label: <span className="importViewSelectorOptions">\r</span>,
      },
      {
        value: ",",
        label: <span className="importViewSelectorOptions">,</span>,
      },
      {
        value: ";",
        label: <span className="importViewSelectorOptions">;</span>,
      },
      {
        value: ".",
        label: <span className="importViewSelectorOptions">.</span>,
      }
    );

    let encodingOptions = [];
    encodingOptions.push(
      {
        value: "UTF-8",
        label: (
          <span className="importViewSelectorOptions">
            <small>UTF-8</small>
          </span>
        ),
      },
      {
        value: "Windows-1250",
        label: (
          <span className="importViewSelectorOptions">
            <small>Windows-1250</small>
          </span>
        ),
      },
      {
        value: "ISO-8859-2",
        label: (
          <span className="importViewSelectorOptions">
            <small>ISO 8859-2</small>
          </span>
        ),
      }
    );

    this.setState({
      delimiters: delimiterOptions,
      encodingOptions: encodingOptions,
    });
    sAction.raiseFileUpload({ prefix: this.props.way });
  }

  populateDropdown = (fields, isFields) => {
    let renderData = [];
    let options = [];

    if (isFields && ! sAction.isPopupOpen()) {
      renderData.push({
        value: null,
        label: "-- " + sAction.translate("LBL_IGNORE_FIELD") + " --",
      });
    }

    const module = this.props.data.get("module");
    const mappedRelates = sAction.dataGet('view/mappedRelates');
    fields.forEach((field, index) => {
      if ( ! isFields && mappedRelates.includes(field['id_name'])) {
        return;
      }

      let translation = field["vname"];
      if (sAction.moduleTranslate[module] !== undefined) {
        translation = sAction.moduleTranslate[module][translation];
      }
      if (
        translation == null ||
        translation === "" ||
        translation === undefined
      ) {
        translation = sAction.app_strings[translation];
      }
      renderData.push({
        value: field["name"],
        label: translation,
        type: field['type'],
        index: index,
      });
    });

    if (this.props.module === "Users" && isFields) {
      renderData.unshift({
        value: sAction.translate("LBL_USER_ID_PLACEHODLER"),
        label: sAction.translate("LBL_USER_ID_PLACEHODLER"),
      });
    }
    const tabOption = isFields
      ? sAction.translate("LBL_REPORT_SEARCH_FIELDS")
      : sAction.translate("LBL_REPORT_SEARCH_RELATE");
    options[tabOption] = renderData;

    return options;
  };

  changeLineDelimiter = (delimiter) => {
    sAction.dsClear();
    sAction.dsAdd(
      "set",
      this.props.way + "/delimiters/lineDelimiter",
      delimiter
    );
    sAction.dsProcess();
    sAction.parseFile({ prefix: this.props.way });
  };

  changeColumnDelimiter = (delimiter) => {
    sAction.dsClear();
    sAction.dsAdd(
      "set",
      this.props.way + "/delimiters/columnDelimiter",
      delimiter
    );
    sAction.dsProcess();
    sAction.parseFile({ prefix: this.props.way });
  };

  changeEncoding = (encoding) => {
    sAction.dsClear();
    sAction.dsAdd("set", this.props.way + "/fileEncoding", encoding);
    sAction.dsProcess();
    sAction.readFile({ prefix: this.props.way, encoding: encoding });
  };

  addColumn = () => {
    const relates = this.props.data.get("additionalData");
    const index = relates.size;
    const way = this.props.way + "/additionalData/" + index;

    const module = this.props.data.get("module");
    const data = {
      relates: this.props.data.get("fields").get("relate"),
      fields: this.props.data.get("fields").get("fields"),
      getOptionsCallback: this.populateDropdown,
      module: module,
      way: way,
      buttons: [
        {
          label: sAction.translate("LBL_CANCEL", "Home"),
          callback: () =>
            sAction.deleteColumn({
              way: this.props.way + "/additionalData",
              index: index,
            }),
        },
        {
          label: sAction.translate("LBL_CONTINUE", "Home"),
          callback: () => {
            sAction.addImportColumn({
              way: this.props.way + "/additionalData",
              index: index,
            });
            sAction.popupHide();
          },
        },
      ],
      onClose: () =>
        sAction.deleteColumn({
          way: this.props.way + "/additionalData",
          index: index,
        }),
    };
    sAction.popup("MapAdditionalData", data);
  };

  modifyFirstRow = () => {
    let ignoreFirstRow = this.props.data.get('ignoreFirstRow') === 0 ? 1 : 0;
    let rows = this.props.data.get("import");
    sAction.dsClear();

    if (ignoreFirstRow) {
      rows = this.deleteFirstRow(rows);
    }  else {
      rows = this.addFirstRow(rows);
    }

    sAction.dsAdd('set', this.props.way + '/import', rows);
    sAction.dsAdd('set', this.props.way + '/ignoreFirstRow', ignoreFirstRow);
    sAction.dsProcess();
  }

  deleteFirstRow = (rows) => {
    sAction.dsAdd('set', this.props.way + '/firstRow', rows.get(0));
    return rows.delete(0);
  }

  addFirstRow = (rows) => {
    const firstRow = sAction.dataGet(this.props.way + '/firstRow');
    return rows.insert(0, firstRow);
  }

  mapFromFirstRow = () => {
    let useFirstAsMapping = this.props.data.get('useFirstAsMapping') === 0 ? 1 : 0;
    let rows = this.props.data.get("import");
    if (useFirstAsMapping) {
      sAction.dsClear();
      let first = rows.get(0);
      sAction.mapFromFirstRow(this.props.way + '/table/header/mapping', first, this.props.data.get('fields').get('fields'));
      rows = this.deleteFirstRow(rows);
    } else {
      rows = this.addFirstRow(rows);
      sAction.dsAdd('set', this.props.way + '/table/header/mapping', {});
    }


    sAction.dsAdd('set', this.props.way + '/import', rows);
    sAction.dsAdd('set', this.props.way + '/useFirstAsMapping', useFirstAsMapping);
    sAction.dsProcess();
  }

  render() {
    const data = this.props.data;
    const prefix = this.props.way;
    let csvMapping;
    let table;
    let delimiterSelects = [];

    // znamena, ze mame nacteny soubor od uzivatele
    if (data.get("import")) {
      csvMapping = (
        <ImportFileTable
          way={prefix + "/table"}
          data={data}
          key="mapping"
          getOptions={this.populateDropdown}
        />
      );
      delimiterSelects.push(
        <>
          <ImportFileSelect
            options={this.state.delimiters}
            label={sAction.translate("LBL_COLUMN_DELIMITER")}
            key="columnDelimiter"
            value={data.toJS()["delimiters"]["columnDelimiter"]}
            onChange={this.changeColumnDelimiter}
          />
          <ImportFileSelect
            options={this.state.delimiters}
            label={sAction.translate("LBL_ROW_DELIMITER")}
            key="lineDelimiter"
            value={data.toJS()["delimiters"]["lineDelimiter"]}
            onChange={this.changeLineDelimiter}
          />
          <ImportFileSelect
            options={this.state.encodingOptions}
            label={sAction.translate("LBL_FILE_ENCODING")}
            key="fileEncoding"
            value={data.toJS()["fileEncoding"]}
            onChange={this.changeEncoding}
          />
          </>
      );
    }

    // znamena, ze mame importovana data ze souboru od uzivatele a zobrazujeme vysledek ve forme listu
    if (data.get("listViewTree")) {
      table = (
        <ListViewTable2
          data={data}
          parent={this}
          forceRightPanel={true}
          prefix={prefix}
          key="importedList"
        />
      );
    }

    return (
      <div className="importViewMainContainer">
        {data.get("buttons") && <ViewPanelMenu data={data} prefix={prefix} />}

        <div className="importViewContainer" id={"importView"}>
          <div className="importViewSelectorContainer" key="delimiterSelects">
            {delimiterSelects}
            <div className={"importFlr"}>
              {data.get("import") && (
                <Button className={"stillHover"} onClick={this.addColumn}>
                  <span className={"icon icon-addIcon"} />
                  Přidat sloupec
                </Button>
              )}
            </div>
          </div>
          <div className="importViewSelectorContainer" key="checkboxSelects">
            {data.get("import") && (
              <>
                <div className="ImportViewCheckbox"><CheckBox defaultValue={this.props.data.get('ignoreFirstRow')} readonly={this.props.data.get('useFirstAsMapping') === 1} onChange={this.modifyFirstRow}/>Ignorovat první řádek</div>
                <div className="ImportViewCheckbox"><CheckBox defaultValue={this.props.data.get('useFirstAsMapping')} readonly={this.props.data.get('ignoreFirstRow') === 1} onChange={this.mapFromFirstRow}/>Namapovat podle prvního řádku</div>
              </>
            )}
          </div>

          {table}
          {csvMapping}
        </div>
      </div>
    );
  }
}

export default Import;
