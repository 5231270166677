export default function clearNonValidGroupBy() {
    const activeColumns = this.dataGet("reportWindow/columns/active")
    const groupByObjects = this.dataGet("reportWindow/groupBy")

    let groupMap = new Map()

    groupByObjects.forEach((value, key) => {
        if(activeColumns.some(column => column.name === value.field)){
            groupMap.set(key, value)
        }
    })

    this.dataSet("reportWindow/groupBy", groupMap)
}