export default function homeLayoutSave(callback) {
  const data = this.dataGet("view").toJS();
  // console.log(data);

  this.setStorage("homeActiveTab", data.activeId);

  data.tabs.forEach((t, ti) => {
    t.cols.forEach((c, ci) => {
      c.rows.forEach((r, ri) => {
        if (!r.def) {
          return;
        }
        if (r.def.type == "view" || r.def.type == "calendar") {
          let path = data.tabs[ti].cols[ci].rows[ri].data;
          path.orderBy = path.viewData.orderBy ?? "";
          path.asc = path.viewData.asc ?? 0;
          path.viewData = "";
          path.dashletId = ti+"_"+ci+"_"+ri;
          this.dataSet("view/tabs/"+ti+"/cols/"+ci+"/rows/"+ri+"/data/viewData/dashletId",ti+"_"+ci+"_"+ri)
        }
      });
    });
  });

  this.rest.post("home/savedashboard", data, function(data) {
    if(callback){
      callback();
    }
  });
  
  // uprava sirky sloupcu v listview kvuli asynchroncite...
  let times = [100, 1100];
  for (let time of times) {
    setTimeout(() => {
      this.afterLoadListView("view", undefined, "widget");
    }, time);
  }
}
