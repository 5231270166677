export default function getSubCatFromTree(children,id,add = false,ids){
    if(id == ""){
        return;
    }
    children.forEach(child => {
        if(child.id == id || add == true){
            ids.push(child.id);
            this.getSubCatFromTree(child.children,id,true,ids);
        }else{
            this.getSubCatFromTree(child.children,id,false,ids);
        }
    });
}