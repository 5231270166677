export default function filesXhr(data) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", this.param.files + data.action);
  xhr.setRequestHeader("sID", this.rest.getCookie("sID"));
  xhr.responseType = "json";
  xhr.upload.onprogress = e => {
    const loaded = e.loaded;
    const total = e.total;
    const percent = (loaded / total)*100;
    if(data.progress !== undefined){
        data.progress({loaded,total,percent});
    }
};
  xhr.onreadystatechange = rData => {
      if(xhr.readyState == 2) {
        if (xhr.status != 200) {
          xhr.responseType = "text";
        }
      }
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status == 200) {
            if(data.success !== undefined){
              data.success(xhr.response);
            }
        }else{
            if(data.error != undefined){
              data.error(xhr.response);
            }
        }
      }
    };
    xhr.send(this.objectToFormdata(data.data));
}
