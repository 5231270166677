export default function openOpportunity(params){

    this.dsClear();
    this.dsAdd("set", params.prefix+"/fields/sales_stage/value", "Qualification");
    this.dsAdd("set", params.prefix+"/changes/fields/sales_stage", "Qualification");
    this.dsProcess();

    //this.saveField(params);
    params.canSave = true;
    this.saveRecord(params);
}