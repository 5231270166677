/*
http://localhost:8080/#custom/?action=acceptDecline&module=Calls&record=136201f1-945e-4bc2-8179-46248d0cb1f0&contact_id=969eb1e5-cb36-1b63-e89e-46248ccc391f&accept_status=accept
 */

export default function routeCustom(){
  let url = window.location.hash;

  if (!/\?/.test(url)) return;

  url = url.replace(/.*\?/, "");

  url = JSON.parse(
    '{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function(key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );

  if (url.action === "acceptDecline") {
    // prijmuti nebo odmitnuti hovoru nebo schuzky pres odkaz v e-mailu a presmerovani na dany zaznam...

    this.rest.post(url.action, url, () => {
      this.dsProcess(); // init menu
      this.load();
      window.location.hash = "#detail/" + url.module + "/" + url.record;
    });
  } else if (url.action === 'mirrorLogin') {
    const d41 = (value) => value.matchAll(/(.{6})(.{3})(.{3})(.{3})(.{9})/g).next().value.slice(1)
      .map((item) => item.split('').reduce((a, i) => a * 41 + 'tQBRumJqHdaSACriksnMNFGxfvKbZLDcpXTUoVzhe'.indexOf(i), 0))
      .map((item, index) => item.toString(16).padStart([6, 3, 3, 3, 9][index], '0'))
      .join('-');
    this.rest.setCookie('sID', d41(url.sid), 1);
    this.rest.setCookie('mirrorSID', d41(url.mirrorSID), 1);
    window.location.hash = '#home';
  }
}