import  sAction  from "sAction";

export default function clearCalDetailStore(){

    sAction.dataSet("calendar/activeTab", "detailMeeting");
    
    var content = {
        id: "12", 
        module: "Users",
        actionButtons : []
      };

      var action = {
        type: "CALENDARCLEARDATA",
        content: {},
        function: sAction
      };
      
      
      sAction.dsClear();
      sAction.dsAddCustom(action);
      sAction.dsProcess();
    
}