import React from "react";
import moment from 'moment';
import PureComponent from "../../pure";
import Select from '../../formElements/Select';
import Button from "../../formElements/Button";

import  sAction  from "sAction";
import Loader from "../../loader";

export default class OppCreateQuoteFromTemplate extends PureComponent {
    constructor(props){
        super(props);

        let date_quote_from = new Date();
        let date_quote_expected_closed = new Date(moment().add(30, 'days').format("YYYY-MM-DD"));

        let febPom = "-02-28";
        if(moment().isLeapYear()){
            febPom = "-02-29";
        }

        const pomDate = new Date(moment().format("YYYY"+febPom));
        
        if(date_quote_from < pomDate && date_quote_expected_closed > pomDate){
            date_quote_expected_closed = pomDate;
        }
        

        this.state = {
            template: "",
            trans_period : 0,
            date_quote_from : date_quote_from,
            date_quote_expected_closed : date_quote_expected_closed,
            templates : [],
            loaded: false
        }
    }

    handleCreate(){
        const id = this.state.template;

        if(!id){
            sAction.error("Invalid data.");
            return;
        }

        const params = {action: "opp_create_quote_from_template", 
        record : this.props.data.get("id"), 
        params: {
            template : this.state.template,
        }};

        sAction.load();
        sAction.rest.post("customAction", params, function(resp) {
            if(resp.errCode == "OK"){
                sAction.href("#detail/Quotes/"+resp.record);       
            }
            else{
                sAction.error(resp.errMsg);
            }
            
        });

    }

    componentDidMount() {
        sAction.rest.post("getQuoteTemplates", {}, templates => {
            if (!templates || !templates.length) {
                sAction.popupHide();
                // Nemáte přiřazeny žádné aktivní šablony.
                sAction.error(sAction.translate("LBL_NO_QUOTE_TEMPLATES_FOUND", "Quotes"));
                return;
            }
            this.setState({ templates, loaded: true, template: templates[0].id });
        });
    }


    render(){
        if (!this.state.loaded) return <Loader />;

        const templates = this.state.templates;
        var templateOptions = [];
        templates.forEach(template =>{
            templateOptions.push({value: template.id, label: template.name});
        });

        return <div>
        <div className="viewActionPanelButtons">
            <Button className="hoverGreen" onClick={() => this.handleCreate()}>
                <div className={"icon-saveIcon calHeaderIcon"} ></div>
                {sAction.translate("LBL_CREATE_QUOTE", "Quotes")}
            </Button>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                <div className={"icon-close calHeaderIcon"} ></div>
                {sAction.translate("LBL_CANCEL_ACTION")}
            </Button>
        </div>
        <div className="templateCreatorFormContainer templateCreatorForm">
            <div className="templateCreatorFormRow">
                <div className="quoteTemplNameLbl">
                    {sAction.translate("LBL_TEMPLATE_NAME", "Quotes")}:
                </div>
                <Select 
                    open={false} 
                    name="template_name"
                    options={templateOptions} 
                    defaultValue={this.state.template}
                    onChange={(ev) => this.setState({template : ev.target.value})} 
                />
            </div>
        </div>
    </div>;
    }
}