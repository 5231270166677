export default function subppanelChangeRelate(newRecord,subpanel,prefix,way,deleted = 0){
    var recModule = subpanel.def.getIn(["rel","relName"]);
    let relid = newRecord.id; // kdyz se do subpanelu priradi zaznam v popup okne
    if(recModule == "Activities" || recModule == "History"){
        recModule = newRecord.record.module.toLowerCase();
        relid = newRecord.record.record;
    }
    if (deleted) {
        // kdyz se maze zaznam
        relid = newRecord.record.record;
    }
    if (!relid && typeof newRecord.record == "string") {
        relid = newRecord.record;
    }
    const parentData = this.dataGet(prefix);
    var data = {
        module: parentData.module,
        relname: recModule,
        id: parentData.id,
        relid,
        deleted: deleted,
    }
    this.load();
    this.rest.post("rels",data,returnData => {
        if(returnData.status == "ok"){
            const subData = {
                parentId : parentData.id,
                parentModule: parentData.module,
                module: subpanel.module,
                relName: data.relname,
                prefix: prefix,
                way:way,
            }
            this.reloadSubpanel(subData);
        }
        this.popupHide();
        this.route();
    });
}