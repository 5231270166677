export default function closeRequest(params) {
    if (params.status === 'Approved') {
        params.canSave = true;
        // this.saveRecord(params);
        this.popup('ApproveRequest', params);
    } else if (params.status === 'Rejected') {
        params.canSave = true;
        this.popup('RejectRequest', params);
    }
}
