import detailDefault from "../../detailDefault";
export default class detailacm_templateinvoicing extends detailDefault {
  load(sAction, data) {}

  newSubRecord(sAction, data) {}
  beforeSave(sAction, data) {
      console.log(sAction,data)
    if (data.prefix == "view" && sAction.dataGet("view/customData/customLines").has("lines") === false) {
      return this.noRowsErrorMsg(sAction);
    }
    return true;
  }

  noRowsErrorMsg(sAction) {
    sAction.alert(sAction.translate("LBL_NO_ROWS_MSG", "acm_invoices"));
    return false;
  }
}
