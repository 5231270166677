import React from "react";
import Switch from "@material-ui/core/Switch";
import PropTypes from 'prop-types';
export default class CheckBox extends React.PureComponent {
  constructor(props){
    super(props)

    this.input = React.createRef()
  }

  onKeyDown(e) {
    const keyCode = e.keyCode;
    if (keyCode === 13 && this.props.onChange !== undefined) {
      this.props.onChange(!this.props.defaultValue)
    } else if (this.props.onKeyDown !== undefined) {
      this.props.onKeyDown(e)
    }
  }
  componentDidMount() {
    const autoSetValue = this.props.autoSetValue;
    if (autoSetValue == true && this.props.onChange != undefined) {
      this.props.onChange(this.props.defaultValue)
    }
    this.input.current.nextSibling.addEventListener("click",this.change)
  }
  change = (event) => {
    if(this.props.onChange != undefined){
      this.props.onChange()
    }
  };

  render() {
    const defaultValue = parseInt(this.props.defaultValue);
    const readonly = this.props.readonly
    var checkClass = false;
    var className = "acmCheckBox off";
    if (defaultValue === 1) {
      checkClass = true;
      className = "acmCheckBox";
    }

    if (this.props.className != undefined) {
      className += " " + this.props.className
    }
    const handleChange = (event) => {
      
    };
    return (
      <div>
     <Switch
        disabled={readonly == true ? true : false}
        className={className}
        checked={checkClass}
        inputRef={this.input}
        onChange={() =>
            this.props.onChange != undefined &&
            this.props.onChange(!this.props.defaultValue)
          }
      />
        <input type="hidden" id={this.props.name} defaultValue={defaultValue} />
      </div>
    );
  }
}
CheckBox.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
};
