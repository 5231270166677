export default function rowResizeStop() {
    if (this.activeResizeRow) {
        const view = this.getViewName()
        const module = this.dataGet("view/modul")
        const way = view + module
        if (module && view) {
            let filterData = this.getStorage('listFilterData')

            if (!filterData[way]['widthInfo']) {
                filterData[way]['widthInfo'] = []
            }

            const data = {
                'tableIndex': this.index,
                'minWidth': this.resizeRows[0].style.minWidth,
                'maxWidth': this.resizeRows[0].style.maxWidth
            }

            //pokud uz se resizuje stejny sloupec musi se najit v poli a prepsat hodnoty
            const alreadySet = filterData[way]['widthInfo'].find((widthData, i) => {
                if (widthData['tableIndex'] === this.index) {
                    filterData[way]['widthInfo'][i] = data;
                    return true;
                }
            });

            if (!alreadySet) {
                filterData[way]['widthInfo'].push(data)
            }

            this.setStorage('listFilterData', filterData)
        }
        this.activeResizeRow = false;

    }
}