export default function saveCellFieldByPropsWithDefaultValue(props, value, defaultValue) {
	const way = props.way;
	const name = props.data.name;
	const module = props.data.editModule;
	const id = props.data.recordId;
	if (defaultValue != value) {
		this.saveCellField({ way, value, name, module, id });
	} else {
		this.blurCellField({ way, id });
	}
}