import Driver from 'driver.js';
export default function openGuide(guide = null){

    if(guide == null){
        guide = this.getViewName().replace("#","")+"_"+this.getModuleName().replace("#","");
    }
    const params = {
        guide: guide
    }

    this.rest.post("Base/getGuide",params,ret => {
        if(ret.state == true){
            ret.data.forEach((d,i) => {
                d.popover.title = this.translate(d.popover.title,"guideDriver");
                d.popover.description = this.translate(d.popover.description,"guideDriver");
            })
            const driver = new Driver({
                allowClose: false,
                animate: false,
                padding:2,
            });
            driver.defineSteps(ret.data)
            driver.start();
        }else{
            this.alert(this.translate("LBL_FOR_THIS_PAGE_IS_NOT_GUIDE","guideDriver"));
        }
        
    },200)
}