export default function addImportColumn(paramData) {
  const way = paramData.way;
  const index = paramData.index;

  let relates = this.dataGet(way);
  let mappedRelates = [];
  let mappedRelate = relates.get(index);
  if (!mappedRelate) {
    return;
  }
  mappedRelate = mappedRelate.set("visible", true);
  mappedRelate = mappedRelate.set("way", way);
  relates = relates.set(index, mappedRelate);

  relates.forEach((relate) => {
    mappedRelates.push(relate.get("field"));
  });
  this.dsClear();
  this.dsAdd("set", way, relates);
  this.dsAdd("set", "view/mappedRelates", mappedRelates);
  this.dsProcess();
}
