//productSelector
import React from "react";
import PureComponent from "../../pure";

import ListViewTable2 from '../../list/listViewTable2'

import  sAction  from "sAction";

export default class ProductSelectorList extends PureComponent {


    render(){

        const data = this.props.data;

        return (<div className="prodSelectorListContainer">
                <ListViewTable2 data={data} parent={this}  prefix="rightPanel/data/listView" />
            </div>);
    }

}