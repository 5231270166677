import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";
import AcmDateTime from "../../formElements/AcmDatetime";
import Relate from "../../formElements/Relate";
import Select from "../../formElements/Select";
import Parent from "../../formElements/Parent";
import RadioGroup from "../../formElements/RadioGroup";

export default class CreateMultipleActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userId: '',
            startDate: null,
            userIdEmpty: false,
            startDateEmpty: false,
            perDayEmpty: false,
            timeBetween: false,
            nameEmpty: false,
            activity: false,
            descriptionEmpty: false,
    
            parentModule: '',
            parentName: '',
            parentId: '',
            parentType: "individual"
        };
        // this.user = React.createRef();
        this.perDay = React.createRef();
        this.timeBetween = React.createRef();
        this.startDate = React.createRef();
        this.name = React.createRef();
        this.activity = React.createRef();
        this.description = React.createRef();
    }

    setRelate(item) {
        this.setState({userName: item.name, userId: item.id});
        // sAction.dataSet('conf/popup/data/name', item.name);
    }
    
    setParent(item) {
        this.setState({parentModule: item.module, parentName: item.name, parentId: item.id});
    }

    createActivity(e) {
        if (this.state.userId && this.state.startDate && this.perDay.current.value && this.timeBetween.current.value && this.name.current.value && this.description.current.value && this.activity.current.value) {
            const prefix = "view";

            var filterData = null;
            
            let parentModule;
            if (this.state.parentType === 'individual') {
                parentModule = this.props.data.get('module');
            } else {
                parentModule = this.state.parentModule;
            }
            
            const params = {
                user: this.state.userId,
                perDay: this.perDay.current.value,
                timeBetween: this.timeBetween.current.value,
                startDate: this.state.startDate,
                parentModule,
                parentId: this.state.parentId,
                parentType: this.state.parentType,
                recordID: this.props.data.get('id'),
                name: this.name.current.value,
                description: this.description.current.value,
                activity: this.activity.current.value,
                filter: filterData
            };
            sAction.createMultipleActivitiesSave(params);
        }

        let error = {};
        this.state.userId ? error.userIdEmpty = false : error.userIdEmpty = true;
        this.state.startDate ? error.startDateEmpty = false : error.startDateEmpty = true;
        this.perDay.current.value ? error.perDayEmpty = false : error.perDayEmpty = true;
        this.timeBetween.current.value ? error.timeBetweenEmpty = false : error.timeBetweenEmpty = true;
        this.name.current.value ? error.nameEmpty = false : error.nameEmpty = true;
        this.activity.current.value ? error.activityEmpty = false : error.activityEmpty = true;
        this.description.current.value ? error.descriptionEmpty = false : error.descriptionEmpty = true;
        this.setState(error);
    }

    setDate(date) {
        if (date && date !== 'NaN-NaN-NaN NaN:NaN:00') {
            this.state.startDate = date;
        }
    }

    closePopup() {
        sAction.popupHide();
        this.props.close();
    }

    render() {
        let message = undefined;
        const userName = this.state.userName;
        const userId = this.state.userId;
        let options = [
            {
                label: sAction.translate('LBL_MODULE_NAME', 'Calls'),
                value: 'Calls',
            },
            {
                label: sAction.translate('LBL_MODULE_NAME', 'Meetings'),
                value: 'Meetings',
            },
            {
                label: sAction.translate('LBL_MODULE_NAME', 'Tasks'),
                value: 'Tasks',
            },
        ];

        return (
            <React.Fragment>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_CREATE_MULTIPLE_ACTIVITIES')}</div>
                </div>
                <div className="">
                    {message !== undefined && (
                        <div className="tagMessage">{sAction.translate(message)}</div>
                    )}
                    <div className="multipleCallsTable">
                        {/*Vyber aktivity*/}
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_ACTIVITY')}</div>
                            <div className="mcCell">
                                <Select
                                    myRef={this.activity}
                                    error={this.state.activityEmpty}
                                    // onChange={(e) => this.setlanguage(e.target.value)}
                                    options={options}
                                />
                            </div>
                        </div>
                        {/*Nazev aktivity*/}
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_NAME')}</div>
                            <div className="mcCell">
                                <InputText
                                    myRef={this.name}
                                    error={this.state.nameEmpty}
                                />
                            </div>
                        </div>
                        {/*Komu je pridelena*/}
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_ASSIGNED_USER')}</div>
                            <div className="mcCell">
                                <Relate
                                    newRecord={false}
                                    module='Users'
                                    data={{
                                        value: userName,
                                        id_value: userId
                                    }}
                                    error={this.state.userIdEmpty}
                                    buttons={[]}
                                    callback={(item) => this.setRelate(item)}
                                    key={userId}
                                />
                            </div>
                        </div>
                        {/*Tyka se*/}
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_PARENT')}</div>
                            <div className="mcCell">
                                <div>
                                    <RadioGroup
                                        value={this.state.parentType}
                                        options={{
                                            "individual": sAction.translate('LBL_MULTIPLE_ACTIVITY_PARENT_INDIVIDUAL'),
                                            "specific": sAction.translate('LBL_MULTIPLE_ACTIVITY_PARENT_SPECIFIC'),
                                        }}
                                        onChange={(e) => this.setState({ parentType: e.target.value })}
                                        style={{ padding: "10px 0" }}
                                    />
                                    {this.state.parentType === "specific" && (
                                    <Parent
                                        newRecord={false}
                                        module="Calls" // pro ziskani seznam modulu
                                        data={{
                                            module: this.state.parentModule,
                                            name: this.state.parentName,
                                            id: this.state.parentId
                                        }}
                                        error={this.state.parentEmpty}
                                        buttons={[]}
                                        callback={(item) => this.setParent(item)}
                                    />)}
                                </div>
                            </div>
                        </div>

                        {/*Popis*/}
                        <div className="mcRow" style={{height: '8' +
                                '5px'}}>
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_DESCRIPTION')}</div>
                            <div className="mcCell" style={{alignItems: 'center'}}>
                                <textarea
                                    style={{height: '60px', width: '100%'}}
                                    ref={this.description}
                                    className={this.state.descriptionEmpty ? 'error' : ''}
                                />
                            </div>
                        </div>
                        {/*Datum a cas zacatku*/}
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_TIME_BETWEEN')}</div>
                            <div className="mcCell">
                                <InputText
                                    type='number'
                                    myRef={this.timeBetween}
                                    defaultValue='15'
                                    error={this.state.timeBetweenEmpty}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_MAX_PER_DAY')}</div>
                            <div className="mcCell">
                                <InputText
                                    type='number'
                                    myRef={this.perDay}
                                    error={this.state.perDayEmpty}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_ACTIVITY_FIRST_DATETIME')}</div>
                            <div className="mcCell">
                                <AcmDateTime
                                    myRef={this.startDate}
                                    key={this.state.startDate}
                                    onChange={e => this.setDate(e)}
                                    // onBlur={e => this.setDate(e)}
                                    value={this.state.startDate}
                                    error={this.state.startDateEmpty}
                                    // className={'error'}
                                    // error={true}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell"></div>
                            <div className="mcCell mcButtons viewActionPanelButtons">
                                <Button
                                    className='popupCloseButton'
                                    onClick={e => this.closePopup()}
                                >
                                    <div className="actionPanelButtonIcon icon-closeIconLight" />
                                    {sAction.translate('LBL_EXPORT_CLOSE')}
                                </Button>
                                <Button
                                    onClick={e => this.createActivity(e)}
                                >
                                    <div className="actionPanelButtonIcon icon-addIcon" />
                                    {sAction.translate('LBL_MULTIPLE_ACTIVITY_CREATE')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
