import DefaultFloat from '../../defaultFields/Float/Float'
import  sAction  from "sAction";

export default class Float extends DefaultFloat {

  saveField(data) {
    sAction.saveField(data);
  }
  
}
