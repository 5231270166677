import React from "react";
import  sAction  from "sAction";
export default class Timeline extends React.Component {
  constructor(props) {
    super(props);
  }

  onSelect(e,value){
    if(this.props.onSelect != undefined){
        this.props.onSelect(e,value);
    }
  }


  getTimelinePoint(data,state) {
    var clickable = this.props.clickable
    const value = this.props.value;
    
    var extraStyle = null;
    var extraStyleArrow = null;
    if(state == "colored" || state == "now"){
        extraStyle = {backgroundColor:value.color}
        extraStyleArrow={borderColor: "transparent transparent transparent "+value.color}
    }
    let title = data.title;
    if (sAction.getStorage("debug")) {
      title = !title ? data.value : data.value + " | " + title;
    }
    return (
      <div key={data.value} className="acmTimelinePointContainer">
        <div className="acmTimelinePoint">
          <div 
          className={clickable == true && data.clickAble == true ? "acmTimelinePointCenter clickable" : "acmTimelinePointCenter"}
          style={extraStyle} 
          onClick={(e) => this.onSelect(e,data.value)}
          title={title}
          >
          {data.label}
          </div>
        </div>
        <div className="acmTimeLineContainerArrow">
        
        </div>
        <div className="acmTimeLineArrow"
          style={extraStyleArrow}
        />
      </div>
    );
  }

  render() {
    const data = this.props.data;
    const value = this.props.value;

    var renderData = [];
    var pointState = "colored";
    data.forEach(point => {
      if (value.value == point.value) {
        pointState = "now";
      }
      renderData.push(this.getTimelinePoint(point,pointState));
      if (value.value == point.value) {
        pointState = "alreadySet";
      }
    });

    return <div className="acmTimeline">{renderData}</div>;
  }
}
