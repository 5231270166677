import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import CheckBox from "../../formElements/CheckBox";
import Button from "../../formElements/Button";
import Text from "../../formElements/Text";
import RichText from "../../formElements/RichText";

export default class ApproveRequest extends PureComponent {
    constructor(props) {
        super(props);

        this.input = {
            total_dealers_discount: React.createRef(),
            dealers_purchase_price: React.createRef(),
            maturity: React.createRef(),
            subsidies: React.createRef(),
            gso: React.createRef(),
            gso_total: React.createRef(),
            campaign_name: React.createRef(),
            campaign_grant: React.createRef(),
            description: React.createRef(),
        };
        this.state = {
            refueling: 0,
            performance_bonus: 0,
        }

    }

    componentDidMount() {
        let data = this.props.data.toJS();
        this.input.dealers_purchase_price.current.value = parseFloat(sAction.dataGet(data.prefix + '/fields/gross_price/value')).toFixed(2);
    }

    save() {
        let data = this.props.data.toJS();

        sAction.dsClear();
        this.input.forEachObject((field, key) => {
            sAction.dsAdd('set', data.prefix + '/fields/' + key + '/value', field.current.value);
            sAction.dsAdd('set', data.prefix + '/changes/fields/' + key, field.current.value);
        });
        this.state.forEachObject((value, key) => {
            sAction.dsAdd('set', data.prefix + '/fields/' + key + '/value', value);
            sAction.dsAdd('set', data.prefix + '/changes/fields/' + key, value);
        });
        sAction.dsAdd('set', data.prefix + '/fields/status/value', 'Approved');
        sAction.dsAdd('set', data.prefix + '/changes/fields/status', 'Approved');
        sAction.dsAdd('set', data.prefix + '/changes/fields/acm_discount_request_users_1users_ida', sAction.dataGet('conf/user/id'));
        sAction.dsAdd('set', data.prefix + '/changes/fields/relate', {acm_discount_request_users_1_name: sAction.dataGet('conf/user/name')});
        sAction.dsProcess();

        sAction.saveRecord(data);
        sAction.popupHide();
    }

    calculatePrice() {
        const data = this.props.data.toJS();
        // const total_dealers_discount = sAction.dataGet(data.prefix + '/fields/total_dealers_discount/value');
        // const campaign_grant = sAction.dataGet(data.prefix + '/fields/campaign_grant/value');
        const gross_price = sAction.dataGet(data.prefix + '/fields/gross_price/value');
        const total_dealers_discount = this.input.total_dealers_discount.current ? this.input.total_dealers_discount.current.value : 0;
        const campaign_grant = this.input.campaign_grant.current ? this.input.campaign_grant.current.value : 0;

        let value = gross_price * (1 - (total_dealers_discount / 100)) - campaign_grant;
        this.input.dealers_purchase_price.current.value = value;
        // sAction.dsClear();
        // sAction.dsAdd('set', data.prefix + '/fields/dealers_purchase_price/value', value);
        // sAction.dsAdd('set', data.prefix + '/changes/fields/dealers_purchase_price', value);
        // sAction.dsProcess();
    }

    render() {
        const data = this.props.data.toJS();
        const currencySymbol = '[' + sAction.currencies[sAction.dataGet(data.prefix + '/fields/currency_id/value')].symbol + ']';

        return (
            <React.Fragment>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_APPROVE_REQUEST', 'acm_discount_request')}</div>
                </div>
                <div className="exportListPopupContent">
                    <div className="simpleForm">
                        <table>
                            <tbody>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_TOTAL_DEALERS_DISCOUNT', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <InputText
                                        type='number'
                                        myRef={this.input.total_dealers_discount}
                                        className="withBorder"
                                        onChange={e => {this.calculatePrice();}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_DEALERS_PURCHASE_PRICE', 'acm_discount_request')}</td>
                                <td className='approveRequest'>
                                    <InputText
                                        type='number'
                                        myRef={this.input.dealers_purchase_price}
                                        className="withBorder"
                                    />
                                </td>
                                <td style={{paddingLeft: '0'}}>
                                    {currencySymbol}
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_REFUELING', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <CheckBox
                                        onChange={e =>
                                            e == true
                                                ? this.setState({ refueling: 1 })
                                                : this.setState({ refueling: 0 })
                                        }
                                        className="noHeight"
                                        defaultValue={this.state.refueling}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_MATURITY', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <InputText
                                        myRef={this.input.maturity}
                                        className="withBorder"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_PERFORMANCE_BONUS', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <CheckBox
                                        onChange={e =>
                                            e == true
                                                ? this.setState({ performance_bonus: 1 })
                                                : this.setState({ performance_bonus: 0 })
                                        }
                                        className="noHeight"
                                        defaultValue={this.state.performance_bonus}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_SUBSIDIES', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <InputText
                                        myRef={this.input.subsidies}
                                        className="withBorder"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_GSO', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <InputText
                                        type='number'
                                        myRef={this.input.gso}
                                        className="withBorder"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_GSO_TOTAL', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <InputText
                                        myRef={this.input.gso_total}
                                        className="withBorder"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_CAMPAIGN_NAME', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <InputText
                                        myRef={this.input.campaign_name}
                                        className="withBorder"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_CAMPAIGN_GRANT', 'acm_discount_request')}</td>
                                <td className='approveRequest'>
                                    <InputText
                                        type='number'
                                        myRef={this.input.campaign_grant}
                                        className="withBorder"
                                        onChange={e => {this.calculatePrice();}}
                                    />
                                </td>
                                <td style={{paddingLeft: '0'}}>
                                    {currencySymbol}
                                </td>
                            </tr>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_DESCRIPTION', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <textarea
                                        ref={this.input.description}
                                        className="withBorder"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="viewActionPanelButtons flexRight">
                                        <Button onClick={() => this.save()}>
                                            <div className={"actionPanelButtonIcon icon-approve"} />
                                            {sAction.translate('LBL_APPROVE_REQUEST', 'acm_discount_request')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}