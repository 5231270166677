export default function logIn(name, pass, language, way = null) {
  const data = {
    username: name,
    password: pass,
    language: language
  };
  this.load();
  this.rest.post("login", data, data => {
    if (data.sid != undefined) {
      this.rest.setCookie("sID", data.sid, 1);
      this.initData(data);
      this.dsProcess();
      this.checkNewNotifications();
      var view = this.getViewName();
      this.route(true, null, view == "login" ? "home" : null);
      return;
    } else if (data.status == "error") {
      this.unLoad();
    }
  });
  // mirror login
  fetch(`${this.param.mirror.rest}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json()).then((data) => {
    if (data.message?.data?.sid != null) {
      this.rest.setCookie('mirrorSID', data.message.data.sid, 1);
    }
  }).catch((error) => {
    console.error('Error:', error);
  });
}
