export default function clickToCall(prefix,value){

    const data = this.dataGet(prefix)

    const params = {
        value:value,
        module: data.get("module"),
        record: data.get("id"),
    }
    this.load();
    this.rest.post("clickToCall",params,ret => {
        if(ret.state){
            this.unLoad();
            this.popupDetail({module:"Calls",record:ret.call_id,saveCallback: data => {
                this.route();
            }})
        }else{
            this.unLoad();
            this.alert(this.translate(ret.message))
        }
    })
}