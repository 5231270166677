export default function printTimelineConfirm(prefix) {
  
  const module = "TimeLine";
  const data = {
    
    header: this.translate("LBL_PRINT_TIMELINE", module),
    buttons: [
      {
        label: this.translate("LBL_BTN_PRINT_TIMELINE_ALL", module),
        callback: () => {
          // this.popupHide();
          this.printTimeline({ prefix, all: true });
        },
      },
      
      {
        label: this.translate("LBL_BTN_PRINT_TIMELINE_SHOWN", module),
        callback: () => {
          // this.popupHide();
          this.printTimeline({ prefix, all: false });
        },
      },
    ],
  };

  var message = this.translate("LBL_PRINT_TIMELINE_QUESTION", module);

  this.popup(message, data);
}
