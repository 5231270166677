export default function shareVehicle(params) {
    this.load();
    const self = this;
    this.rest.post('shareVehicle', {vehicleID: params.id,status: params.status}, (returnData) => {
        self.dsClear();
        self.dsAdd("set", params.prefix + '/fields/ss/def/selected', returnData.message.data);
        self.dsProcess();
        self.route();
    });
}
