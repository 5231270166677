export default function detailTabNext(data) {
  const shift = data.shift;
  var index = data.index;
  const rowWay = data.rowWay;
  const rowWayExplode = rowWay.split("/");
  const rowIndex = rowWayExplode[rowWayExplode.length - 1];
  rowWayExplode.splice(rowWayExplode.length - 1, 1);
  const rowsWay = rowWayExplode.join("/");

  const rowsData = this.dataGet(rowsWay);

  var newField = null;
  newField = getNextField(rowsData, rowIndex, index, shift,data.prefix,this);


  if (newField != null) {
    if (this.detailTempData[data.prefix] === undefined) {
      this.detailTempData[data.prefix] = {
        activeField: newField
      };
    } else {
      this.detailTempData[data.prefix]["activeField"] = newField;
    }
    this.dsClear();
    if (data.error !== true) {
      this.dsAdd(
        "set",
        data.prefix + "/fields/" + data.name + "/actEdit",
        false
      );
    }
    this.dsAdd("set", data.prefix + "/fields/" + newField + "/actEdit", true);

    const newType = this.dataGet(
      data.prefix + "/fields/" + newField + "/def/type"
    );
    if (
      newType === "enum" ||
      newType === "currency_id" ||
      newType === "multienum"
    ) {
      this.dsAdd(
        "set",
        data.prefix + "/fields/" + newField + "/def/forceOpen",
        false
      );
    }

    this.dsProcess();
  }
}

function getNextField(rowsData, rowIndex, index, shift,prefix,sAction) {
  var newIndex = index;
  var nextRow = parseInt(rowIndex);
  if (shift === true) {
    nextRow -= 1;
  } else {
    nextRow += 1;
  }

  if (nextRow > rowsData.size - 1) {
    nextRow = 0;

    var maxRow = 0;
    rowsData.forEach(row => {
      if (row.size > maxRow) {
        maxRow = row.size;
      }
    });
    maxRow -= 1;
    if (newIndex < maxRow) {
      newIndex += 1;
    } else if (newIndex === maxRow) {
      newIndex = 0;
    }
  } else if (nextRow < 0) {
    nextRow = rowsData.size - 1;

    var maxRow = 0;
    rowsData.forEach(row => {
      if (row.size > maxRow) {
        maxRow = row.size;
      }
    });
    maxRow -= 1;

    if (newIndex === 0) {
      newIndex = maxRow;
    } else if (newIndex > 0) {
      newIndex -= 1;
    }
  }
  const newField = rowsData.getIn([nextRow, newIndex]);
  if (newField === undefined) {
    return getNextField(rowsData, nextRow, newIndex, shift,prefix,sAction);
  } else {

    var readonly = sAction.dataGet(prefix+"/fields/"+newField+"/def/readonly")
    if(readonly == true){
      return getNextField(rowsData, nextRow, newIndex, shift,prefix,sAction);
    }
    return newField;
  }
}
function getNextFieldAddress(data){
  var addressOrder= [
    'street',
    'city',
    'state',
    'kraj',
    'country',
  ]
 
    var  indexOf = null
    addressOrder.forEach((add,key) => {
      if(data.name.indexOf(add) != -1){
        indexOf = key;
      }
    })
    if(indexOf != null){
      if(data.shift !== true){
        if(indexOf == (addressOrder.length-1)){
          console.log("ted");
        }
      }
    }
}
