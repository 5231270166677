export default function closeRightPanel(){
    const way = this.dataGet('rightPanel/data/reloadList');
    const reloadOnClose = this.dataGet("rightPanel/reloadOnClose");

    if(way && reloadOnClose){
        this.reloadList(0, 1, way)
    } else if (!way && reloadOnClose){
        this.route()
    }

    this.dataSet("rightPanel", {}, "RightPanel");
}
