import React, { PureComponent } from "react"
import  sAction  from "sAction";

import Text from "../formElements/InputText"

export default class EmailSearchSimple extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {reset: false};
    this.input = React.createRef();
  }

  toggleAdvancedSearch = () => {
    sAction.dataSet(
      this.props.way+"/advancedSearch",
      !sAction.dataGet(this.props.way+"/advancedSearch")
    );
  };

  //vyhledavani v emailech
  search = (e = null) => {
    const timeout = e === null ? 0 : 1000;
    const value = e ? e.target.value : this.input.current.value;

    const {reset} = this.state;
    if (value && !reset) {
      this.setState({reset: !reset})
    } else if (!value && reset) {
      this.setState({reset: !reset})
    }

    let newSearch = {
      emailUIAction: "searchAdvanced",
      id: "search",
      simple_search: true,
      body_text: value,
      page: 1,
      start: 0
    };

    if (!this.props.search.isImap) newSearch.mbox = "Search Results";

    newSearch = this.props.search.merge(newSearch);

    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      sAction.emailGetList(newSearch, this.props.way);
    }, timeout);
  };

  resetSearch = () => {
    this.input.current.value = "";
    this.search({target: {value: ""}});
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  render() {
    return (
      <div className="listViewSearch">
        <div
          onClick={() => this.search()}
          className="icon-search listViewSearchIcon"
        />
        <Text
          myRef={this.input}
          onKeyUp={event => event.keyCode == 13 && this.search()}
          placeholder={sAction.translate("LBL_EMAIL_SEARCHING")}
          autoFocus={true}
          onChange={this.search}
        />
        <div className="email__header__search__reset">
          {this.state.reset && (
            <div className="icon-detailCancel" onClick={this.resetSearch}></div>
          )}
        </div>
        <div
          tabIndex={0}
          className="listViewSearchButton"
          onClick={() => this.search()}
        >
          {sAction.translate("LBL_LIST_SEARCH_GLOBAL")}
        </div>
        <div
          tabIndex={0}
          className="listViewSearchButton"
          onClick={this.toggleAdvancedSearch}
        >
          {/*Rozšířené*/}
          {sAction.translate("LBL_EMAIL_ADVANCED_SEARCH","Emails")}
        </div>
      </div>
    );
  }
}
