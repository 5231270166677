export default function custom(type, data) {
  data.view = this.getViewName();

  var module = this.getModuleName();
  if (data.module != undefined && data.module != null) {
    module = data.module;
  }
  const detailCustom = this.customClassPole["detail" + module];
  if (detailCustom != undefined) {
    this.customClass = new detailCustom();
  } else {
    this.customClass = null;
  }
  this.dataSet(data.prefix+"/doCustom",false);
  if (this.customClass != null) {
    if (type == "load") {
      this.customClass.load(this, data);
    } else if (type == "update") {
      this.customClass.update(this, data);
    } else if (type == "beforeSave") {
      return this.customClass.beforeSave(this, data);
    } else if(type == "newSubRecord"){
      return this.customClass.newSubRecord(this,data);
    }else{
      console.log("nespecifikovaný hook "+type);
    }
  }
  return true;
}
