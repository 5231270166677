import React from "react";
import PureComponent from "../pure";

import Tree from "../Tree/Tree";

import  sAction  from "sAction";

export default class ListViewTableTree extends PureComponent {
  change(id) {
    const data = this.props.data;
    const way = this.props.way;

    var ids = [];
    sAction.getSubCatFromTree(data.tree, id, false, ids);

    if(ids.length == 0){
      ids = null;
    }
    sAction.dataSet(way+"/listViewTree/activeCategory",id);
    sAction.dataSet(way + "/defaultFilter/tree/parentId", ids);
    sAction.reloadList(0, 1, way);
  }

  render() {
    const data = this.props.data;
    return (
      <div className="listViewTableTree">
        <Tree
          header={sAction.translate("LBL_TREE_CATEGORY")}
          data={data.tree.toJS()}
          onSelectGroup={id => this.change(id)}
          openLevel={1}
        />
      </div>
    );
  }
}
