export default function saveSubpanelOrder(way) {
  const data = this.dataGet(way);
  const module = data.module;
  const subpanels = data.subpanels;

  var subpanelsNewOrder = {};
  subpanels.forEach((subpanel, key) => {
    subpanelsNewOrder[subpanel.module] = {
        order: key,
        open: subpanel.def.get("open"),
    };
  });

  var newData = {
    category: module+"_subpanel",
    contents: {
      all: subpanelsNewOrder
    }
  };
  this.load();
  this.rest.post("userPrfUpdate", newData, returnData => {
    if(returnData.state === true){
        this.unLoad();
    }
   
  });
}
