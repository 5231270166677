import React from "react";
import PureComponent from "../../pure";

// import InputText from "../formElements/InputText";

import  sAction  from "sAction";

import DialogContent from "@material-ui/core/DialogContent";

export default class ChooseAddressPopup extends PureComponent {
  constructor(props) {
    super(props);
  }

  //ulozeni zmen v popupu
  savePopup = e => {
    sAction.popupHide();

    const data = this.props.data;

    //address
    const a = data.get("data").get(e.target.dataset.value);

    const type = data.get("type"); // [billing|shipping|primary]_adress

    let street = a.get("ulice");
    if (street) {
      const landmarkNumber = "/" + a.get("orientační_číslo") || "";
      street += " " + (a.get("č.p.") || a.get("č.ev.")) + landmarkNumber;
    }

    const callbackData = {};
    callbackData[type + "_street"] = street;
    callbackData[type + "_postalcode"] = a.get("PSČ");
    callbackData[type + "_city"] = a.get("obec");

    //updateFields
    this.props.data.get("callback")(callbackData);
  };

  render() {
    const adresses = this.props.data.get("data");

    return (
      <div className="homeSetViewContainer">
        <div className="acmPopupHeader">Vyberte</div>
        <DialogContent>
          {adresses.entrySeq().map(e => {
            const v = e[1];
            const cityPart = v.get("část_obce");
            const street = v.get("ulice");
            const landmarkNumber = v.get("orientační_číslo");
            return (
              <div className="detailApi__accounts" key={e[0]} data-value={e[0]} onClick={this.savePopup}>
                {v.get("obec")}, {!!cityPart && cityPart + ", "}
                {!!street && street + " "}
                {v.get("č.p.") || v.get("č.ev.")}
                {!!landmarkNumber && "/" + landmarkNumber},{" "}
                {v.get("PSČ").replace(/(..)$/," $1")}
              </div>
            );
          })}
        </DialogContent>
      </div>
    );
  }
}
