import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";

export default class OldRecordContainer extends PureComponent {
  constructor() {
    super();
    this.state = {
      open: true
    };
    this.content = React.createRef();
  }
  componentDidMount() {
    const body = this.content.current;
    const index = this.props.index;
    body.addEventListener("scroll", e => {
      var scrollValue = this.content.current.scrollTop;
      const bodies = document.querySelectorAll(".oldMergeBody");
      bodies.forEach(body => {
        if (body.dataset.index != index && !body.classList.contains("active")) {
          body.scrollTop = scrollValue;
        }
      });
    });
  }
  mouseEnter() {
    var bodies = document.querySelectorAll(".oldMergeBody.active");
    bodies.forEach(body => {
      body.classList.remove("active");
    });

    this.content.current.classList.add("active");
  }
  mouseLeave() {
    this.content.current.classList.remove("active");
  }
  componentDidUpdate() {}

  addToNewRecord(field, def) {
    const data = this.props.data;
    const value = data.get(field);
    const way = this.props.way;

    var paramData = [{ way: way, field: field, value: value }];

    if (def.get("type") == "relate" && def.get("source") == "non-db") {
      paramData.push({
        way: way,
        field: def.get("id_name"),
        value: data.get(def.get("id_name"))
      });
    }
    sAction.mergeAddField(paramData);
  }

  render() {
    const data = this.props.data;
    const def = this.props.def;
    const module = this.props.module;
    const index = this.props.index;
    const way = this.props.way;
    var linesRender = [];
    def.entrySeq().forEach(e => {
      const value = data.get(e[0]);

      linesRender.push(
        <div key={e[0]} className="mergeRecordFieldLine">
          <div className="mergeRecordText">
            <div className="mergeRecordFieldHeader">
              {sAction.translate(e[1].get("vname"), module)}
            </div>
            <div className="mergeRecordFieldValue">
              {value == undefined || value == "" || typeof value == "object"
                ? "..."
                : data.get(e[0])}
            </div>
          </div>
          <div className="mergeRecordButtons">
            <div
              className="icon-arrowRight mergeAddFieldButton"
              onClick={() => this.addToNewRecord(e[0], e[1])}
            />
          </div>
        </div>
      );
    });

    return (
      <div
        className={
          this.state.open == true
            ? "recordContainer old"
            : "recordContainer recordContainerClose old"
        }
      >
        <div className="mergeRecordHeader">
          <div
            className="mergeRecordHeaderText"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <div className={this.state.open == true ? "icon-subpanelClose" : "icon-subpanelOpen"}></div>
            {data.get("name")}
          </div>
          <div className="mergeRecordHeaderButtons">
            <div
              className="icon-arrowRight mergeAddFieldButton"
              onClick={() => sAction.mergeSetAsDefault(way, index)}
            />
          </div>
        </div>
        <div
          style={this.state.open == true ? null : { display: "none" }}
          className="mergeRecordBody oldMergeBody"
          data-index={index}
          ref={this.content}
          onMouseEnter={() => this.mouseEnter()}
          onMouseLeave={() => this.mouseLeave()}
        >
          {linesRender}
        </div>
      </div>
    );
  }
}
