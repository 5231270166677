export default function getEnumForSelect(name) {
    var selectoptions = [];
      try{
        selectoptions = this.app_strings[name];
      }catch(e){
        console.log("Chybí číselník "+name);
      }
      if(selectoptions == undefined) {
        console.log("Chybí číselník" + name);
        console.log(this.app_strings[name]);
        return [];
      }

      var optionsRender = [];
      selectoptions.forEachObject((value,key) => {
          optionsRender.push({value:key,label:value})
      })
      return optionsRender;
}