export default function setStorage(key,paramValue){
    
    var value = null;
    if(typeof paramValue == "object" && paramValue != null){
        value = JSON.stringify(paramValue);
    }else{
        value = paramValue;
    }
    if (window.localStorage) {
        localStorage.setItem(key,value);   
    }else{
        this.rest.setCookie(key,value,1);
    }
}