export default function customReportXHR(url,data,callback){
    var xhr = new XMLHttpRequest();
    let query = window.location.hash.replace(/.*\?/,"");
    xhr.open("POST", this.param.reports + url);
    // xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    // xhr.withCredentials = true;
    xhr.setRequestHeader("sID", this.rest.getCookie("sID"));
    xhr.onreadystatechange = data => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
            callback(xhr.response);
        }
      }
    };
    if (data != null) {
      let param = "";
      data.forEachObject((val, key) => {
        if (val == null) return;
        if(Array.isArray(val)){
          val.forEach( valP => {
            param += "&"+key+"="+valP;
          });
        }
        else{
          param += "&"+key+"="+val;
        }
      });
      if (query) param = query + "&" + param;
      xhr.send(param);
    } else {
      xhr.send();
    }
}