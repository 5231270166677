export default function reportWindowDeleteSearch(id){
    if(id != ""){
        var data = this.dataGet("reportWindow");
        data = data.toJS();
        var url = data.module+"/"+id

        this.load();
        this.rest.delete("search/"+url, {},returnData => {
            if(returnData.status === "ok"){
                this.popupHide();
            }
            this.unLoad();
        });
    }
}