export default function reloadSubpanelByWay(way, module) {
  const activitiesModules = ["Calls", "Meetings", "Tasks", "Notes"];

  var subModule = module;
  if (activitiesModules.includes(module)) {
    if (subModule == "Notes") {
      subModule = "History";
    } else {
      subModule = "Activities";
    }
  }
  const subpanels = this.dataGet(way + "/subpanels");

  var subpanelIndex = [];
  subpanels.forEach((subpanel, index) => {
    if (subModule == subpanel.module) {
      subpanelIndex.push(index);
    }
  });
  if (subpanelIndex.length != 0) {
    subpanelIndex.forEach(index => {
      const subWay = way + "/subpanels/" + index + "/rows";
      this.reloadList(0, 1, subWay); 
    })
  }else{
    this.unLoad();
  }
}
