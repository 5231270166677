export default function ACLRolesDuplicate(attrs){
  this.load();
  const params = {action: "aclroles_duplicate", record : attrs.id};
  //var self = this;

  this.rest.post("customAction", params, (resp) => {
    if(resp.errCode == "OK"){
      this.href("#detail/ACLRoles/"+resp.record);
    }
    else{
      this.unLoad();
      this.alert(resp.errMsg);
    }
  });
}