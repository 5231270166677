import React from 'react'
import MaterialDefault from './MaterialDefault'
import MaterialTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default class TabContainer extends MaterialDefault {
    constructor(props){
        super(props)
    }
    render(){
        const attr = this.createAttr("acmTabContainer");
        delete attr['tabs'];
        
        var tabs = [];
        this.props.tabs.forEach((item,index) => {
            let className = item ? "acmTabContainerButton" : "hidden";
            tabs.push(<Tab key={index} label={item} data-index={index} className={className} />)
        })
        return(
            <MaterialTabs
            {...attr}
            >
            {tabs}
            </MaterialTabs>
        )
    }

}