import React from "react";
import PureComponent from "../../pure";

// import InputText from "../formElements/InputText";

import  sAction  from "sAction";

import DialogContent from "@material-ui/core/DialogContent";

export default class FinstatChooseAccountPopup extends PureComponent {
  constructor(props) {
    super(props);
  }

  //ulozeni zmen v popupu
  savePopup = (e) => {
    sAction.popupHide();

    let ico = e.target.dataset.value;
    if (!ico) ico = e.target.parentElement.dataset.value;
    console.log('call sAction.finstat(ico)')
    sAction.finstat({ ico });
  };

  render() {
    const accounts = this.props.data.get("accounts").valueSeq();

    return (
        <DialogContent>
          {accounts.map(v => {
            const ico = v.get("ico");
            return (
              <div
                key={ico}
                className="detailApi__accounts"
                data-value={ico}
                onClick={this.savePopup}
              >
                <strong>{v.get("name")}</strong> - {ico}
                <br />
                {v.get("address")}
              </div>
            );
          })}
        </DialogContent>
    );
  }
}
