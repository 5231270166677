import detailDefault from '../../detailDefault';
import $ from "jquery";
export default class detailacm_discount_request extends detailDefault{
    load(sAction,data){
        const status = sAction.dataGet(data.prefix + 'fields/status/value');
        if (status === 'Rejected' || status === 'Canceled' || status === 'Approved') {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/readonly', true);
            sAction.dsProcess();
        }

        $("#requested_extradiscount_percent").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
        $("#requested_extradiscount_percent").closest(".detailViewTabContentRow").css("display", "none");
        const type = sAction.dataGet(data.prefix + '/fields/discount_type/value');
        this.setType(type, data, sAction);
    }
    update(sAction,data) {
        if (data.field === 'status' && (data.value === 'Rejected' || data.value === 'Canceled' || data.value === 'Approved')) {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/readonly', true);
            sAction.dsProcess();
        } else if (data.field === 'total_dealers_discount' || data.field === 'campaign_grant') {
            const gross_price = sAction.dataGet(data.prefix + '/fields/gross_price/value');
            const total_dealers_discount = sAction.dataGet(data.prefix + '/fields/total_dealers_discount/value');
            const campaign_grant = sAction.dataGet(data.prefix + '/fields/campaign_grant/value');

            let value = gross_price * (1 - total_dealers_discount / 100) - campaign_grant;
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/dealers_purchase_price/value', value);
            sAction.dsAdd('set', data.prefix + '/changes/fields/dealers_purchase_price', value);
            sAction.dsProcess();
        } else if (data.field === 'discount_type') {
            let type = data.value;
            this.setType(type, data, sAction);
        }
    }

    setType(type, data, sAction) {
        let tabs = sAction.dataGet(data.prefix + '/tabs').toJS();
        tabs.forEach((tab, key) => {
            tab.rows.forEachObject((line, lineKey) => {
                line.forEachObject((field, fieldKey) => {
                    if (field === 'requested_extradiscount' && type == 1) {
                        line[fieldKey] = 'requested_extradiscount_percent';
                        sAction.dsClear();
                        sAction.dsAdd('set', data.prefix + '/tabs/' + key + '/rows/' + lineKey, line);
                        sAction.dsProcess();
                    } else if (field === 'requested_extradiscount_percent' && type == 0) {
                        line[fieldKey] = 'requested_extradiscount';
                        sAction.dsClear();
                        sAction.dsAdd('set', data.prefix + '/tabs/' + key + '/rows/' + lineKey, line);
                        sAction.dsProcess();
                    }
                });
            });
        });
        // if (type == 1) {
        //     $("#requested_extradiscount_percent").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
        //     $("#requested_extradiscount_percent").closest(".detailViewTabContentRow").css("display", "flex");
        //     $("#requested_extradiscount").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
        //     $("#requested_extradiscount").closest(".detailViewTabContentRow").css("display", "none");
        // } else {
        //     $("#requested_extradiscount_percent").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
        //     $("#requested_extradiscount_percent").closest(".detailViewTabContentRow").css("display", "none");
        //     $("#requested_extradiscount").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
        //     $("#requested_extradiscount").closest(".detailViewTabContentRow").css("display", "flex");
        // }
    }
}
