export default function editDetailChangeOptionIndex(index,direction,list){

    const max = list.size - 1
    var nextIndex = null;
    if(direction == "up"){
        nextIndex = index - 1
    }else if(direction == "down"){
        nextIndex = index + 1
    }
    
    if(nextIndex <= max && nextIndex > -1){

        const actLine = list.get(index)
        const secondLine = list.get(nextIndex)

        this.dsClear()
        this.dsAdd("set","conf/popup/data/optionList/"+index,secondLine)
        this.dsAdd("set","conf/popup/data/optionList/"+nextIndex,actLine)
        this.dsProcess()
    }
}