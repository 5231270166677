export default function mergeInit(params){

    const module = params.module;

    if(!this.hasAccess(module, "edit")){
        this.error(this.translate("LBL_ERROR_MERGE_NOT_PERMITTED", "ACL"));
        return;
    }

    const list = this.dataGet(params.prefix);
    var selected = null
    var filterData = null;
    
    if(list.selected === "all"){
        const savedSearch = list.actSavedSearch;
        filterData = this.reloadListParams({
            list,
            prefix: params.prefix,
            savedSearch,
            columns: false,
        });
        filterData["selected"] = list.selected;
        filterData["module"] = list.modul;
        filterData["type"] = list.type;
        selected = "all";
    }else{
        selected = list.selected.toJS();
    }
    

    const param= {
        records: selected,
        module: module,
        filter: filterData,
    }

    const paramsAcl = {
        "record" : selected,
        "module" : module,
        "filter" : filterData,
        "checkDelete" :true,
    }
    this.load();
    this.rest.post("hasAccess",paramsAcl,ret => {
        this.unLoad();

        if(ret){
            window.location.hash = "merge";
            this.rest.post("mergeInit",param,data => {
                this.dsClear();
                this.dsAdd("set","conf/load",false);
                this.dsAdd("set",params.prefix,{},"mergeRecords");
        
                data.def.forEachObject((def,key) => {
                    this.dsAdd("set",params.prefix+"/def/"+key,def);
                });
                var index = 0;
                data.records.forEachObject(record => {
                    this.dsAdd("set",params.prefix+"/records/"+index,{});
                    record.forEachObject((value,field) => {
                        this.dsAdd("set",params.prefix+"/records/"+index+"/"+field,value);
                    });
                    index += 1;
                })
                this.dsAdd("set",params.prefix+"/module",module);
                this.dsProcess();
            });
        }
        else{
            this.error(this.translate("LBL_ERROR_MERGE_NOT_PERMITTED", "ACL"));
        }

    });
}