export default function updateDetailCustom(type, params) {
    this.dsClear();
    if(type == "field"){
        this.dsAdd("set",params.way, params.value);
    }
    else if(type == "delItem"){
        this.dsAdd("delete", params.way, params.index);
    }
    else if(type == "prodName"){
        if(!params.template){
            this.dsAdd("set", params.way+"/name", params.item.name);
            this.dsAdd("set", params.way+"/id", null);
        }
        else{
            let data = params.item;
            data.product_template_id = data.id;
            data.id = "";
            this.dsAdd("set", params.way, data, "product");
        }
    }
    else if(type == "addProdLine"){
        var item = {id: "", name: ""};
        if(typeof(params.item) == "object"){
            item = params.item;
        }
        this.dsAdd("add", params.way, item, "product");
    }
    else if(type == "addProdGroup"){
          this.dsAdd("add", params.way, {
            id: "",
            name: "",
            type: "normal",
            description: "",
          }, "productGroup");
    }
    else if(type == "changeOppProd"){
        var total = 0;
        var pom = {quantity : 0, discount_price : 0};
        

        params.lines.forEach(line => {
            pom.quantity = parseFloat(line.quantity);
            pom.discount_price = parseFloat(line.discount_price);
            if(line.product_template_id == params.product_template_id){
                if(params.field !== "quantity" 
                && params.value != 0
                && pom.quantity == 0
                ){
                    this.dsAdd("set", params.way+"/quantity", 1);
                    pom.ks = 1;
                }
                pom[params.field] = params.value;
            }
            if(!isNaN(pom.discount_price)){
                total += pom.discount_price;
            }
            
        });

        this.dsAdd("set", params.way+"/"+params.field, params.value);
        this.dsAdd("set", "view/fields/"+params.totalFieldName+"/value", total);
        this.dsAdd("set", "view/changes/fields/"+params.totalFieldName, total);
    }
    else if(type == "addInvitee"){
        this.dsAdd("add", params.wayRel, params.value);
        this.dsAdd("delete", params.waySearch, params.index);
    }

    this.dsAdd("set","view/changes/forceChange", true);
    this.dsProcess();

}