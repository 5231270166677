//funkcni verze s materialem, ale pomala !!!
import React from "react";

import  sAction  from "sAction";
import Button from "../../formElements/Button";
import Select from "../../formElements/Select";
// import InputText from "../../formElements/InputText";

export default class SelectParams extends React.PureComponent {
  _isMounted = false;

  constructor() {
    super();
    this.paramVarPHP = React.createRef();
    this.state = {
      //selecty
      selectedField1: "",
      selectOptions1: [],
      selectOptions2: []
    };
  }

  //preda hodnotu v paramVarPHP parentovi
  addParam = () => {
    const param = this.paramVarPHP.current.value;
    if (!param) return false;

    this.props.onAdd(param);

    return true;
  };

  //ulozi hodnoty <select> <option> do state
  handleChange = event => {
    let { value, name } = event.target;

    this.setState({
      [name]: value
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  //přednačtou se data pro 1. select
  componentDidMount() {
    this._isMounted = true;

    sAction.load();
    sAction.rest.get("modulesSingular", modules => {
      sAction.unLoad();

      if (!this._isMounted) {
        return;
      }
      let selectOptions1 = [];
      modules.forEachObject((v, k) => {
        selectOptions1.push({
          label: sAction.app_strings.moduleList[k] || k,
          value: k+"|"+v
        })
      });
      selectOptions1.sort(this.sortByLabel);
      this.setState({ selectOptions1 });
    });
  }
  
  sortByLabel( a, b ) {
    const alabel = a && typeof a.label === "string" ? a.label : "";
    return alabel.localeCompare(b.label);
  }

  componentDidUpdate(prevProps, prevState) {
    //pokud se zmeni hodnota 1. selectu, nactou se data pro druhy select
    const { selectedField1 } = this.state;

    if (selectedField1 != prevState.selectedField1) {
      let selectOptions2 = [];

      sAction.load();

      sAction.rest.get("defs/" + selectedField1.split("|")[0], data => {
        let label; //preklad
        let value;
        data.forEachObject((field, key) => {
          if (!field) {
            return;
          }
          label = sAction.translate(field.vname || "", selectedField1.split("|")[0]);
          label = label != null ? label.replace(":", "", label) : "";
          value = field.name || "";

          selectOptions2.push({
            label,
            value
          });
        });

        this.setState({ selectOptions2 });
        if (this.paramVarPHP.current != null)
          this.paramVarPHP.current.value = "";
        // this.paramVarPHP.current.value =
        //   "{" + selectedField1 + "." + selectOptions2[0].value + "}";
        sAction.unLoad();
      });
    }
  }

  render() {
    let { selectedField1, selectOptions1, selectOptions2 } = this.state;

    if (!selectOptions1.length) return "";

    // console.log("selectParamsRender()");

    return (
      <div className="email__add-params">
        {/*Vložit parametr*/}
        <label>{sAction.translate("LBL_INSERT_VARIABLE","EmailTemplates")}</label>
        <div className="email__add-params__select">
          <Select
            defaultValue={selectedField1}
            options={selectOptions1}
            name="selectedField1"
            onChange={this.handleChange}
            // label="Zvolte modul"
            label={sAction.translate("LBL_CHOOSE_MODULE","EmailTemplates")}
          />
        </div>
        {/* Pokud byl zvolen 1. select, nabidne se druhy */}
        {selectOptions2.length > 0 && (
          <React.Fragment>
            <div className="email__add-params__select">
              <Select
                key={selectedField1}
                options={selectOptions2}
                //value=""
                defaultValue=""
                name="selectedField2"
                onChange={e =>
                  (this.paramVarPHP.current.value =
                    "$" + selectedField1.split("|")[1].toLowerCase() + "_" + e.target.value)
                }
                // label="Zvolte pole"
                label={sAction.translate("LBL_CHOOSE_FIELD","EmailTemplates")}
              />
            </div>
            <div className="email__add-params__select">
              <input value="" ref={this.paramVarPHP} readOnly />
            </div>
          </React.Fragment>
        )}

        <Button onClick={this.addParam} className="stillHover">
          {/*Vložit*/}
          {sAction.translate("LBL_INSERT","EmailTemplates")}
        </Button>
      </div>
    );
  }
}
