export default function addNewLineToTab(way,key = null){
    const lineData = ["empty_row","empty_row"]
    if(key == null){
        this.dataAdd(way+"/rows",lineData)
    }else{
        const rows = this.dataGet(way+"/rows").toJS()
        rows.splice(key, 0, lineData);
        this.dsClear()
        this.dsAdd("set",way+"/rows",[]);
        rows.forEach(row => {
            this.dsAdd("add",way+"/rows",row)
        })
        this.dsProcess();
    }
}