import React from "react";
import PureComponent from "../../pure";

import sAction from 'sAction';

import CustomLine from "./CustomLine";
import Button from "../../formElements/Button";
import CustomLinesHeader from "./CustomLinesHeader";
import CustomLinesTotal from "./CustomLinesTotal";

export default class CustomDetailLines extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { index: 0, customLines: [] };
        this.keyArray = {};
    }

    addCustomLine(def, moduleData) {
        var defaultData = {};
        def.get('fields').toJS().forEachObject((field) => {
            if (def.get('default') && def.get('default').get(field)) {
                defaultData[field] = def.get('default').get(field);
            } else if (moduleData.get(field) && moduleData.get(field).get && moduleData.get(field).get('default')) {
                defaultData[field] = moduleData.get(field).get('default')
            }
        });
        def.get('default').toJS().forEachObject((value, column) => {
            defaultData[column] = value;
        });
        sAction.dsClear();
        sAction.dsAdd("set", this.props.way + '/customData/customLines/lines/' + this.state.index, defaultData);
        sAction.dsAdd("set", this.props.way + '/changes/forceChange', true);
        sAction.dsProcess();
        this.setState({ index: ++this.state.index, });
    }

    componentWillMount() {
        const def = this.props.data.get('def');
        const info = this.props.data.get('info');
        const moduleData = this.props.data.get('moduleData');

        this.state.header = (
            <CustomLinesHeader
                def={def}
                moduleData={moduleData}
                info={info}
            />
        );
    }

    getTotal(moduleData, way, lines) {
        return (
            <CustomLinesTotal
                def={this.props.data.get('def')}
                way={way}
                lines={lines}
                moduleData={moduleData}
                info={this.props.data.get('info')}
            />
        );
    }

    moveElement(key, direction) {
        let way = this.props.way + '/customData/customLines/lines';

        let list = sAction.dataGet(way);
        const cnt = list.size;

        sAction.dsClear();

        if(direction === 'up'){
            if(key === 0 || key === '0'){
                return;
            }

            let pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key - 1], pomProd);

            sAction.dsAdd("set", way, list);
        } else if(direction === 'down'){
            if((key + 1) >= cnt){
                return;
            }

            let pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key + 1], pomProd);

            sAction.dsAdd("set", way, list);
        }

        sAction.dsAdd("set","view/changes/forceChange", true);
        sAction.dsProcess();
    }

    render() {
        const info = this.props.data.get('info');
        const way = this.props.way;
        const lines = this.props.data.get('lines');
        const def = this.props.data.get('def');
        const acl = this.props.acl;
        const moduleData = this.props.data.get('moduleData');
        const timeline = this.props.data.get('timeline');
        var index = 0;
        var customLines = [];
        if (lines) {
            lines.forEach((line, key) => {
                if (line.get && line.get('deleted') !== 1 && line.get('deleted') !== '1') {
                    this.keyArray[index] = key;
                    customLines.push(
                        <CustomLine
                            data={line}
                            def={def}
                            key={index}
                            keyV={index}
                            moduleData={moduleData}
                            showError={this.props.data.get('showError')}
                            way={way}
                            canEdit={acl.get('edit')}
                            timeline={timeline}
                            info={info}
                            moveElement={(key, direction) => {this.moveElement(key, direction)}}
                        />
                    );
                }
                index++;
            });
        }
        this.state.index = index;

        var totalLine = null;
        if (def.get('total') && def.get('total').toJS().length > 0) {
            totalLine = this.getTotal(moduleData, way, lines);
        }

        return (
            <div className='detailCard detailViewTabContent detailQuoteProdComponent'>
                <div className='subpanelsContainer' data-way={this.props.way + '/customData/customLines'}>
                    <div className="qGroupHeaderContainer header">
                        <div className="title">
                            <span className={"detailViewHeaderIcon icon-"} />
                            <span className="detailViewName">
                                {sAction.translate('LBL_MODULE_NAME', info.get('module'))}
                            </span>
                        </div>
                    </div>
                    <div className="detailView">
                        <div className="detailViewTabContent customLines">
                            {this.state.header}
                            {customLines}
                            {totalLine}
                            {acl.get('edit') && (
                                <div className='customLinesRowContainer flexRight viewActionPanelButtons'>
                                    <Button
                                        className="acmButton"
                                        onClick={e => this.addCustomLine(def, moduleData)}
                                    >
                                        <div className={"actionPanelButtonIcon icon-addIcon"} />
                                        {sAction.translate('LBL_ADD_CUSTOM_LINE')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
