export default function fieldToEdit(data) {
  this.dsClear();
  if (this.detailTempData[data.prefix] === undefined) {
    this.detailTempData[data.prefix] = {
      activeField: data.name
    };
  } else {
    const actField = this.detailTempData[data.prefix]["activeField"]
    if(actField !== undefined && actField !== null){
      this.dsAdd("set",data.prefix+"/fields/"+actField+"/actEdit",false)
    }
    this.detailTempData[data.prefix]["activeField"] = data.name
  }
  this.dsAdd("set", data.way + "/actEdit", true);
  if(data.type === "enum" || data.type==="currency_id" || data.type === "multienum"){
    this.dsAdd("set",data.way+"/def/forceOpen",true)
  }
  this.dsProcess();
}
