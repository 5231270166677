import InputField from "../inputFieldClass";
import  sAction  from "sAction";

export default class ApiPSC extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
      reg: /^[0-9]{3} ?[0-9]{2}$/
    };
  }

  check() {
    let ok = super.check();

    const value = this.getValue();

    if (ok && value && value!==this.props.data.value) {
      var slash = this.props.way.lastIndexOf("/")+1;
      var underscore = this.props.way.indexOf("_", slash);
      const fieldPrefix = this.props.way.substr(slash,(underscore-slash));
      sAction.checkPSC(value, this.props.prefix, fieldPrefix);
    }

    return ok;
  }
}
