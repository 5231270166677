import React, { Component } from "react";
import MaterialButton from '@material-ui/core/Fab'
export default class Button extends React.PureComponent{
   onClick(e){
       if(this.props.onClick != undefined){
           this.props.onClick(e);
       }
   }
   render(){
    const className = this.props.className
    
    return(
        <MaterialButton
        variant = {this.props.variant}
        onClick={(e) => this.onClick(e)}
        className={"acmButtonFloat "+className}>
            {this.props.children}
        </MaterialButton>
    )
    }
}