export default function toast(data){
    if(data.name == ""){
        console.log("chyby parameter 'name'");
        return;
    }
    if(data.description == ""){
        console.log("Chybí parameter 'description'")
        return;
    }
    this.dataAdd("conf/toast",data);
}