import detailDefault from "../../detailDefault";
export default class detailacm_invoices extends detailDefault {
    load(sAction,data) {
        if (data.prefix === "rightPanel/data/detail"){
            sAction.dataSet(data.prefix + "/fields/cena_dph_celkem/def/readonly", true)
            sAction.dataSet(data.prefix + "/fields/cena_bez_dph_celkem/def/readonly", true)
            sAction.dataSet(data.prefix + "/fields/cena_s_dph_celkem/def/readonly", true)
        }
    }
  
    newSubRecord(sAction, data) {
        const currency = sAction.dataGet(data.prefix+"/fields/currency_id/value");
        if(sAction.detailPredefinedFields == null){
            sAction.detailPredefinedFields = [];
        }
        sAction.detailPredefinedFields.push({
            type:"field",
            "name":"currency_id",
            "value": currency
        })
    }
    beforeSave(sAction, data) {
        if (
            data.prefix === 'view'
            && (!sAction.dataGet("view/customData/customLines")
                || sAction.dataGet("view/customData/customLines").has('lines') === false)
        ){
            return this.noRowsErrorMsg(sAction);
        }
        let lines = sAction.dataGet("view/customData/customLines/lines", null,true)
        let linLen = 0
        if (lines !== undefined){
            linLen = Object.keys(lines).length

            lines.forEachObject((data,key) => {
                if (data.deleted == 1){
                    linLen--;
                }
            })
        }

        if (lines !== undefined){
            if (Object.keys(lines).length === 0 || linLen === 0){
                this.noRowsErrorMsg(sAction);
            }
            else{
                return true;
            }
        }
        else{
            return true;
        }
    }

    noRowsErrorMsg(sAction){
        sAction.alert(sAction.translate("LBL_NO_ROWS_MSG", "acm_invoices"));
        return false;
    }
}
