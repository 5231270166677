import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import Button from "../../formElements/Button";
import CheckBox from "../../formElements/CheckBox";

import  sAction  from "sAction";

export default class ExportList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        includeId: 0,
        fieldFunctions: {},
        type: 'csv'
      };
    this.exportNameInput = React.createRef();
  }
  fieldFunction(value,key){
    const fieldFunctions = this.state.fieldFunctions
    fieldFunctions[key] = value

    this.setState({
      fieldFunctions:fieldFunctions,
    })
  }
  onCreate(){
    const name = this.exportNameInput.current.value;
    const type = this.state.type;
    const addId = this.state.includeId;
    const totalRow = this.state.fieldFunctions;
    const data = {
      name,
      type,
      addId,
      totalRow
    };
    if(this.props.onCreate !== undefined){
        this.props.onCreate(data);
    }
  }

  render() {
    var data = this.props.data;
    const selectData = sAction.getEnumForSelect("reportExportType")
    const functionDataNumber = sAction.getEnumForSelect("reportFunctionListNumber")
    const functionData = sAction.getEnumForSelect("reportFunctionList")

    var renderRows = [];
    const columns = this.props.columns;
    const numberFields = ["currency","double","float","int"];
    columns.forEach((column, index) => {
      const type = column.get("def").get("type");
      var options = functionDataNumber
      if(numberFields.indexOf(type) == -1){
        options = functionData
      }
      var columnName = sAction.translate(column.get("def").get("vname"),column.get("def").get("fieldModule"));
      console.log(column.get("def").get("vname"),column.get("def").get("fieldModule"))
      columnName = columnName.replace(/:\s*$/, "");
      renderRows.push(
        <tr key={index}>
          <td>
            {columnName}{' '}
            ({sAction.app_strings.moduleList[column.get("def").get("fieldModule")]}):
          </td>
          <td style={{width:"90px"}}>
            <Select
              onChange={e =>
                this.fieldFunction(
                  e.target.value,
                  column.get("def").get("fieldModule") + "." + column.get("name")
                )
              }
              containerClassName="withBorder"
              options={options}
            />
          </td>
        </tr>
      );
    });


    return (
        <div className="simpleForm">
          <table>
            <tbody>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_EXPORT_FILENAME")}</td>
                <td>
                  <InputText
                    myRef={this.exportNameInput}
                    className="withBorder"
                  />
                </td>
              </tr>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_EXPORT_TYPE")}</td>
                <td>
                  <Select
                    defaultValue="csv"
                    onChange={e => this.setState({ type: e.target.value })}
                    options={selectData}
                    containerClassName="withBorder"
                  />
                </td>
              </tr>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_EXPORT_INCLUDE_ID")}</td>
                <td>
                  <CheckBox
                    onChange={e =>
                      e == true
                        ? this.setState({ includeId: 1 })
                        : this.setState({ includeId: 0 })
                    }
                    className="noHeight"
                    defaultValue={this.state.includeId}
                  />
                </td>
              </tr>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_EXPORT_TOTAL_ROW")}</td>
                <td>
                  <table>
                    <tbody>{renderRows}</tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="viewActionPanelButtons flexRight">
                    <Button onClick={() => this.props.close()} className='popupCloseButton'>
                      <div className={"actionPanelButtonIcon icon-closeIconLight"} />
                      {sAction.translate("LBL_EXPORT_CLOSE")}
                    </Button>
                    <Button onClick={() => this.onCreate()}>
                      <div className={"actionPanelButtonIcon icon-download"} />
                      {sAction.translate("LBL_EXPORT_EXPORT")}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    );
  }
}
