
import detailDefault from "../../detailDefault";
export default class detailacm_CampaignsPartipants extends detailDefault{
    load(sAction,data){
    }
    update(sAction,data){
        //"Prirazeno" se zkopiruje do nazvu
        if(data.field === "acm_campaignspartipants_contactscontacts_ida"){
            sAction.saveField({way: data.prefix+"/fields/name", name: "name", value: data.value.name}, true);
        }
    }
}
