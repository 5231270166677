export default function createMultipleActivitiesSave(params) {
    this.load();
    this.rest.post('createMultiActivities', params, returnData => {
        this.unLoad();
        if (returnData['status']) {
            this.popupHide();
            this.alert(this.translate(returnData['message']));

            // echo ok
        } else {
            this.error(this.translate(returnData['message']));
        }
    });
}
