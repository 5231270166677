import React from 'react';
import moment from 'moment';

import PureComponent from '../pure';
import Button from "../formElements/Button";
import ButtonFloat from "../formElements/Button";
import ButtonCircle from "../formElements/ButtonFloat";
import CalSettingsForm from './calSettingsForm'

import  sAction  from "sAction";

class CalHeader extends PureComponent{
    constructor (props) {
      super(props);
      this.state = {
        startDate: moment(),
        cssSelector: this.props.calCssSelector,
        openPicker: false
      };
      
    }

    handleChange(date) {
      this.setState({
        startDate: date
      });
      sAction.calBulkActions(this.state.cssSelector, "gotoDate", date, true);
    }
    

    fncView(view){
      sAction.calBulkActions(this.state.cssSelector, "changeView", view, false);
    }



    render(){
        const views = sAction.app_strings.calendar_initial_view;
        var viewsBtns = [];
        //$(this.state.cssSelector).fullCalendar();

        const curView = sAction.calBulkActions(this.state.cssSelector, "getView").name;

        for(var view in views) {
          
          let pom = view;

          let buttonClass = "calViewButton";

          if(view == curView){
            buttonClass += " activeView";
          }

          if(view == "agendaDay"){
            <i className="fas fa-clipboard-list"></i>
          }
          viewsBtns.push(<Button className={buttonClass} key={view} onClick={() => {this.fncView(pom)}}>{views[view]}</Button>);
        }

        const setting = this.props.setting;

        let sharedLbl = sAction.translate("LBL_FC_SHARED", "Calendar");
        if(this.props.sharedView){
          sharedLbl = sAction.translate("LBL_FC_NOTSHARED", "Calendar");
        }

        return(
          <div>
            <div className="viewActionPanel calPanel">
            
              <div className="acmCalHeaderLeftMenu">
              <div className="icon-CalHeaderIcon calHeaderIcon"></div>
              {viewsBtns}
              <div className="icon-CalSharedIcon calHeaderIcon"></div>
              <Button onClick={() => sAction.calToggleSharedView()} className="calViewButton">{sharedLbl}</Button>
              <div>
              </div>
              </div>
              <div className="acmCalHeaderCenterMenu">
              </div>
              <div className="acmCalHeaderRightMenu">
                <Button className="calendarButton acmButton" onClick={() => sAction.popup("calSettingsForm", {setting: setting})}>
                <div className={"calHeaderIcon icon-CalSettingsIcon"} ></div>
                {sAction.translate("LBL_SETTINGS", "Calendar")}
              </Button>                 
              </div>
            
            </div>

          </div>
        )
      }
  }
  export default CalHeader
