import React from "react"
import PureComponent from "../../pure"
// import InputText from '../../formElements/InputText';
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import  sAction  from "sAction";
import EmailRelateField from "../../email/EmailRelateField"
import { EmailPopupActionPanel } from "./shared/EmailPopupActionPanel"


export default class EmailRelatePopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      parent_id: "",
      parent_type: "",
      uid: this.props.uid,
    };
  }

  savePopup = () => {
    sAction.rest.post("email/relateEmails", this.state, (data) => {
      sAction.load();
      sAction.popupHide();
      sAction.unLoad();
    })
  }

  handleRelate = returnData => {
    this.setState({
      parent_id: returnData.id,
      parent_type: returnData.type
    });
  };

  render() {

    return (
      <div className="email__popup">
        <div className="acmPopupHeader">
          {/*Vazba*/}
          {sAction.translate("LBL_EMAIL_ASSIGN_TO","Emails")}
        </div>
        <EmailPopupActionPanel onSave={this.savePopup} />
        <div className="email__popup__items">
          <DialogContent>
            <DialogContentText />
            <EmailRelateField onChange={this.handleRelate} />
          </DialogContent>
        </div>
      </div>
    );
  }
}
