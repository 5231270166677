import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import CheckBox from "../../formElements/CheckBox";
import Button from "../../formElements/Button";
import Text from "../../formElements/Text";
import RichText from "../../formElements/RichText";

export default class ApproveRequest extends PureComponent {
    constructor(props) {
        super(props);

        this.input = {
            rejected_reason: React.createRef(),
        };

    }

    save() {
        let data = this.props.data.toJS();

        sAction.dsClear();
        this.input.forEachObject((field, key) => {
            sAction.dsAdd('set', data.prefix + '/fields/' + key + '/value', field.current.value);
            sAction.dsAdd('set', data.prefix + '/changes/fields/' + key, field.current.value);
        });
        sAction.dsAdd('set', data.prefix + '/fields/status/value', 'Rejected');
        sAction.dsAdd('set', data.prefix + '/changes/fields/status', 'Rejected');
        sAction.dsAdd('set', data.prefix + '/changes/fields/acm_discount_request_users_1users_ida', sAction.dataGet('conf/user/id'));
        sAction.dsAdd('set', data.prefix + '/changes/fields/relate', {acm_discount_request_users_1_name: sAction.dataGet('conf/user/name')});
        sAction.dsProcess();

        sAction.saveRecord(data);
        sAction.popupHide();
    }

    render() {
        return (
            <React.Fragment>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_REJECT_REQUEST', 'acm_discount_request')}</div>
                </div>
                <div className="exportListPopupContent">
                    <div className="simpleForm">
                        <table>
                            <tbody>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_REJECTED_REASON', 'acm_discount_request')}</td>
                                <td colSpan={2}>
                                    <textarea
                                        ref={this.input.rejected_reason}
                                        className="withBorder"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="viewActionPanelButtons flexRight">
                                        <Button onClick={() => this.save()}>
                                            <div className={"actionPanelButtonIcon icon-reject"} />
                                            {sAction.translate('LBL_REJECT_REQUEST', 'acm_discount_request')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}