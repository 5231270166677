import React from "react";
import  sAction  from "sAction";
export default function NameCallback(props) {
  const value = sAction.decodeHTMLEntities(props.value);
  var lineRender;
  let showEyeIcon = true;

  lineRender = (
    <div className="hrefEffect" onClick={() => props.callback()}>
      <a className="strong" title={value}>
        {value}
      </a>
    </div>
  );

  return (
    <div className={"newListViewLine " + props.extraClass}>
      {showEyeIcon && (
        <a
          href={"#detail/" + props.module + "/" + props.id}
          target="_blank"
          className="icon-newTab listViewEyeIcon"
        />
      )}
      {lineRender}
    </div>
  );
}
