import {List} from 'immutable';

export default function parseFile(params) {
    const prefix = params.prefix;
    const fileText = this.dataGet(prefix + "/fileContents");
    const columnDelimiter = this.dataGet(prefix + "/delimiters/columnDelimiter");
    const lineDelimiter = this.dataGet(prefix + "/delimiters/lineDelimiter");
    const rows = fileText.split(lineDelimiter);
    this.dsClear();
    this.dsAdd('set', prefix + "/import", {})
    let maxColumnCount = 0;
    let rowList = [];
    for (let i = 0; i < rows.length; i ++) {
        if (i >= 20) {
            break;
        }

        const columns = rows[i].split(columnDelimiter);
        maxColumnCount = columns.length > maxColumnCount ? columns.length : maxColumnCount;
        rowList.push(List(columns));
    }
    this.dsAdd('set', prefix + '/ignoreFirstRow', 0);
    this.dsAdd('set', prefix + '/useFirstAsMapping', 0);
    this.dsAdd('set', prefix + '/import', []);
    this.dsAdd('set', prefix + '/import', List(rowList));
    this.dsAdd('set', prefix + "/columnCount", maxColumnCount);
    this.dsAdd('set', prefix + "/table", {});
    this.dsAdd('set', prefix + '/firstRow', {});
    this.dsProcess();
}