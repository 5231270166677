import React, { PureComponent } from "react"
import  sAction  from "sAction";

import Button from "../formElements/Button"
import Relate from "../formElements/Relate"

export default class EmailAssignField extends PureComponent {
  constructor(props) {
    super(props);

    const { id, name } = sAction.dataGet("conf/user");

    this.state = {
      users: id,
      assigned_user_name: name
    };
  }

  componentDidMount() {
    this.props.onChange({
      id: this.state.users,
      name: this.state.assigned_user_name
    });
  }

  openUsersList = () => {
    sAction.openRelatePopup({ module: "Users" }, returnData => {
      this.handleChange(returnData);
      sAction.popupHide();
    });
  };

  handleChange = returnData => {
    this.props.onChange(returnData);
    this.setState({
      users: returnData.id,
      assigned_user_name: returnData.name
    });
  };

  render() {
    const { assigned_user_name } = this.state;

    return (
      <div className="email__compose__field-row">
        <div className="email__compose__field-row__input">
          <Relate
            key={assigned_user_name}
            newRecord={false}
            data={{
              value: assigned_user_name,
              //label: "Přidělen"
              label: sAction.translate("LBL_ASSIGNED_TO", "Emails")
            }}
            buttons={[]}
            module={"Users"}
            //key={keyPre+"_"+field.name}
            callback={this.handleChange}
          />
        </div>
        <div className="email__compose__field-row__button">
          <Button onClick={this.openUsersList} className="stillHover">
            {/*Vybrat*/}
            {sAction.translate("LBL_EMAIL_SELECT")}
          </Button>
        </div>
      </div>
    );
  }
}
