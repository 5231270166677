import React, { PureComponent } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import  sAction  from "sAction";

export class EmailListContextMenu extends PureComponent {
  render() {
    // console.log("render contextMenu()");

    const { open, isImap } = this.props;

    const e = window.event;

    let left = e && e.pageX ? e.pageX : 0;
    let top = e && e.pageY ? e.pageY : 0;

    return (
      <Menu
        id="menu"
        //anchorEl={anchorEl}
        open={open}
        onClose={this.props.closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={{ left, top }}
      >
        {isImap && (
          <MenuItem onClick={this.props.openImportPopup}>
              <div className="contextMenuIcon icon-download" />
            {/*Importovat do Sugaru*/}
            {sAction.translate("LBL_EMAIL_ARCHIVE_TO_SUGAR", "Emails")}
          </MenuItem>
        )}

        {!isImap && (
          <MenuItem onClick={this.props.openRelationsPopup}>
              <div className="contextMenuIcon icon-emailRelations" />
            {/*Zobrazit vztahy*/}
            {sAction.translate("LBL_EMAIL_VIEW_RELATIONSHIPS")}
          </MenuItem>
        )}

        {!isImap && (
          <MenuItem onClick={this.props.openAssignPopup}>
              <div className="contextMenuIcon icon-assign" />
            {/*Přiřadit*/}
            {sAction.translate("LBL_EMAIL_ASSIGN_TO")}
          </MenuItem>
        )}

        {!isImap && (
          <MenuItem onClick={this.props.openRelatePopup}>
            <div className="contextMenuIcon icon-relate" />
            {/*Vazba*/}
            {sAction.translate("LBL_EMAIL_RELATE_TO")}
          </MenuItem>
        )}

        <MenuItem onClick={e => this.props.openEmail(e, "reply")}>
          <div className="contextMenuIcon icon-emailReply" />
          {/*Odpovědět*/}
          {sAction.translate("LBL_EMAIL_REPLY")}
        </MenuItem>

        <MenuItem onClick={e => this.props.openEmail(e, "replyAll")}>
          <div className="contextMenuIcon icon-emailReplyAll" />
          {/*Odpovědět všem*/}
          {sAction.translate("LBL_EMAIL_REPLY_ALL")}
        </MenuItem>

        <MenuItem onClick={e => this.props.openEmail(e, "forward")}>
          <div className="contextMenuIcon icon-emailForward" />
          {/*Přeposlat*/}
          {sAction.translate("LBL_BUTTON_FORWARD", "Emails")}
        </MenuItem>
      </Menu>
    );
  }
}
