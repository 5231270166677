import React, { PureComponent } from "react"
import  sAction  from "sAction";
import Checkbox from "../formElements/TickBox"
import Select from "../formElements/SelectAcm"
import Button from "../formElements/Button"
import DeleteIcon from "@material-ui/icons/Delete"

export class EmailListHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.sort_options = [
      // "Označit jako"
      {
        label: sAction.translate("LBL_EMAIL_SORT_BY", "Emails"),
        value: "-"
      },
      // "Nejnovější"
      {
        label: sAction.translate("LBL_EMAIL_LATEST", "Emails"),
        value: "date desc"
      },
      // "Nejstarší"
      {
        label: sAction.translate("LBL_EMAIL_OLDERS", "Emails"),
        value: "date asc"
      },
      // "Oblíbené"
      {
        label: sAction.translate("LBL_EMAIL_FAVORITE", "Emails"),
        value: "flagged desc"
      },
      // "Jméno A-Z"
      {
        label: sAction.translate("LBL_EMAIL_NAME_ASC", "Emails"),
        value: "from asc"
      },
      // "Jméno Z-A"
      {
        label: sAction.translate("LBL_EMAIL_NAME_DESC", "Emails"),
        value: "from desc"
      },
      // "Předmět A-Z"
      {
        label: sAction.translate("LBL_EMAIL_SUBJELT_ASC", "Emails"),
        value: "subject asc"
      },
      // "Předmět Z-A"
      {
        label: sAction.translate("LBL_EMAIL_SUBJECT_DESC", "Emails"),
        value: "subject desc"
      },
      // "Nepřečtené"
      {
        label: sAction.translate("LBL_EMAIL_UNREAD", "Emails"),
        value: "unread desc"
      }
    ];
    this.mark_options = [
      // "Označit jako"
      {
        label: sAction.translate("LBL_EMAIL_MARK", "Emails"),
        value: "-"
      },
      // "Přečtený"
      {
        label: sAction.translate("LBL_EMAIL_MARK_READ", "Emails"),
        value: "read"
      },
      // "Nepřečtený"
      {
        label: sAction.translate("LBL_EMAIL_MARK_UNREAD", "Emails"),
        value: "unread"
      }
      // { label: "Archivovaný", value: "archived" },
      // { label: "Uzavřený", value: "closed" },
      // { label: "Zodpovězený", value: "replied" }
    ];
  }

  render() {
    const { isSthSelected, checkedAll } = this.props;

    return (
      <React.Fragment>
        <div className="email__list__header__checkbox">
          <Checkbox
            checked={checkedAll}
            onChange={this.props.handleCheckboxAll}
          />
        </div>

        {!isSthSelected && (
          <div className="email__add-params__select">
            <Select
              options={this.sort_options}
              name="sort"
              onChange={this.props.sort}
              defaultValue="-"
            />
          </div>
        )}

        {isSthSelected && (
          <React.Fragment>
            <div className="email__add-params__select">
              <Select
                options={this.mark_options}
                name="seen"
                onChange={this.props.mark}
                //value=""
                defaultValue="-"
              />
            </div>

            {/* DELETE */}
            <Button onClick={this.props.delete} className="hoverBlue">
              <DeleteIcon />
              {/*Vymazat*/}
              {sAction.translate("LBL_DELETE_INLINE", "Emails")}
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
