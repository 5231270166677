import React from "react";
import PureComponent from "../../pure";

import Button from "../../formElements/Button";
import Loader from "../../loader";
import sAction from 'sAction';

export default class FileDownload extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    var data = this.props.data;
    const state = data.get("state");
    return (
      <div className="fileDownload">
        {state == "load" && <Loader />}
        {state == "init" && (
          <React.Fragment>
            <div className="popupDownloadButton icon-preview"
              onClick={() => {
                window.open(
                  data.get("token"),
                  "_blank"
                );
                sAction.popupHide();
              }
              }
            >
            </div>
            <span>{sAction.translate("LBL_PREVIEW_FILE", "Documents")}</span>
          </React.Fragment>
        )}
      </div>
    );
  }
}
