export default function sweetSpotSearch(str){
    var self = this;
    this.rest.post("sweetSpotSearch", {str: str}, function(data) {
        self.dsClear();
        
        var akce ={
            type: 'SWEETSPOTSEARCH',
            content: {data: data},
            prefix: "conf/popup/data",
          }
          self.dsAddCustom(akce);
          self.dsProcess();

    });
}