

export default function closeFilter(filter,prefix){


    const akce = {
        type: "CLOSEFILTER",
        listViewPrefix: prefix,
        filter: filter, 
      }
    this.dataSetCustom(akce);

}