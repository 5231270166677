import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";

import RadioGroup from "../../formElements/RadioGroup";
import  sAction  from "sAction";
import { EmailPopupButtons } from "../email/shared/EmailPopupButtons";

/**
 * @parent  src\components\home\HomeTabs.js
 * @parent  src\components\home\HomeTabSettingsSubmenu.js
 * @parent  src\components\home\HomeTabsSubmenu.js
 */
export default class HomeTabAddOrEditPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
    this.nameRef = React.createRef();
  }

  updateVal = (name, value) => {
    let pom = {};
    pom[name] = value;
    this.setState(pom);
  };

  savePopup = () => {
    sAction.popupHide();

    const name = this.nameRef.current.value;
    const value = this.state.value;
    let wasChanged = false;

    //pridani tabu
    if (this.props.add == true) {
      sAction.homeTabAdd(name, value);
      wasChanged = true;
    } else {
      //prejmenovani zalozky
      if (this.props.name != name) {
        sAction.homeTabChangeName(this.props.way + "/name", name);
        wasChanged = true;
      }

      //uprava sloupcu
      if (this.props.value != value) {
        sAction.homeColumnsChange(this.props.way, value);
        wasChanged = true;
      }
    }

    //ulozeni layoutu v pripade zmeny
    if (wasChanged) {
      sAction.homeLayoutSave();
    }
  };

  render() {
    let options = {
      1: "1",
      2: "2",
      3: "3",
    };

    return (
      <div>
        <div className="acmPopupHeader">
          {this.props.add == true
            ? sAction.translate("LBL_ADD_PAGE", "Home")
            : sAction.translate("LBL_EDIT_PAGE", "Home")}
        </div>
        {/* Přidat stránku || Upravit stránku */}
        <div className="acmPopupContent">
          <div className="w100">
            <table className="w100">
              <tbody>
                <tr>
                  <th>{sAction.translate("LBL_DASHLET_OPT_TITLE", "Home")}</th>
                  <td>
                    <InputText
                      autoFocus
                      inputRef={this.nameRef}
                      id="name"
                      className="withBorder"
                      type="text"
                      defaultValue={this.props.name}
                      fullWidth
                      onKeyUp={(ev) => {
                        if (ev.key === "Enter") {
                          this.savePopup();
                          ev.preventDefault();
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>{sAction.translate("LBL_NUM_COLUMNS", "Home") /* Počet sloupců */}</th>
                  <td>
                    <RadioGroup
                      value={this.state.value.toString()}
                      options={options}
                      onChange={(e) => this.updateVal("value", e.target.value)}
                      style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row", padding: "10px 0" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <EmailPopupButtons onSave={this.savePopup} />
      </div>
    );
  }
}
