export default function reloadSubpanel(data){
    var paramData = {
        record: data.parentId,
        module: data.parentModule,
        subpanel: data.module,
        relName: data.relName,
        userPreferences: {category:data.parentModule+"_subpanel",way:"all/"+data.module+"/open",contents:true}
    }

    this.rest.post("returnSubpanelData",paramData,returnData => {
        if(returnData.state == true){
            const action = {
                type:"LOADSUBPANEL",
                subpanel:returnData,
                parent: data.parentModule,
                parentId: data.parentId,
                prefix:data.prefix,
                way:data.way,
            }
            this.dataSetCustom(action)
            this.unLoad();
        }
    })
}