export default function homeTabAdd(name, columns) {
  if (!name) name = this.translate("LBL_NEW_PAGE","Home"); //Nová stránka
  const id = this.dataGet("view/tabs").size;

  //addTab
  this.dsClear();

  this.dsAdd(
    "add",
    "view/tabs",
    {
      id: id,
      name: name,
      cols: []
    },
    "HomeTabs"
  );

  //pridani sloupcu
  for (let i = 0; i < columns; i++) {
    this.dsAdd(
      "add",
      "view/tabs/" + id + "/cols",
      {
        id: i + 1
      },
      "HomeColumns"
    );
  }

  this.dsAdd("set","view/activeId", id);
  this.dsProcess();
}
