import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";

export default class Breadcrumb extends PureComponent {
  constructor() {
    super();
  }

  render() {
    const data = this.props.data
    var renderData = [];
    data.forEach((element,index) => {
        if(element.type == "link"){
            renderData.push(<a key={index} href={element.url}>
                {element.label}
            </a>)
            renderData.push(<div key={"0"+index} className="breadcrumbDelimiter icon-arrowRight"></div>);
        }else if(element.type == "text"){
            renderData.push(<span key={index}>{sAction.decodeHTMLEntities(element.label)}</span>)
            renderData.push(<div key={"0"+index} className="breadcrumbDelimiter icon-arrowRight"></div>);
        }
    });
    renderData = renderData.splice(0,renderData.length-1);
    return (
        <div className="headerBreadCrumb">
            {renderData}
        </div>
    );
  }
}
