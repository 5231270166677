export default function reportWindowSaveSearch(paramData){
        const name = paramData.name;
        const id = paramData.id;
        if(name != ""){
            var data = this.dataGet("reportWindow");
            data = data.toJS();
            data.name = name;
            delete data.fields;
            delete data.modules;
            delete data.relate;
            delete data.actContent;
            delete data.results;
            delete data.savedSearch;
  
            var url = data.module;
            if(id != "" && id != null && id != undefined){
                url = data.module+"/"+id
            }
            const filter = this.reportWindowCreateBasic();
            data.listViewFilter = filter;
            data.metadata = paramData;

            this.load();
            this.rest.post("search/"+url,data,returnData => {
                if(returnData.status === "ok"){
                    paramData = returnData['record'];
                    this.dataSet("reportWindow/metadata/id",paramData);
                }
                this.unLoad();
            });
        }
}