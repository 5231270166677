import React from "react";

import Timeline from "../Timeline/Timeline";
import Select from "../formElements/Select";
import  sAction  from "sAction";

export default class DetailTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customValue: null
    };
  }

  onSelect(e, value) {
    const way = this.props.way;
    const id = this.props.id;
    const module = this.props.module;

    const data = this.props.data;
    const field = this.props.field;
    if (data.clickable == true) {
      const action = data.onClick.get(value);
      if (action === undefined) {
        this.changeState(value);
      }else if(action != null){
        if(action.type == "sAction"){
          if(sAction[action.action] != undefined){
            const param = {
              way,id,module
            }
            sAction[action.action](param);
          }else{
            console.log("Tato akce není v sAction");
          }
        }
      }
    }
  }
  changeState(value) {
    const data = this.props.data;
    const listType = data.list.get("type");

    if (listType == "list") {
      this.changeStateBase(value)
    } else if (listType == "custom") {
      const listData = data.list.get("value");
      const pointData = listData.get(value);

      if (pointData.values.size > 1) {
        const buttons = [
          { label: "Zrušit", callback: () => sAction.popupHide() },
          {
            label: "Pokračovat",
            callback: () => {
              this.changeStateCustom(this.state.customValue);
            }
          }
        ];

        const completeList = sAction.app_strings[data.list.get("name")];
        var selectValues = [];
        pointData.values.forEach(value => {
          selectValues.push({ value: value, label: completeList[value] });
        });

        const quickForm = (
          <div className="detailTimelineCustomForm">
            <div>{sAction.translate("LBL_SELECT_STATE_TIMELINE")}</div>
            <div>
              <Select
                options={selectValues}
                myRef={this.customSelect}
                onChange={e => this.setState({ customValue: e.target.value })}
              />
            </div>
          </div>
        );
        sAction.popup(quickForm, { header: "Test", buttons });
      }else{
        this.changeStateCustom(pointData.values.get(0));
      }
    }
  }
  changeStateCustom(value) {
    if(value != ""){
    this.changeStateBase(value);
    sAction.popupHide();
    }
  }
  changeStateBase(value){
    const data = this.props.data;
    const way = this.props.way;
    const field = this.props.field;
    const name = data.field;
    const type = field.def.get("type");
    const updateData = {
      way: way + "/fields/" + name,
      name,
      value,
      type
    };
    sAction.saveField(updateData);
    const saveData = {
      prefix: way,
      id: this.props.id,
      module: this.props.module,
      canSave: true,
      type: this.props.type
    };
    sAction.saveRecord(saveData);
  }

  render() {
    const data = this.props.data;
    const module = this.props.module;
    const field = this.props.field;
    const listType = data.list.get("type");

    var selectValue = null;

    var timeLineData = [];
    if (listType == "list") {
      const list = sAction.app_strings[data.list.get("name")];
      list.forEachObject((label, value) => {
        if (value != "") {
          timeLineData.push({ 
            value: value, 
            label: label,
            title: sAction.translate(data.titles.get(value),module),
            clickAble: data.onClick.get(value) !== null ? true : false
          });
        }
      });
      selectValue = field.value;
    } else if (listType == "custom") {
      const list = data.list.get("value");
      list.entrySeq().forEach(k => {
        timeLineData.push({
          value: k[0],
          label: sAction.translate(k[1].label, module),
          title: sAction.translate(data.titles.get(k[0]),module),
          clickAble: data.onClick.get(k[0]) !== null ? true : false
        });

        const values = k[1].values;
        if (values.includes(field.value)) {
          selectValue = k[0];
        }
      });
    }

    const defaultColor = sAction.param.defaultColor;
    var color = data.colors.get(selectValue);
    if (color == undefined) {
      color = defaultColor;
    }

    const selectValueData = { value: selectValue, color: color };

    return (
      <Timeline
        data={timeLineData}
        value={selectValueData}
        clickable={data.clickable}
        onSelect={(e, value) => this.onSelect(e, value)}
      />
    );
  }
}
