import React from 'react'
import PureComponent from '../pure'
import  sAction  from "sAction";

class SweetSpotItem extends PureComponent{

    handleClick(data){
        sAction.sweetSpotAction({item: data, index: null});
    }
    render(){
        const data = this.props.data;
        var icon = null;
        if(data.icon){
            icon = <div className={"SweetSpotIcon "+data.icon}></div>;
        }

        return (<div className={"SweetSpotItem"+this.props.pomClass} 
                    onClick={() => this.handleClick(data)}>
                    {icon}
                    <div className="SweetSpotItemValue">{data.name}</div>
                </div>);
    }
}
export default SweetSpotItem