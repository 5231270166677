import React from "react";
export default function FileName(props) {
  var lineRender = null;
    lineRender = <a title={props.value} href={"#detail/" + props.module + "/" + props.id}>{props.value}</a>;
 

  var className = "newListViewLine";
  if(props.extraClass !== undefined){
      className += " "+props.extraClass
  }

  return (
    <div className={className}><div onClick={() => props.onGetFile({id:props.id,module:props.module})} className="icon-download listDownLoadIcon"></div>{lineRender}</div>
  );
}
