export default function saveCalSettings(state, callBack){
    var self = this;

    this.rest.post("saveCalSettings", state, function(ret){
        if(ret.retCode == "OK"){
            let cssSelector = self.calEvents.cssSelector;

            self.dsClear();

            self.dsAdd("set", "calendar/filters/showMeetings", state.showMeetings);
            self.dsAdd("set", "calendar/filters/showCalls", state.showCalls);
            self.dsAdd("set", "calendar/filters/showTasks", state.showTasks);
            self.dsAdd("set", "calendar/filters/showCompleted", state.showCompleted);
            
            self.dsAdd("set", "calendar/filters/meetStates", state.meetStates);
            self.dsAdd("set", "calendar/filters/callStates", state.callStates);
            self.dsAdd("set", "calendar/filters/taskStates", state.taskStates);
           

            self.dsProcess();


            callBack();

            self.calInit(cssSelector);
            ///location.reload();
        }
        else{
            alert(ret.retMsg);
        }
    });
}