export default function dataDispatch(akce){
    // vytvoří pole které zpracuje reducers
    var reducerArray = null
    if(Array.isArray(akce) === true){
        reducerArray = akce
    }else if (typeof akce === "object"){
        reducerArray = [akce]
    }
    var frontaAkce = {
        type: "pbDATAFRONTA",
        reducer: this.dataReducer,
        fronta: reducerArray
    }

    if(reducerArray != null){
    this.store.dispatch(frontaAkce)
    }
}