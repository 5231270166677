export default function reportSelectMainModule(module) {
  var self = this;
  this.load();
  this.rest.get("defs/" + module, defsData => {
    self.dsClear();
    self.dsAdd("set", "reportWindow/columns", {}, "reportWindowColumns");

    var fields = {
      fields: [],
      relate: []
    };

    defsData.forEachObject((field, key) => {
      if(field != null){
        if (field.type == "relate" ) {
          if (field.searchable == true && field.name !== "currency_name") {
            fields.relate.push(field);
          }
        } else if (this.checkValidColumn(field)) {
          fields.fields.push(field);
        }
      }

      const k = key.toLowerCase();
      if (field && this.checkValidColumn(field)) {
        field['show'] = true;
        field['fieldModule'] = module;
        if (field['default']) {
          self.dsAdd(
              'add',
              'reportWindow/columns/active',
              { name: k, def: field },
              'reportWindowColumn'
          );
        } else {
          self.dsAdd(
              'add',
              'reportWindow/columns/hidden',
              { name: k, def: field },
              'reportWindowColumn'
          );
        }
      }
    });

    self.dsAdd("set", "reportWindow/metadata", {}, "reportWindowMetadata");
    self.dsAdd("set", "reportWindow/module", module);
    self.dsAdd("set", "reportWindow/fields", fields.fields);
    self.dsAdd("set", "reportWindow/relate", fields.relate);
    self.dsAdd("set", "reportWindow/group", {}, "reportWindowGroup");
    self.dsAdd("set", "conf/load", false);
    self.dsAdd("set", "reportWindow/activeRelate", [{ main: true, module: module, selected: true }]);

    self.dsProcess();
    this.dsClear();
  });

  this.rest.get("search/" + module, (data) => {
    self.dataSet("reportWindow/savedSearch", data.data);
  }, false);
}
