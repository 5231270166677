import InputField from '../inputFieldClass'
import  sAction  from "sAction";

export default class Varchar extends InputField {


  constructor(){
    super();
    this.state = {
      state: "fine",
   //   reg: /^[a-zA-ZÀ-ž\.\-\/\,\:\?\!\+\@ 0-9]+$/,
    };
  }
  saveField(data) {
    sAction.saveField(data);
  }
}