import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";

import AcmDate from "../../formElements/AcmDate"

export default class PopupAcmDate extends PureComponent {
  constructor() {
    super();

  }
  componentDidMount(){

  }
  change(date){
      const data = this.props.data
      if(data.get("callback") != undefined){
          data.get("callback")(date)
      }
      sAction.popupHide();
  }
  render() {
    const data = this.props.data
    return (
        <div style={{display:"none"}} >
        <AcmDate autoOpen={true} 
            onChange={(date) => this.change(date)}
            onClose={() => sAction.popupHide()}
            value={data.get("value") ?? null}
        />
        </div>
    );
  }
}
