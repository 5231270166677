import React from "react";
import { ButtonBase } from "@material-ui/core";
import sAction from "sAction";

export default class Button extends React.PureComponent {

  render() {
    const data = this.props.data.def;

    let action = data.get("action");

    return (
      <ButtonBase
        onClick={() => sAction[action]()}
        className="acmButton acmDetailFieldButton"
        variant="text"
      >
        <span className={data.get("icon")} style={{marginRight: 5}} />
        {sAction.translate(data.get("label"))}
      </ButtonBase>
    );
  }
}
