import React from "react";
import PureComponent from "../pure";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import  sAction  from "sAction";
import composeEmail from "../detailViewFields/Email/composeEmail";

export default class NewActivityButton extends PureComponent {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.button = React.createRef();
  }

  showEmailPopup = e => {
    this.setState({ open: false });
    composeEmail(this.props.prefix)(e);
  };

  render() {
    const data = this.props.data;
    const way = this.props.way;
    const prefix = this.props.prefix;
    const optionsRender = [];
    this.props.activities.forEach((activity, index) => {
      if (activity.module == "Emails" && sAction.hasAccess(activity.module, "edit")) {
        optionsRender.push(
          <MenuItem key={index} onClick={this.showEmailPopup}>
            <div className={"contextMenuIcon " + activity.icon} />
            {activity.label}
          </MenuItem>
        );
      } else {
        if (sAction.hasAccess(activity.module, "edit")) {
          const parent = sAction.dataGet(prefix);
          const params = {
            module: activity.module,
            parentId: parent.id,
            parentName: parent.name,
            parentModule: parent.module,
            prefix: prefix
          };
          optionsRender.push(
            <MenuItem key={index} onClick={() => sAction.newSubRecord(params)}>
              <div className={"contextMenuIcon icon-" + activity.module} />
              {sAction.translate("LBL_MODULE_NAME", activity.module)}
            </MenuItem>
          );
        }
      }
    });
    return (
      <div>
        <div
          className="subpanelHeaderRightButton icon-addIcon"
          ref={this.button}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <Menu
            className="acmMoreOptionContent"
            anchorEl={this.button.current}
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
          >
            {optionsRender}
          </Menu>
        </div>
      </div>
    );
  }
}
