import React from 'react'
export default function Currency(props){
    var lineRender = null;
    const extraClass = "listViewCellRight";
    try {
      lineRender =
        props.value +
        " " +
        props.currencies[props.currency].symbol;
    } catch (e) {
      console.log(props.currencies);
      console.log("Nenalezna měna s ID: " + props.currency);
      lineRender = props.value;
    }
    return(
        <div className={"newListViewLine " + extraClass} onClick={props.onClick}>{lineRender}</div>
    )

}