import React from "react";
import MaterialDefault from './MaterialDefault'
import MaterialRadio from '@material-ui/core/Radio';

export default class Radio extends MaterialDefault {
    constructor(props){
        super(props)
        this.state = {
            value:false
        }

    }
    onChange(e){

        var state = e.target.checked
        if(state == true && this.state.value == true){
            state = false
        }

        this.setState({
            value: state
        })
        console.log(state);
        if(this.props.onChange != undefined){
            this.props.onChange(state);
        }
    }
    render(){

        const attr = this.createAttr("acmRadio");

        return(
            <MaterialRadio 
            onChange={(e) => this.onChange(e)}
            checked={this.props.checked ?? this.state.value}
            tabIndex="0"
            {... attr}
             />
        )
        }
}