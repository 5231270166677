export default function searchInvitees(params, prefix){
    var self = this;

    if(params.parent){
        const def = params.parent.def;
        if(!def.parent_type_value || !def.id_value){
            return;
        }
    }
    else if(!params.first_name && !params.last_name && !params.email_address){
        const parent_type = this.dataGet(params.prefix+"/fields/parent_name/def/parent_type_value");
        const parent_id = this.dataGet(params.prefix+"/fields/parent_name/def/id_value");
        if(parent_type === "Accounts" && parent_id){
            params.parent = {
                def: {
                    parent_type_value : parent_type,
                    id_value : parent_id,
                }
            }
        }
        else{
            return;
        }
    }

    this.rest.post("searchInvitees", params,
        function(invitees) {

            const content = {
                invitees : invitees
            }

            const action = {
            type : "CALENDARSEARCHINVITEES",
            content: content,
            prefix: prefix
            }

            self.dsClear();
            self.dsAddCustom(action);
            self.dsProcess();

    });
}