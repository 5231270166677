export default function reportWindowColumnsModule(value, dsProcess = true) {
  const activeRelate = this.dataGet("reportWindow/activeRelate");
  const savedId = this.dataGet("reportWindow/selectedSavedSearchId")
  const mainModule = this.dataGet("reportWindow/module")
  const modul = activeRelate.getIn([value, "module"]);
  const main = activeRelate.getIn([value, "main"]);

  if (dsProcess) {
    this.dsClear();
  }
  this.dsAdd("set", "reportWindow/columns/hidden", []);

  if(mainModule === modul && savedId) {
    this.rest.get("search/" + modul + "/" + savedId, data => {
      this.reportWindowColumnsModuleSet(data, main, value, modul);
    });
  }else{
    this.rest.get("defs/" + modul, data => {
      this.reportWindowColumnsModuleSet(data, main, value, modul);
    });
  }
}
