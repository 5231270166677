import React from "react";
import PureComponent from "../../pure";

import Button from "../../formElements/Button";

import  sAction  from "sAction";

export default class FileUpload extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    var data = this.props.data;
    const loading = data.get("loading");
    return (
      <div className="fileUploadPopupContainer">
        {loading == true ? (
          <React.Fragment>
            <h2>{sAction.translate("LBL_UPLOADING")}</h2>
            <div className="progressBarContainer">
              <div
                className="progressBarActual"
                style={{ width: data.get("percent") + "%" }}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>{sAction.translate("LBL_UPLOAD_SUCCESSFUL")}</h2>
            <Button
              className="stillHover"
              onClick={() => this.props.parent.forceClose()}
            >
              {sAction.translate("LBL_CLOSE_WINDOW")}
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  }
}
