import React from "react";
import PureComponent from "../../pure";

import ListViewTable2 from "../../list/listViewTable2";

class ReportWindowList extends PureComponent {

  render() {
    var data = this.props.data;

    var renderList = true;
    if (data.results == undefined || data.results.size == 0) {
      renderList = false;
    }

    return (
      <div className="reportWindowResultList">
        {renderList == true && (
          <div className="listView">
            <ListViewTable2 prefix="reportWindow/results" data={data.results} parent={this} />
          </div>
        )}
      </div>
    );
  }
}

export default ReportWindowList;
