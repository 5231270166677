import React from 'react';
import PureComponent from '../../pure';

import  sAction  from "sAction";
import Button from "../../formElements/Button";

class CalendarDashlet extends PureComponent {
    cssSelector = "dashFC_"+this.props.reportId;

    fncGoLeft(){
        sAction.calActivityDashlet({action : "prev", cssSelector : "#"+this.cssSelector, way : this.props.way});
    }

    fncGoRight(){
        sAction.calActivityDashlet({action : "next", cssSelector : "#"+this.cssSelector, way : this.props.way});
    }

    fncToday(){
        sAction.calActivityDashlet({action : "today", cssSelector : "#"+this.cssSelector, way : this.props.way});
    }

    componentDidMount() {
        const dashData = sAction.dataGet(this.props.way+"/data/options");
        var attr = {height: "parent", prefix: this.props.way+"/data/viewData", widget: true};

        sAction.displayFcCal(sAction, this.cssSelector, dashData, attr);
        attr = {widget: true, cssSelector : "#"+this.cssSelector, prefix: this.props.way+"/data/viewData"};
        //sAction.setEventsCalendar(sAction, attr, null, "");
        sAction.calActivityDashlet({action : "filter", cssSelector : "#"+this.cssSelector, way : this.props.way});
    }
    componentWillUnmount(){
        sAction.calBulkActions("#"+this.cssSelector, "destroy", null, false);        
    }

    render(){
        const title = this.props.data.title; 
        return <div className = "DashletCalendarContainer">
            <div className="DashletCalendarHeader">
                <div className="DashletCalendarHeaderLeft">
                    <div className="icon-CalArrowRightIcon calHeaderIcon" onClick={() => this.fncGoLeft()}/>
                    <Button variant="text" className="calendarButton calHeaderIcon" onClick={() => this.fncToday()}>{sAction.translate("LNK_VIEW_CALENDAR", "Calendar")}</Button>
                </div>
                <div className="calTitle" id="dashFCTitle">{title}</div>
                <div className="icon-CalArrowLeftIcon calHeaderIcon" onClick={() => this.fncGoRight()}/>
            </div>
            <div id={this.cssSelector} />
        </div>;
    }
};
export default CalendarDashlet 