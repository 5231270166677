import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";

import InputText from "../../formElements/InputText";
import { EmailPopupButtons } from "./shared/EmailPopupButtons";

// import InputText from "../formElements/InputText";

export default class EmailFolderCreate extends PureComponent {
  constructor(props) {
    super(props);

    this.nameRef = React.createRef();
  }

  //ulozeni zmen v popupu
  savePopup = () => {
    const name = this.nameRef.current.value;

    if (!name) {
      // alert("Nebyl zadán žádný název");
      sAction.error(sAction.translate("ERR_NO_SUBJECT", "Emails"));
      return;
    }

    sAction.popupHide();

    const data = this.props.data;

    let folderData;
    if (data.ieId !== "folder") {
      folderData = {
        folderType: "imap",
        ieId: data.ieId,
        mbox: data.mbox,
        newFolderName: name,
      };
    } else {
      folderData = {
        folderType: "sugar",
        parentId: data.id,
        nodeLabel: name,
      };
    }

    sAction.load();
    sAction.rest.post("email/saveNewFolder", folderData, (data) => {
      // console.log(data)

      sAction.routeEmail();
    });
  };

  render() {
    return (
      <React.Fragment>
        {/* Přidat novou složku */}
        <div className="acmPopupHeader">{sAction.translate("LBL_EMAIL_MENU_ADD_FOLDER")}</div>

        <div className="acmPopupContent">
          <div className="w100">
            <InputText
              autoFocus
              margin="dense"
              id="name"
              label={sAction.translate("LBL_ENTER_NAME", "Home")} // Zadejte název
              type="text"
              inputRef={this.nameRef}
              defaultValue={this.props.name}
              fullWidth
              onKeyUp={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  this.savePopup();
                }
              }}
            />
          </div>
        </div>
        <EmailPopupButtons onSave={this.savePopup} />
      </React.Fragment>
    );
  }
}
