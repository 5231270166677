import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";

//material
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import HomeTabAddOrEditPopup from "../popup/home/HomeTabAddOrEdit";

//parent src\components\home\HomeTabs.js
export default class HomeTabSettingsSubmenu extends PureComponent {
  state = {
    anchorEl: null
  };

  //otevreni kontext. menu
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  //zavreni kontext. menu
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  //vymazani boxu
  handleDelete = () => {
    this.handleClose();

    sAction.homeTabDelete(this.props.id);
  };

  //edit tabu - popup
  handleEdit = () => {
    this.handleClose();

    const way = "view/tabs/" + this.props.id;
    const colsNum = sAction.dataGet(way + "/cols").size;

    //otevre popup okno na upravu aktualniho tabu
    sAction.popup(
      <HomeTabAddOrEditPopup
        way={way}
        name={this.props.item}
        value={colsNum}
      />
    );
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <React.Fragment>
        {this.props.item}
        <div className="homeSettingsIcon" onClick={this.handleClick}><div className="icon-settings2" /></div>

        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleEdit}>
            <div className="contextMenuIcon icon-EditField" />
            {sAction.translate("LBL_EDIT_PAGE", "Home")} {/* Upravit stránku */}
          </MenuItem>
          <MenuItem onClick={this.handleDelete}>
            <div className="contextMenuIcon icon-deleteIcon" />
            {sAction.translate("LBL_CLEAR", "Home")} {/* Smazat */}
          </MenuItem>

        </Menu>
        </React.Fragment>
    );
  }
}