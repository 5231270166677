import React from "react";
import PureComponent from "../../pure";
import TabSelect from "../../formElements/TabSelect";
import Relate from "../../detailViewFields/Relate/Relate";
import { DataConstructors } from "../../../store/DataConstructors";
import sAction from "sAction";
import InputText from "../../formElements/InputText";
import Enum from '../../defaultFields/Enum/Enum';

class MapAdditionalData extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      relateData: null,
    };
  }

  createFieldRecord = (paramData, selectedFieldData) => {
    return new DataConstructors["Field"]({
      name: paramData.value,
      value: "",
      actEdit: false,
      customStyle: null,
      customClass: null,
      def: selectedFieldData,
      events: null,
    });
  }

  prepareDataForComponent = (paramData, selectedFieldData) => {
    const data = this.createFieldRecord(paramData, selectedFieldData);
    const module = this.props.data.get("module");
    const prefix = "view";

    this.setState({
      data: data,
      module: module,
      index: 1,
      newRecord: false,
      prefix: prefix,
      fieldType: paramData.type,
      way: "",
    });
  }

  onChange = (paramData) => {
    sessionStorage.setItem('additionalImportDataLabel', paramData.label);
    if (this.state.data && paramData.value !== this.state.data.name) {
      this.setState({ relateVisible: false });
      sAction.dsClear();
      sAction.dsAdd("set", this.props.data.get("way"), null);
      sAction.dsProcess();
    }

    let selectedData;
    if (paramData.type === 'relate') {
      selectedData = this.props.data
      .get("relates")
      .get(paramData.index);

      this.prepareDataForComponent(paramData, selectedData);
      return;
    }

    selectedData = this.props.data
    .get("fields")
    .get(paramData.index);

    this.prepareDataForComponent(paramData, selectedData);
  };

  renderRelate = () => {
    this.setState({ relateVisible: true });
  };

  closeRelate = () => {
    this.setState({ relateVisible: false });
  };

  render() {
    const data = this.props.data;
    const mapping = sAction.dataGet(data.get("way"));
    const value = mapping ? mapping.get("inputValue") : "";
    const getOptionsCallback = this.props.data.get("getOptionsCallback");
    const relates = getOptionsCallback(data.get("relates").toJS(), false);
    const fields = getOptionsCallback(data.get('fields').toJS(), true);
    let options = [];
    const fieldsKey = Object.keys(fields);
    const relatesKey = Object.keys(relates);
    options[fieldsKey] = fields[fieldsKey];
    options[relatesKey] = relates[relatesKey];

    const relate = (
      <Relate
        data={this.state.data}
        newRecord={this.state.newRecord}
        module={this.state.module}
        index={this.state.index}
        prefix={this.state.prefix}
        way={data.get("way")}
        onSelect={this.closeRelate}
      />
    );


    const relateValueHolder = <InputText value={value} onClick={this.renderRelate} />;
    let content = this.state.relateVisible ? relate : relateValueHolder;

    if (this.state.fieldType && this.state.fieldType !== 'relate') {
      const actionFieldType =  this.state.fieldType.charAt(0).toUpperCase() + this.state.fieldType.slice(1);
      let Component = sAction.fields[actionFieldType];

      if ( ! Component) {
        console.log("Field '" + actionFieldType + "' se nenašel");
        Component = sAction.fields['Varchar'];
      }

      content = <Component
        data={this.state.data}
        newRecord={this.state.newRecord}
        module={this.state.module}
        index={this.state.index}
        prefix={this.state.prefix}
        way={data.get("way")}
      />
    }

    return (
      <div className={"importPopupContainer"}>
        <div className={"acmPopupHeader"}>Přidejte sloupec</div>
        <div className={"importRelateSelectorContainer"}>
          <div style={{ width: "300px", marginBottom: "20px" }}>
            <TabSelect
              containerClassName="withBorder"
              search={true}
              options={options}
              onChange={this.onChange}
              container={"importView"}
            />
          </div>
          {this.state.data && (
            <div style={{ width: "300px", border: "1px solid #DFDFDF", height: "100%" }}>
              <div className={"DetailViewValue importRelateSelect"}>
                {content}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MapAdditionalData;
