export default function getRequiredFields(fields) {
    const required = {};

    for (const field of fields) {
        if (field.hasOwnProperty('required') && field['required'] === true) {
            required[field['name']] = field['vname'];
        }
    }

    return required;
}