export default function afterLoadListView(prefix, table, type = "listView") {
  var listTable = [table];
  if (table === undefined) {
    listTable = document.querySelectorAll(".newListView");
  }

  if (!listTable || !listTable.length) {
    return;
  }
  
  listTable.forEach(actTable => {
    if(actTable === null){
      return;
    }
    const widgetParent = actTable.closest(".widgetListView");

    const tableWidth = actTable.clientWidth;
    const tds = actTable.querySelectorAll(".setWidth");
    const endOfLine = tds.length / 2

    const view = this.getViewName()
    const module = this.dataGet("view/modul")
    const way = view + module
    let filterData = this.getStorage('listFilterData')

    let widthInfo = null
    if(module && view) {
      if (filterData && filterData[view + module]) {
        widthInfo = filterData[way]['widthInfo']
      }
    }

    let index = 1;
    var alreadySet = 0;
    if (type !== "subpanel") {
      tds.forEach(td => {

        //pokud uzivatel resizoval je potreba vytahnout data o jeho nastavene sirce sloupcu
        if(widthInfo) {
          let widthData = filterData[way]['widthInfo'].find(o => o['tableIndex'] === index);

          if (widthData) {
            td.style.minWidth = widthData.minWidth;
            td.style.maxWidth = widthData.maxWidth;
            if (index === endOfLine) {
              index = 1;
            } else {
              index++;
            }
            return;
          }

          if (index === endOfLine) {
            index = 1;
          } else {
            index++;
          }

        }

        const last = td.dataset.last;

        var percent = 10;
        if (td.dataset.width != null) {
          percent = parseInt(td.dataset.width);
        }
        
        var newWidth = tableWidth * (percent / 100);

        if (last === "false") {
          alreadySet += newWidth;
        } else {
          if (tableWidth > alreadySet + newWidth) {
            // Pokud se nejedná o widget na homepage tak se odečte více kvůli tomu že jsou viditelné checkboxy
            if (widgetParent !== null) {
              newWidth = tableWidth - alreadySet - 10;
            } else {
              
              newWidth = tableWidth - alreadySet - 35;
            }
          }
          alreadySet = 0;
        }
          td.style.minWidth = newWidth + "px";
          td.style.maxWidth = newWidth + "px";

      });
    }
    if (type === "subpanel" || type === "widget") {
      var count = true;
      var totalPercent = 0;
      var percentArray = [];
      tds.forEach(td => {
        const last = td.dataset.last;
        if(count == true){
          var percent = 3;
          if(td.dataset.width != null){
          percent = parseInt(td.dataset.width);
          }else{
            percent = (30 / tableWidth)*100;
          }
          percentArray.push(percent);
          totalPercent += percent;
        }
        if (last === "true") {
          count = false;
        }
      });
      
      percentArray = this.repairSubpanelRowPercent(totalPercent,percentArray);

      var lastIndex = 0;
      tds.forEach((td,key) => {
        const last = td.dataset.last;
        const percent = percentArray[key-lastIndex];
        td.style.width = percent+"%";
        if(last === "true"){
          lastIndex = key+1;
        }
      })
      this.repairSubpanelHeight(actTable);
    }
  });
}
