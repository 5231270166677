export default function oppCreateQuote(data) {

    //TT TODO tady by se jeste asi mely kontrolovat cahnges (pokud nejsou prazdne (fieldy a lines), tak upozornit prez confirm)
    //TODO mozna vyvolat confirm

    
    const params = {action: "opp_create_quote", record : data.id};

    this.load();
    this.rest.post("customAction", params, resp => {
        if(resp.errCode == "OK"){
            this.href("#detail/Quotes/"+resp.record);
        }
        else{
            this.unLoad();
            this.alert(resp.errMsg);
        }
        
    });
    
}