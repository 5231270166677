function formatDate(d, substractDays) {
  var dd = d.getDate() - substractDays;
  var mm = d.getMonth() + 1; //January is 0!

  var yyyy = d.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  d = dd + ". " + mm + ". " + yyyy;
  return d;
}

function formatDate_nice(d) {
  var dd = d.getDate();
  var mm = d.getMonth() + 1; //January is 0!

  var yyyy = d.getFullYear();

  if (yyyy === new Date().getFullYear()) yyyy = "";

  d = dd + ". " + mm + ". " + yyyy;
  return d;
}

export default function mysqlToDate(originalDate, fullFormat = false) {
  if (!originalDate) return "";
  const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})( [0-9]{2}:[0-9]{2}:[0-9]{2})?$/;
  if (regex.test(originalDate) !== true) return originalDate;

  let d = originalDate.replace(/([0-9]{4})-([0-9]{2})-([0-9]{2})/, "$3.$2.$1");

  if (fullFormat) return d;

  d = d.split(" ");

  var [date, time] = d;

  var todayDate = new Date();
  var today = formatDate(todayDate, 0);
  var yesterday = formatDate(todayDate, 1);

  if (date == today) return this.translate("LBL_EMAIL_DATE_TODAY")+", " + time;

  if (date == yesterday) return this.translate("LBL_EMAIL_DATE_YESTERDAY")+", " + time;

  const niceDate = formatDate_nice(new Date(originalDate));
  if (niceDate.indexOf("NaN") !== -1) console.error(originalDate);

  return niceDate;
}
