export default function subpanelNewRecord(params) {
  const { paramData, way, prefix, predefinedField } = params;
 
  const parent = this.dataGet(prefix);
  if (paramData.module == "Documents") {
    const data = {
      prefix: way + "/rows",
      rel: {
        module: parent.module,
        relname: paramData.def.getIn(["rel", "relName"]),
        id: parent.id
      }
    };
    this.processFileInput(data);
  } else {
    var rel = paramData.def.get("rel");
    const params = {
      module: paramData.module,
      parentModule:parent.module,
      parentId:parent.id,
      parentName:parent.name,
      rel:rel,
      prefix:prefix
    }
    console.log(params);
    this.newSubRecord(params);
  }
}