export default function processFiles(ev,way,callback){

  var files = [];
  var i, file;
  this.dataSet(way+"/dropZoneActive",false);
  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (i = 0; i < ev.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[i].kind === "file") {
        file = ev.dataTransfer.items[i].getAsFile();
        files.push(file);
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (i = 0; i < ev.dataTransfer.files.length; i++) {
      file = ev.dataTransfer.files[i];
      files.push(file);
    }
  }
  if(callback !== undefined){
    callback(files)
  }
 /*
  var formData = new FormData();
  files.forEach(file => {
      formData.append("files[]",file,file.name)
  });
  const treeData = this.dataGet(way+"/listViewTree");
  const activeCategory = treeData.get("activeCategory");
  const fieldName = treeData.get("field");
  const fieldData = {};
  fieldData[fieldName] = activeCategory;

  formData.append("fieldData["+fieldName+"]",activeCategory);

  this.sendFiles(formData,returnData => {
      if(returnData.state == true){
        this.reloadList(0,1,way);
      }
  });
  */
}
