import React from "react";
import  sAction  from "sAction";
import DefaultDate from '../../defaultFields/Date/Date';

export default class Date extends DefaultDate {

	saveField(data) {
		sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);	
	}

}
