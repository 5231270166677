import React from 'react'
import PureComponent from '../pure'

import SweetSpotResultContainer from './SweetSpotResultContainer'

import  sAction  from "sAction";

class SweetSpot extends PureComponent{
    constructor() {
        super();
        this.state = {
            resultArrowIndex: -1
        };

        this.searchInterval = null;
        this.maxRecords = 6;
        this.input = React.createRef();
      }

    keyUp(ev){
        const keyCode = ev.keyCode;
        if (keyCode === 13) {
            if(this.state.resultArrowIndex != -1){
                sAction.sweetSpotAction({item: null, index: this.state.resultArrowIndex});
                return;
            }
            
            this.search();
        } else if (keyCode === 27) {
            sAction.hideSweetSpot();
        }else if(keyCode === 40 || keyCode === 38) {
            this.changeArrowIndex(keyCode);
        } else if (keyCode !== 9 && keyCode !== 37 && keyCode !== 39) {
            this.waitForSearch();
        }
    }

    waitForSearch(){
        if (this.searchInterval != null) {
            clearInterval(this.searchInterval);
          }
          var self = this;
          this.searchInterval = setInterval(() => {
            self.search();
            clearInterval(self.searchInterval);
          }, 300);
    }

    search(){
        const value = this.input.current.value;
        if(value != ""){
            this.setState({resultArrowIndex : -1});
            sAction.sweetSpotSearch(value);
        }
    }

    changeArrowIndex(keyCode){
        var resultArrowIndex = this.state.resultArrowIndex
        if(keyCode === 40){
            resultArrowIndex += 1;
        }else if(keyCode === 38){
            resultArrowIndex -=1;
        }
        
        var max = sAction.dataGet("conf/popup/data/data/length");
        
        if(this.maxRecords < max){
            max = this.maxRecords;
        }
        
        if(resultArrowIndex <= -1){
            resultArrowIndex = max - 1;
        }
        else if(resultArrowIndex >= max){
            resultArrowIndex = 0;
        }
        this.setState({resultArrowIndex : resultArrowIndex});
    }

    showHelp () {
        let rows = [];
        let commands = sAction.getEnumForSelect("sweetspot_commands_list");
        commands.forEach((command, key) => {
            rows.push(<tr key={key}><td>{command.value}</td><td>{command.label}</td></tr>);
        });
        let content = (
            <React.Fragment>
                    {sAction.translate("LBL_SWEETSPOT_HELP_TEXT")}<br />
                    {sAction.translate("LBL_SWEETSPOT_HELP_COMMAND_HEADER")}<br />
                    <table className='simpleForm sweetspotHelp'>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                    {sAction.translate("LBL_SWEETSPOT_HELP_FILTER")}<br />
            </React.Fragment>
        );
        let data = {
            content: content,
            header: sAction.translate("LBL_SWEETSPOT_HELP_HEADER"),
            selector: '.icon-help-circle',
            className: 'sweetspot'
        };
        sAction.popper(data);
    }

    render(){
        const data = this.props.data.get("data");
        var resComp = null;
        if(data != undefined && data.length > 0){
            resComp = <SweetSpotResultContainer 
                data={data}
                arrowIndex={this.state.resultArrowIndex}
                maxRecords = {this.maxRecords}
            />
        }

        return (
            <React.Fragment>
                <div className="acmPopupHeader black">
                    {sAction.translate("LBL_SWEETSPOT_TITLE")}
                    <div style={{paddingLeft: '10px'}} className='icon-help-circle' onClick={ () => this.showHelp()}></div>
                </div>
                <div className="sweetSpotContainer">
                    <div className="sweetSpotInputContainer">
                        <input
                            type="text"
                            className="sweetspot-searchinput"
                            name="sweetspot-searchinput"
                            onKeyUp = {ev => this.keyUp(ev)}
                            ref={this.input}
                            autoFocus={true}
                            autoComplete="off"
                        />
                    </div>
                    {resComp}
                </div>
            </React.Fragment>
        );
    }
}

export default SweetSpot