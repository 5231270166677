import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";

import NewRecordLine from "./NewRecordLine"

export default class NewRecordContainer extends PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {}
  componentDidUpdate() {}
  render() {
    const data = this.props.data;
    const def = this.props.def;
    const module = this.props.module;
    const way = this.props.way
    const record = this.props.record;

    var linesRender = [];
    var linesRenderWithValue = [];
    def.entrySeq().forEach(e => {
      if(e[1].get("type") == "id" || e[1].get("dbType") == "id"){
        return;
      }
      const value = record.get(e[0]);
      if (value == undefined || value == "") {
        linesRender.push(
          <NewRecordLine key={e[0]} way={way} module={module} field={e[0]} def={e[1]} value={value} />
        );
      } else {
        linesRenderWithValue.push(
          <NewRecordLine key={e[0]} way={way} module={module} field={e[0]} def={e[1]} value={value} />
        );
      }
    });
    return (
      <div className="recordContainer new">
        <div className="mergeRecordHeader">
          <div>{sAction.translate("LBL_MERGE_RECORDS_RESULT_HEADER")}</div>
          <div>
            <div
              className="icon-deleteIcon mergeAddFieldButton"
              onClick={() => sAction.dataSet(way+"/newRecord",{})}
            />
          </div>
        </div>
        <div className="mergeRecordBody">
          {linesRenderWithValue}
          {linesRender}
        </div>
      </div>
    );
  }
}
