export default function rowResize(e,prefix){
  if(this.dataGet(prefix+"/openFilterData") != null){
    this.dataSet(prefix+"/openFilterData/open",false);
  }
  var th = e.target.closest(".newListViewHeaderCell");
  var table = e.target.closest(".newListView");
  var index = Array.from(th.parentNode.childNodes).indexOf(th);
  var linesRow = table.querySelector(".newListViewLines").children[index]

  var width = th.clientWidth;

  this.index = index
  this.resizeStart = e.clientX;
  this.resizeWidthStart = width;
  this.resizeRows = [th,linesRow];
  this.activeResizeRow = true;
}