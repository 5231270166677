import React from "react";
import PureComponent from "../pure";
import Report from "./Report";
import Button from "../formElements/Button";
import  sAction  from "sAction";

export default class ReportContainer extends PureComponent {
  render() {
    const data = this.props.data;
    const way = this.props.way;

    return (
      <div className="reportViewContainer">
        <div className="viewActionPanel">
          <div className="viewActionPanelButtons">
            <Button href="/#list/acm_coripoReports" key={"returnChanges"}>
              <React.Fragment>
                <div className={"actionPanelButtonIcon " + "icon-undo"} />
                {sAction.app_strings["LBL_RETURN_CHANGES"]}
              </React.Fragment>
            </Button>
          </div>
        </div>
        <Report data={data} way={way} />
      </div>
    );
  }
}
