import $ from 'jquery'

export default function fixPositionFilter(container){

        var container = $(container);
        var parent = container.closest(".newListView");
        console.log(parent);
        var parentOffset = parent.offset().left;
        var containerOffset = container.offset().left;
        var left = container.width() / 2;
      //  container.css("left","0"+"px");
        if((containerOffset - left)  < parentOffset){
            left = containerOffset - parentOffset -  10;
        }
        containerOffset = container.offset().left;
        var icon = container.closest(".listViewHeadericonContainer");
        var iconOffset = icon.offset().left

        var triangle = container.children(".triangle");
        var triangleLeft = left - 13;
        triangle.css("left",triangleLeft+"px");


        var containerLeft = iconOffset - parentOffset - left;

        container.css("left",containerLeft +"px");

}