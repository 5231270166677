export default function orderBy(field, asc, prefix) {

  this.dsClear();
  this.dsAdd("set",prefix+"/orderBy",field);
  this.dsAdd("set",prefix+"/asc",asc)
  this.dsProcess();
  let list = null;

  if (this.getViewName() === 'import') {
    const action = this.getStorage('prepareListStructure');
    action.orderBy = field;
    action.asc = asc;
    this.setStorage('prepareListStructure', action);
    list = this.prepareListStructure(action);
  }

  this.reloadList(0, 1, prefix,null,this.dataGet(prefix+"/rowTotalCount"), list);
}
