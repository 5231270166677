export default function saveRecordPopup(params){
    this.saveRecordBase(params, data => {
        if (data.state === true) {
            this.unLoad();
            this.popupHide();
            if(params.saveCallback != undefined){
                params.saveCallback(data.data);
            }
        }
    })
}