import DefaultCurrency_id from '../../defaultFields/Currency_id/Currency_id'
import  sAction  from "sAction";
export default class Currency_id extends DefaultCurrency_id {
    saveField(data) {
        sAction.saveField(data);

        //zmena vsech hodnot na detailu
        const oldCur = this.props.data.value;
        const newCur = data.value;
        const prefix = data.way.split("/")[0];
        sAction.currencyConvertFields(prefix, oldCur, newCur);
        if (this.customLines === true) {
            sAction.recalculateCustomFields(this.props.prefix);
        } else if (this.customLines !== false) {
            this.customLines = this.getIfCustomFields(data, this.props.data.get('value'), data.value);
        }
      }

    getIfCustomFields(data) {
        const way = this.props.prefix + '/customData/customLines/info/kurz';
        const isKurz = sAction.dataGet(way);
        if (isKurz && (isKurz === true || (typeof isKurz === 'string' && isKurz !== ''))) {
            sAction.recalculateCustomFields(this.props.prefix, this.props.data.get('value'), data.value);
            return true;
        } else {
            return false;
        }
    }
}
