import $ from 'jquery';
import moment from 'moment';

export default function calGetEvents(forced){
    var self = this;
    if(!self.calEvents.cssSelector){
        //TODO TTT - tohle je kvuli dashletu (dost velka prasarna)
        // || $("#"+self.calEvents.cssSelector).length === 0
        return;
    }

    if(forced){

        let params = {"user_id" : "", "date_range" : this.calEvents.date_range, "params" : {"sharedView" : self.calEvents.sharedView}};
        
        this.rest.post("calActivity", params, function(data){
            $("#"+self.calEvents.cssSelector).fullCalendar("render");

            data.eventData.calendars.forEach((cal) => {
                let pomEvents = {"Meetings" : [], "Calls" : [], "Tasks" : []};
    
                cal.Meetings.forEach((act) => {
                    pomEvents.Meetings.push(act);
                });
    
                cal.Calls.forEach((act) => {
                    pomEvents.Calls.push(act);
                });
                
                cal.Tasks.forEach((act) => {
                    pomEvents.Tasks.push(act);
                });
    
                self.calEvents.calendars[cal.id] = {id : cal.id, events: pomEvents};

            });

            setEvents(self, {prefix: "calendar"});
        });

    }
    else{
        setEvents(self, {prefix: "calendar"});
    }
}

export function setEvents(self, attrs){
    self.calEvents.calendars.forEachObject((cal, id) => {
        setEventsCalendar(self, attrs, cal, id);
    });
}

export function setEventsCalendar(self, attrs, cal, id){
    let filters = self.dataGet(attrs.prefix+"/filters");

    let events = [];
    let cssSel = "";
    var eventsPom = null;

    if(attrs.widget){
        cssSel = attrs.cssSelector;
        eventsPom = self.dataGet(attrs.prefix+"/eventData");
    }
    else{
        cssSel = "#"+self.calEvents.cssSelector; 
    
        if(self.calEvents.sharedView){
            cssSel += "_"+id;
        }

        eventsPom = cal.events;

    }
    
    if(filters.showMeetings){
        eventsPom.Meetings.forEach((ev) => {
            if(!filters.showCompleted){
                if(ev.status != "Planned"){
                    return;
                }
            }

            if(filters.meetStates && filters.meetStates.size > 0){
                if(!filters.meetStates.includes(ev.status)){
                    return;
                }
            }
            let allDay = ev.all_day == "1" ? true: false;
            events.push(setEventStyle({id: ev.id, title: ev.name, start : ev.date_start, end: ev.date_end, act_type: "Meetings", status: ev.status, allDay: allDay}));
        });
    }

    if(filters.showCalls){
        eventsPom.Calls.forEach((ev) => {
            if(!filters.showCompleted){
                if(ev.status != "Planned"){
                    return;
                }
            }

            if(filters.callStates && filters.callStates.size > 0){
                if(!filters.callStates.includes(ev.status)){
                    return;
                }
            }
            let allDay = ev.all_day == "1" ? true: false;
            events.push(setEventStyle({id: ev.id, title: ev.name, start : ev.date_start, end: ev.date_end, act_type: "Calls", status: ev.status, allDay: allDay}));
        });
    }

    if(filters.showTasks){
        
        let tasksLastDay = self.dataGet(attrs.prefix+"/setting/tasksLastDay");

        eventsPom.Tasks.forEach((ev) => {
                if(!filters.showCompleted){
                    if(ev.status == "Completed"){
                        return;
                    }
                }

                if(filters.taskStates && filters.taskStates.size > 0){
                    if(!filters.taskStates.includes(ev.status)){
                        return;
                    }
                }

                let dateStartPom = ev.date_start;
                if(tasksLastDay){
                    if(ev.date_start.substring(0, 10) != ev.date_end.substring(0, 10)){
                        let dateObj = moment(ev.date_end).subtract(30, "minutes");
                        dateStartPom = dateObj.format("YYYY-MM-DD HH:mm:ss");
                    }
                }
                let allDay = ev.all_day == "1" ? true: false;
                events.push(setEventStyle({id: ev.id, title: ev.name, start : dateStartPom, end: ev.date_end, act_type: "Tasks", status: ev.status, allDay: allDay}));
        });
    }
        
    $(cssSel).fullCalendar('removeEvents');
    $(cssSel).fullCalendar('addEventSource', events);
}

export function setEventStyle(event){
    //event.backgroundColor = "#3584D4";
    //event.borderColor = "#3584D4";

    
    switch (event.act_type) {
        case "Meetings":
            //event.backgroundColor = "rgb(210, 229, 252)";
            event.backgroundColor = "#A7A9FF";
            event.borderColor = "#A7A9FF";
            break;
        case "Calls":
            //event.backgroundColor = "rgb(247, 203, 158)";
            //event.backgroundColor = "rgba(255, 152, 0, 1)";
            //event.borderColor = "rgba(255, 152, 0, 1)";
            event.backgroundColor = "#FABB93";
            event.borderColor = "#FABB93";
            
            break;
        case "Tasks":
            //event.backgroundColor = "rgb(177, 245, 174)";
            //event.backgroundColor = "rgba(76, 175, 80, 1)";
            //event.borderColor = "rgba(76, 175, 80, 1)";
            event.backgroundColor = "#58C97E";
            event.borderColor = "#58C97E";
            break;
        default:
            break;
    }
    
    //event.borderColor = "#CCC";
    event.textColor = "#FFF";


    return event;
}

/*
            event.backgroundColor = "00bcd4";
            break;
        case "Calls":
            event.backgroundColor = "ff9800";
            break;
        case "Tasks":
            event.backgroundColor = "4caf50";
*/