export default function emailTemplatesContent(data, content) {
  content.recordName = data.record.name;
  content.tabs = [
    {
      name: "default",
      rows: []
    }
  ];
  content.customJS = data.js;
  content.actionButtons = data.menu;
  //_________ SUBPANELS

  content.subpanels = data.subpanels;
  content.customData = {};

  const t = content.tabs[0];
  const d = data.def;

  let keys = [];

  let fields = {};

  let rows = [
    ["name", "type"],
    ["date_entered", "date_modified"],
    ["subject", "description"]
  ];

  rows.forEach(row => {
    t.rows.push([{ name: d[row[0]].name }, { name: d[row[1]].name }]);

    row.forEach(key => {
      fields[key] = { value: data.record[key], def: d[key] };
    });
  });

  keys = ["assigned_user_name", "text_only"];
  t.rows.push([{ name: d[keys[0]].name }, { name: d[keys[1]].name }]);

  fields.assigned_user_name = {
    value: data.record[keys[0]],
    def: { ...d[keys[0]], id_value: data.record.assigned_user_id }
  };
  fields.text_only = { value: data.record[keys[1]], def: d[keys[1]] };

  t.rows.push([{ name: d.body_html.name }]);

  fields[d.body_html.name] = {
    value: htmlUnescape(data.record.body_html),
    def: { ...d.body_html, type: "RichText" }
  };

  let files = {};
  if (data.customData && data.customData.files) {
    data.customData.files.forEachObject(f => {
      files[f["id"]] = {
        id: f["id"],
        source: "sugar",
        name: f["filename"],
        type: f["file_mime_type"],
        size: f["size"],
        deleted: false
      };
    });
  }

  t.rows.push([{ name: "files" }]);
  fields.files = { value: "", def: { type: "files", files } };

  content.fields = fields;
}

function htmlUnescape(str) {
  return str == null
    ? ""
    : str
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
}
