import DefaultEnum from '../../defaultFields/Enum/Enum';
import  sAction  from "sAction";

export default class Enum extends DefaultEnum {

	saveField(data) {
		sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);	
	}

}
