import React from "react"
import PureComponent from "../../pure"

import InputText from "../../formElements/InputText"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import  sAction  from "sAction";
import RichText from "../../formElements/HTMLText"
import { EmailPopupActionPanel } from "./shared/EmailPopupActionPanel"
import Loader from "../../loader";

const RichEditor = React.forwardRef((props, ref) => (
  <RichText ref={ref} {...props} />
));

export default class EmailSignaturePopup extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.nameRef = React.createRef();
    this.textRef = React.createRef();

    this.state = {
      loaded: false
    }
  }

  savePopup = () => {
    const name = this.nameRef.current.value;
    const description = tinyMCE.activeEditor.getContent();

    if (!name || !description) {
      // Nejsou vyplněny všechny povinné položky
      sAction.alert(sAction.translate("ERR_FILL_REQUIRED_ITEMS","Emails"));
      return;
    }

    const sendData = {
      name,
      description,
    }

    const id = this.props.data.get("id");
    if (id) { //uprava podpisu
      sendData.record = id;
    } else { //pridani noveho - ulozi se zaroven jako default podpis uzivatele
      sendData.add = true;
    }

    sAction.load();
    sAction.rest.post("email/saveSignature", sendData, (data) => {
      sAction.popupHide();
      this.props.data.get("callback")();
    });
  }

  componentDidMount() {
    this._isMounted = true;

    //get signature html
    const id = this.props.data.get("id");
    if (!id) {
      this.setState({ loaded : true });
      return;
    }

    sAction.rest.post("email/getSignature", { id }, data => {
      if (!this._isMounted) return;

      this.setState({ loaded : true });
      this.textRef.current.setValue(
        sAction.htmlDecode(data.signature_html)
      );
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    if (!this.state.loaded) return <Loader />;

    return (
      <div className="email__popup">
        <div className="acmPopupHeader">
          {this.props.data.get("add") == true
            ? sAction.translate('LBL_EMAIL_ADD_SIGNATURE', 'Emails')
            : sAction.translate('LBL_EMAIL_EDIT_SIGNATURE', 'Emails')}
        </div>
        {/* Přidat podpis || Upravit podpis */}
        <EmailPopupActionPanel onSave={this.savePopup} />
        <DialogContent>
          <DialogContentText />
          <InputText
            autoFocus
            margin="dense"
            inputRef={this.nameRef}
            id="name"
            label={sAction.translate('LBL_DASHLET_OPT_TITLE', 'Home')} //Název
            type="text"
            defaultValue={this.props.data.get("name")}
            fullWidth
            onKeyUp={ev => {
              if (ev.key === 'Enter') {
                this.savePopup();
                ev.preventDefault();
              }
            }}
          />
          <br/>


          <RichEditor ref={this.textRef} />

        </DialogContent>
      </div>
    );
  }
}
