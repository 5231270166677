export default function deleteRecordSubpanel(recordData) {
    this.confrim(this.translate("LBL_BUTTON_CONFIRM_DELETE_RECORD"), () => {
        this.rest.delete("delete/" + recordData.module + "/" + recordData.id, null, (returnData) => {

            if (!returnData.status) {
                this.reloadSubpanelByWay('view', recordData.module);
                this.popupHide()
            } else {
                this.error("Špatný návrat ze serveru")
            }
        });
    })
}