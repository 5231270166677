export default function editFieldsNewField(way,module){

    const optionsList = [{ label: "", value: "" }];
    this.app_strings.forEachObject((element, index) => {
      if (typeof element === "object") {
        optionsList.push({ label: index, value: index });
      }
    });
    this.temporaryData.optionsList = optionsList
    this.popup("editFieldsNewField",{
        closeFromBc:false,
        customClass:"popupNewField",
        header: this.translate("LBL_CREATE_NEW_FIELD"),
        module:module,
        way:way,
        view:"newField",
    });
}