export default function hiddenField(prefix){
    
    const fieldToHide = this.dataGet(prefix+"/editFieldsPosition/a")

    var rowWay = fieldToHide.get("rowWay")
    const field = this.dataGet(rowWay)
    
    const rowWayExplode = rowWay.split("/")
    
    rowWay = rowWay.substring(0, rowWay.length - 2);
    const rowWayIndex = rowWayExplode[rowWayExplode.length - 1]
    if(field != "empty_row"){
        this.dsClear();
        this.dsAdd("set",rowWay+"/"+rowWayIndex,"empty_row")
        this.dsAdd("add",prefix+"/hiddenFields",field)
        this.dsAdd("set",prefix+"/editFieldsPosition/a",null)
        this.dsAdd("set",prefix+"/editFieldsPosition/b",null)
        this.dsProcess()
    }
}