import React from "react";
import PureComponent from '../../pure'

import Detail from "../../detailView/detail"

import  sAction  from "sAction";

export default class DetailViewPopup extends PureComponent {


    render(){
        const data = this.props.data
        return(
            <Detail data={data.get("detailView")} way={this.props.way+"/data/detailView"} />
        );
    }

}