import React from "react";
import PureComponent from "../../pure";

import Button from "../../formElements/Button";
import Select from '../../formElements/Select'

import  sAction  from "sAction";

export default class OppChooseTemplate extends PureComponent {
    constructor(){
        super();
        
        this.state = {
            template : "type1"
        }
    }

    handlePrint(){
        sAction.printPrepare({...this.props.data.get("params").toJS(), type: this.state.template});
    }

    render(){
        const data = this.props.data;

        var templateOptions = [
            {value: "type1", label : "type1"},
            {value: "type2", label : "type2"},
            {value: "type3", label : "type3"},
        ];


        return <div className="OppChooseTemplate">
            <div className="viewActionPanelButtons">
                <Button className="hoverBlue" onClick={() => this.handlePrint()}>
                    <div className={"icon-print calHeaderIcon"} ></div>
                    {sAction.translate("LBL_PRINT_OPPORTUNITY")}
                </Button>
                <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                    <div className={"icon-close calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CANCEL_ACTION")}
                </Button>
            </div>
            <div className="OppChooseTemplateContent">
                <Select 
                    open={false} 
                    name="printTemplate"
                    options={templateOptions} 
                    defaultValue="type1" 
                    onChange={(ev) => this.setState({template : ev.target.value})} 
                />
            </div>
        </div>;
    }
}