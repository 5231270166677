export default function objectToFormdata(obj, form, namespace) {
    let fd = form || new FormData();
    obj.forEachObject((property,formKey) => {
        if(property !== undefined){
        if (namespace) {
            formKey = namespace + '[' + formKey + ']';
          }


        // if the property is an object, but not a File, use recursivity.
        if (property instanceof Date) {
          fd.append(formKey, property.toISOString());
        }
        else if (typeof property === 'object' && !(property instanceof File) && property !== null) {
          this.objectToFormdata(property, fd, formKey);
        } else { // if it's a string or a File object
          fd.append(formKey, property);
        }
        }
    })
    
    return fd;
  }