import DefaultBool from '../../defaultFields/Bool/Bool';
import  sAction  from "sAction";

export default class Bool extends DefaultBool {

	saveField(data) {
		sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);	
	}

}
