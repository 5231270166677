

import React from 'react';
import shallowEqual from 'react-pure-render/shallowEqual';

export default class PureComponent extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
  }
  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    window.reactAppErrorComponent = this;
    this.setState({
      hasError: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
  //console.log(this);
  //  console.log(this.props,prevProps)
  }
}
