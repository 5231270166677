import React from "react";
import PureComponent from "../../pure";
import TabSelect from "../../formElements/TabSelect";
import ReportWindowGroup from "./ReportWindowGroup";

import sAction from "sAction";
class ReportWindowGroupLine extends PureComponent {
  selectField(e) {
    const select = e.value;
    const fields = this.props.fields;
    const relate = this.props.relate;
    const way = this.props.way;
    const index = this.props.index;
    var field = null;
    fields.forEach((lfield, index) => {
      if (lfield.get("name") == select) {
        field = lfield;
      }
    });
    relate.forEach((lfield, index) => {
      if (lfield.get("name") == select) {
        field = lfield;
      }
    });
    const data = {
      name: field.get("name"),
      select: select,
      def: field.toJS(),
    };
    sAction.reportWindowSetLineData(way + "/" + index, data);
  }
  render() {
    var data = this.props.data;
    var fields = this.props.fields;
    var relate = this.props.relate;
    var actModule = this.props.module;
    const way = this.props.way;
    const index = this.props.index;
    var fieldsRender = [];
    var dataRender = [];
    fields.forEach((field, index) => {
      var preklad = null;
      if (sAction.moduleTranslate[actModule] != undefined) {
        preklad = sAction.moduleTranslate[actModule][field.get("vname")];
      }
      if (preklad == null || preklad == "" || preklad == undefined) {
        preklad = sAction.app_strings[field.get("vname")];
      }
      dataRender.push({ value: field.get("name"), label: preklad });
    });
    fieldsRender[sAction.translate("LBL_REPORT_SEARCH_FIELDS")] = dataRender;
    dataRender = [];
    relate.forEach((field, index) => {
      var preklad = null;
      if (sAction.moduleTranslate[actModule] != undefined) {
        preklad = sAction.moduleTranslate[actModule][field.get("vname")];
      }
      if (preklad == null || preklad == "" || preklad == undefined) {
        preklad = sAction.app_strings[field.get("vname")];
      }
      dataRender.push({ value: field.get("name"), label: preklad });
    });
    fieldsRender[sAction.translate("LBL_REPORT_SEARCH_RELATE")] = dataRender;
    return (
      <div className="reportWindowRelateLine">
        <div className="reportWindowHorizontalLine"></div>
        <div className="reportWindowRelateContainer">
          <div className="reportWindowRelateContent">
            <div className="reportWindowGroupHeader">
              <TabSelect
                containerClassName="withBorder"
                options={fieldsRender}
                defaultValue={data.select}
                onChange={(e) => this.selectField(e)}
                activeTabName={sAction.translate("LBL_REPORT_SEARCH_RELATE")}
              />
            </div>
            <div className="reportWindowGroupContent">
              <div className="reportWindowHorizontalLine"></div>
              <div
                onClick={() => sAction.dataDelete(way, index)}
                className="reportWindowDeleteLine iconCancel"
                title={sAction.translate("LBL_REPORT_CLEAR_FIELD")}
              />
              <ReportWindowGroup
                data={data.group}
                fields={data.fields}
                relate={data.relate}
                module={data.module}
                way={way + "/" + index + "/group"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ReportWindowGroupLine;
