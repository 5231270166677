export default function importXML(data) {
    var input=document.createElement('input');
    input.type="file";
    input.style="display:none";

    input.onchange = (e) => {
        const sendData = {
            data: {
                module: data.module,
                id: data.id,
                files: e.target.files,
            },
            action: "importFile",
            responseType: "json",
            success: response => {
                this.unLoad();
                if (response.status === false) {
                    this.error(this.translate('LBL_IMPORT_VEHICLES_ISSUE'));
                } else if (response.status === true) {
                    const data = {
                        // Potvrzení
                        header: this.translate("LBL_CONFIRM"),
                        // color:"red",
                        buttons: [
                            // Zrušit
                            {label: this.translate("LBL_CANCEL", "Home"), callback: () => this.popupHide()},

                            // Pokračovat
                            {label: this.translate("LBL_CONTINUE", "Home"), callback: () => location.reload()},
                        ],
                    };

                    this.popup(this.translate('LBL_FINISHED_IMPORT_VEHICLES') + response.row, data);
                } else {
                    let message = '';
                    // Novy navrat z opportunit
                    switch (response.status) {
                        case 'done':
                            message = this.translate(response.data.message);
                            message = message.replace('{$oppID}', response.data.oppID);

                            const data = {
                                // Potvrzení
                                header: this.translate("LBL_CONFIRM"),
                                // color:"red",
                                buttons: [
                                    // Zrušit
                                    {label: this.translate("LBL_CANCEL", "Home"), callback: () => this.popupHide()},

                                    // Pokračovat
                                    {label: this.translate("LBL_CONTINUE", "Home"), callback: () => {window.location.hash = 'detail/Opportunities/' + response.data.oppID;}},
                                ],
                            };

                            this.popup(message, data);
                            break;
                        case 'warning':
                            let message = this.translate(response.data.message);
                            message = message.replace('$EPL', response.data.epl);
                            this.error(message);
                            break;
                        case 'error':
                        default:
                            message = this.translate(response.data.message);
                            if (response.data.errorMessage) {
                                message = message.replace('{$invalidData}', response.data.errorMessage);
                            }
                            this.error(message);
                            break;
                    }
                }

                // this.unLoad();
                // console.log(response);
            },
            progress: progress => {
            }
        }
        this.load();
        this.filesXhr(sendData);
    }

    setTimeout(function(){
        input.click();
    },200);

}