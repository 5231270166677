import React from "react";
import PureComponent from "../pure";
import Button from "../formElements/Button";
import Input from "../formElements/InputText";
import  sAction  from "sAction";
import Loader from "../loader";

export default class ResetPass extends PureComponent {
  constructor() {
    super();

    this.state = {
      token: false,
      error: false,
      message: "",
      pass: true,
      passAgain: true,
      passMessage: "",
      passAgainMessage: ""
    };
    this.pass = React.createRef();
    this.passAgain = React.createRef();
  }
  onKeyDown(e) {
    if (e.keyCode == 13 && this.state.token === true && !this.state.message) {
      this.resetPassword();
    }
  }
  resetPassword() {
    const pass = this.pass.current.value;
    const passAgain = this.passAgain.current.value;
    if (!pass || !passAgain) {
      this.setState({ pass: Boolean(pass), passAgain: Boolean(passAgain) });
      return;
    }
    if (pass != passAgain) {
      this.setState({
        passAgain: false,
        pass: Boolean(pass),
        passMessage: "",
        passAgainMessage: "LBL_PASSWORDS_DO_NOT_MATCH"
      });
      return;
    }
    var regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{8,})"
    );
    if (!regex.test(pass)) {
      this.setState({
        pass: false,
        passMessage: "LBL_REGEX_CHECK_FAILED",
        passAgainMessage: "",
        passAgain: Boolean(passAgain)
      });
      return;
    }
    const urlArray = window.location.hash.split("/");
    const self = this;
    sAction.resetPassword(pass, urlArray[2], data => {
      if (data.status) {
        self.setState({ message: data.message, error: false, token: false });
      }else{
          sAction.error(sAction.translate("LBL_ERROR"));
      }
    });
  }

  render() {
    var ret;
    if (this.state.message) {
      ret = this.getMessage();
    } else {
      if (!this.state.token) {
        ret = this.getCheckingTokenValidity();
        const self = this;
        const urlArray = window.location.hash.split("/");
        sAction.confirmToken(urlArray[2], "resetPassword", data => {
          if (data.status === true) {
            self.setState({ token: true, error: false });
          } else {
            self.setState({ error: true, message: data.message });
          }
        });
      } else {
        ret = this.getForm();
      }
    }

    return (
      <table className="loginTable" onKeyDown={e => this.onKeyDown(e)}>
        <tbody>
          <tr>
            <td colSpan="2" className="login PageLogoContainer">
              <img src={sAction.param.companyLogo} />
            </td>
          </tr>
          {ret}
        </tbody>
      </table>
    );
  }
  getForm() {
    return (
      <React.Fragment>
        <tr>
          <td colSpan="2">
            {this.state.passMessage
              ? this.getErrorMessage(this.state.passMessage)
              : null}
            <Input
              type="password"
              error={!this.state.pass}
              myRef={this.pass}
              label={sAction.translate("LBL_NEW_PASSWORD")}
              variant="outlined"
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            {this.state.passAgainMessage
              ? this.getErrorMessage(this.state.passAgainMessage)
              : null}
            <Input
              type="password"
              error={!this.state.passAgain}
              myRef={this.passAgain}
              label={sAction.translate("LBL_NEW_PASSWORD_AGAIN")}
              variant="outlined"
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <div className="loginButtonContainer">
              <Button
                className="loginButton"
                onClick={() => this.resetPassword()}
              >
                {sAction.translate("LBL_CONFIRM_NEW_PASSWORD")}
              </Button>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <div className="loginButtonContainer">
              {sAction.translate("LBL_FORGOTTEN_PASSWORD_RETURN")}
              &nbsp;
              <a onClick={() => this.props.goToLogin()}>
                {sAction.translate("LBL_FORGOTTEN_PASSWORD_RETURN_BUTTON")}
              </a>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  getMessage() {
    return (
      <React.Fragment>
        <tr>
          <td colSpan="2">
            <div className={this.state.error ? "center error" : "center"}>
              {sAction.translate(this.state.message)}
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <div className="loginButtonContainer">
              <a onClick={() => this.props.goToLogin()}>
                {sAction.translate("LBL_BACK_TO_LOGIN")}
              </a>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  getCheckingTokenValidity() {
    // sAction.load();
    return (
      <React.Fragment>
        <tr>
          <td colSpan="2">
            <div className="center">
              {sAction.translate("LBL_TOKEN_CHECKING_VALIDITY")}
            </div>
            <Loader key={"zero"} />
          </td>
        </tr>
      </React.Fragment>
    );
  }

  getErrorMessage(message) {
    return <div className="center error">{sAction.translate(message)}</div>;
  }
}
