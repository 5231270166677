export default function ordCreateInvoice(data){
    const params = {action: "ord_create_invoice", record : data.id};

    var self = this;

    this.rest.post("customAction", params, function(resp) {
        if(resp.errCode == "OK"){
            self.href("#detail/acm_invoices/"+resp.record);       
        }
        else{
            self.alert(resp.errMsg);
        }
    });
}